import React, { Fragment, useCallback, useState } from 'react';
import text from '../../shared/translations/en';
import { Device, DeviceColumns, IDialog } from '../../shared/types/device';
import { Dialog } from './Dialog/Dialog';
import { DeviceItem } from './DeviceItem';

const allColumns: DeviceColumns = {
  DEVICE_NAME: 'device-name',
  DEVICE_ID: 'device-id',
  PLATFORM: 'platform',
  MODE: 'mode',
  PUSH_TOKEN: 'push-token',
  SUBSCRIPTIONS: 'subscriptions',
  LANGUAGE: 'language',
  ACTIONS: 'actions',
};

interface IProps {
  devices: Device[];
  columns: string[];
  confirmed: boolean;
  handleConfirmDevice?: (device: Device) => void;
  app?: string;
  extraClass?: string[];
}

export function DevicesList({
  extraClass,
  columns,
  confirmed,
  devices,
  handleConfirmDevice,
  app,
}: IProps): JSX.Element {
  const [dialog, setDialog] = useState<IDialog>();

  const showColumn = (column: string) => columns.includes(column);

  const openDialog = useCallback((device: Device, remove: boolean) => {
    setDialog({
      id: device.id,
      device,
      open: true,
      remove,
      edit: !remove,
      confirm: false,
    });
  }, []);

  const getDialogTitleText = useCallback(() => {
    if (dialog.remove) {
      return text.en['remove-device'];
    }
    return dialog.edit ? text.en['edit-device'] : text.en['confirm-device'];
  }, [dialog]);

  return (
    <div
      className={`app-devices-table ${extraClass ? extraClass.join(' ') : ''}`}
    >
      <div className="app-devices-table-header">
        {Object.keys(allColumns).map(
          (column, ix) =>
            showColumn(allColumns[column]) && (
              <span key={`column-${ix}`}>{text.en[allColumns[column]]}</span>
            )
        )}
      </div>
      {devices && devices.length ? (
        <div className="app-devices-table-body">
          {devices.map(
            (device, i) =>
              device && (
                <Fragment key={`device-item-${i}.${device.id}`}>
                  <DeviceItem
                    device={device}
                    handleRemoveDevice={() => openDialog(device, true)}
                    handleConfirmDevice={handleConfirmDevice}
                    handleEditDevice={() => openDialog(device, false)}
                    showColumn={showColumn}
                    columns={allColumns}
                    confirmed={confirmed}
                  />
                </Fragment>
              )
          )}
        </div>
      ) : (
        <div className="app-devices-table-body">
          <div className="app-devices-table-body-row">
            <span>{text.en['no-devices']}</span>
            <span></span>
          </div>
        </div>
      )}
      {dialog?.open && (
        <Dialog
          app={app}
          dialog={dialog}
          title={getDialogTitleText()}
          closeDialog={() => setDialog(undefined)}
        />
      )}
    </div>
  );
}
