export interface AppSidebarTab {
  name: string;
  iconClass: string;
  tabHash: string;
}

export const sidebarTabs: AppSidebarTab[] = [
  {
    name: 'Overview',
    iconClass: 'icon-16-144-menu-square',
    tabHash: '#overview',
  },
  {
    name: 'Platforms and environments',
    iconClass: 'icon-16-149-layers-stack',
    tabHash: '#platforms-and-environments',
  },
  {
    name: 'Application settings',
    iconClass: 'icon-16-027-setings',
    tabHash: '#app-settings',
  },
  {
    name: 'Admin settings',
    iconClass: 'icon-16-254-Admin-settings',
    tabHash: '#admin-settings',
  },
  {
    name: 'Tools',
    iconClass: 'icon-16-231-wrench-tool',
    tabHash: '#tools',
  },
  {
    name: 'Test devices',
    iconClass: 'icon-16-279-devices',
    tabHash: '#devices',
  },
];

export interface MobileNavItem {
  name: string;
  translation?: string;
  icon: string;
  requireAuth: boolean;
  mainSubMenu?: {
    name: string;
    urlPath: string;
    icon: string;
    translation?: string;
  }[];
}

export const mobileNavItems: MobileNavItem[] = [
  {
    name: 'inquiry',
    translation: 'become-a-client',
    icon: 'icon-16-098-register-signature',
    requireAuth: false,
  },
  { name: 'login', icon: 'icon-16-187-enter', requireAuth: false },
  { name: 'applications', icon: 'icon-16-221-apps', requireAuth: true },
  {
    name: 'account',
    icon: 'icon-16-247-user2-avatar',
    requireAuth: true,
    mainSubMenu: [
      { name: 'sign out', urlPath: '/logout', icon: 'icon-16-188-exit' },
    ],
  },
];
