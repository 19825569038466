import React, { ChangeEvent, Fragment, useCallback, useState } from 'react';
import text from '../../../shared/translations/en';
import Modal from '../../../components/Modal/Modal';
import { ModalButton } from '../../../components/UI/ModalButton/ModalButton';
import { TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import { createApplication } from '../../../store/actions/applications';

interface IProps {
  setIsCreateModalOpen: (value: boolean) => void;
}

export function CreateAppModal({ setIsCreateModalOpen }: IProps) {
  const dispatch = useDispatch();

  const [appName, setAppName] = useState<string>('');

  const handleCreateApp = useCallback(() => {
    dispatch(createApplication({ name: appName }));
    setIsCreateModalOpen(false);
  }, [appName]);

  return (
    <Modal
      title={text.en['create-new-app']}
      content={
        <TextField
          label={text.en.name}
          fullWidth
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            setAppName(event.target.value)
          }
          value={appName}
          variant="standard"
        />
      }
      actions={() => (
        <Fragment>
          <ModalButton
            btnText={text.en.cancel}
            actionHandler={() => setIsCreateModalOpen(false)}
            type="transparent"
          />
          <ModalButton
            isDisabled={!appName}
            btnText={text.en.create}
            actionHandler={handleCreateApp}
            type="colored"
          />
        </Fragment>
      )}
      onDismiss={() => this.handleClose()}
    />
  );
}
