import React, { FormEvent } from 'react';
import { DateInput } from '../DateInput/DateInput';
import text from '../../shared/translations/en';

interface IProps {
  plans: string[];
  onFormSubmit: (event: FormEvent<HTMLFormElement>) => void;
  salesRepresentatives: string[];
  owner: string;
  selectedDate: Date;
  onDateChange: (date: Date) => void;
  onPlanChangeHandler: React.ChangeEventHandler<HTMLSelectElement>;
  selectedPlan: string;
  btnDisable: boolean;
}

export function OwnerPlan({
  plans,
  onFormSubmit,
  salesRepresentatives,
  owner,
  selectedDate,
  onDateChange,
  onPlanChangeHandler,
  selectedPlan,
  btnDisable,
}: IProps): JSX.Element {
  return (
    <>
      <div className="app-detail__row u-margin-top-18">
        <p
          className="body-3"
          dangerouslySetInnerHTML={{ __html: text.en['plan-lead'] }}
        />
      </div>

      <div className="app-detail__row u-margin-top-18">
        <form onSubmit={onFormSubmit}>
          {salesRepresentatives.length !== 0 && (
            <div className="input-row__wrapper">
              <label className="input-row__label">
                {text.en['sales-representatives']}:
                {salesRepresentatives.length
                  ? salesRepresentatives.join(' ')
                  : salesRepresentatives}
              </label>
            </div>
          )}
          {/* email */}
          <div className="input-row__wrapper u-margin-top-18">
            <label className="input-row__label">{text.en.owner}</label>
            <div className="input-row__input-contanier">
              <input
                className="input-row__input u-grey-bg"
                value={owner ?? ''}
                disabled
              />
            </div>
          </div>
          <div className="input-row__wrapper">
            {/* Date picker*/}
            <div className="col-1-of-2 u-margin-top-32">
              <DateInput
                selectedDate={selectedDate}
                selectedPlan={selectedPlan}
                onDateChange={onDateChange}
              />
            </div>
            {/* Plan */}
            <div className="col-1-of-2 u-margin-top-32">
              <div className="input-row__wrapper">
                <label htmlFor="plans" className="input-row__label">
                  {text.en['plan']}
                </label>
                <select
                  id="plans"
                  className="select"
                  onChange={onPlanChangeHandler}
                  value={selectedPlan}
                >
                  {plans.map((planType) => (
                    <option value={planType} key={planType}>
                      {planType}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="input-row__support-msg"></div>
          <button
            type="submit"
            className="sr-btn sr-btn__md btn-default u-btn-right"
            disabled={!btnDisable}
          >
            {text.en.confirm}
          </button>
        </form>
      </div>
    </>
  );
}
