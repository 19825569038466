import React, { Fragment, useCallback, useState } from 'react';
import Modal from '../../../components/Modal/Modal';
import text from '../../../shared/translations/en';
import { ModalButton } from '../../../components/UI/ModalButton/ModalButton';
import {
  Certificate,
  PlatformEnum,
  TokenData,
} from '../../../shared/types/application';
import { convertUnixExpiryTime } from '../../../utils/Date';
import { CerftificateDialog } from './CertificateDialog';
import { useDispatch, useSelector } from 'react-redux';
import { selectApplication } from '../../../store/selectors/application';
import { deleteCertificate } from '../../../store/actions/applications';
import { removeTokenFromApp } from '../../../store/actions/apnTokens';
import { TokenDialog } from './TokenDialog';
import { selectApnTokens } from '../../../store/selectors/apnTokens';
import { selectUser } from '../../../store/selectors/user';
import { CognitoUser } from '../../../shared/types/user';

interface IProps {
  token: TokenData;
  certificate: Certificate;
  mode: string;
  platform: PlatformEnum;
}

export default function APNCredentials({
  token,
  certificate,
  mode,
  platform,
}: IProps): JSX.Element {
  const dispatch = useDispatch();

  const [deleteField, setDeleteField] = useState<string>();
  const [editField, setEditField] = useState<string>();

  const application = useSelector(selectApplication);
  const apnTokens = useSelector(selectApnTokens);
  const user = useSelector(selectUser);
  const appId = application.id ?? application.appId;

  const btnIconGrayCn = 'btn-icon btn--grey';
  const btnIconRedCn = 'btn-icon btn-red';

  const handleDeleteCertificate = useCallback(() => {
    if (deleteField) {
      deleteField === 'certificate'
        ? dispatch(deleteCertificate(appId, platform, mode))
        : dispatch(
            removeTokenFromApp(appId, {
              keyId: token.keyId,
              platform,
              modeType: mode,
              email: (user as CognitoUser).attributes.email,
            })
          );
      setDeleteField(undefined);
    }
  }, [deleteField, token]);

  return (
    <div className="apn-credentials">
      <div className="row">
        <p className="input__label">{text.en['pn-token']}</p>
        <div className="apn-wrapper">
          <div className="apn-wrapper--text">{token ? token.keyId : ''}</div>
          <div className="apn-wrapper--button">
            {token ? (
              <button
                className={btnIconRedCn}
                onClick={() => setDeleteField('token')}
              >
                <i className="icon icon-16-079-trash" />
              </button>
            ) : (
              <button
                className={btnIconGrayCn}
                onClick={() => setEditField('token')}
              >
                <i className="icon-16-219-more2" />
              </button>
            )}
          </div>
        </div>
        <p className="input__label">
          {text.en['pn-certificate']}
          {certificate ? (
            <span>
              <br />
              {text.en['cert-exp-date']}:
              {convertUnixExpiryTime(new Date(certificate.expiryDate))}
            </span>
          ) : null}
        </p>
        <div className="apn-wrapper">
          <div className="apn-wrapper--text">{certificate?.filename}</div>
          <div className="apn-wrapper--button">
            {certificate ? (
              <button
                className={btnIconRedCn}
                onClick={() => setDeleteField('certificate')}
              >
                <i className="icon icon-16-079-trash" />
              </button>
            ) : (
              <button
                className={btnIconGrayCn}
                onClick={() => setEditField('certificate')}
              >
                <i className="icon-16-122-upload2" />
              </button>
            )}
          </div>
        </div>
        {editField === 'certificate' && (
          <CerftificateDialog
            platform={platform}
            mode={mode}
            certificate={certificate?.filename}
            setEditField={setEditField}
          />
        )}
        {editField === 'token' && (
          <TokenDialog
            platform={platform}
            mode={mode}
            setEditField={setEditField}
            apnTokens={apnTokens ?? []}
          />
        )}
      </div>
      {deleteField ? (
        <Modal
          title={`Delete this ${deleteField}?`}
          actions={() => (
            <Fragment>
              <ModalButton
                btnText={text.en.no}
                actionHandler={() => setDeleteField(undefined)}
                type="transparent"
              />
              <ModalButton
                btnText={text.en.yes}
                actionHandler={() => handleDeleteCertificate()}
                type="colored"
              />
            </Fragment>
          )}
          onDismiss={() => setDeleteField(undefined)}
        />
      ) : null}
    </div>
  );
}
