import React, { useCallback } from 'react';
import text from '../../shared/translations/en';
import { SettingsTable } from '../../components/SettingsTable/SettingsTable';
import { updateSettings } from '../../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectApplication } from '../../store/selectors/application';
import { Loader } from '../../components/Loader/Loader';
import { isEmpty } from 'lodash';

export function AppSettings(): JSX.Element {
  const dispatch = useDispatch();

  const application = useSelector(selectApplication);

  const handleUpdateSettings = useCallback((data) => {
    dispatch(updateSettings({ settings: data }));
  }, []);

  if (isEmpty(application)) {
    return <Loader loading={true} />;
  }

  return (
    <>
      <h1 className={'u-margin-bottom-42'}>
        <span className="icon-header">
          <i className="icon-16-239-settings2" />
        </span>
        {text.en['settings']}
      </h1>
      <SettingsTable
        settings={application.settings}
        platformSettings={application.platformSettings}
        updateSettings={handleUpdateSettings}
      />
    </>
  );
}
