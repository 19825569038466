import { updateObject } from '../../shared/utility';
import * as actionTypes from '../actions/actionTypes';
import { CostReportState } from '../../shared/types/store';

const initialState: CostReportState = {
  loading: false,
  error: undefined,
  costReport: undefined,
};

const fetchCostReportSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: undefined,
    costReport: action.costReport,
  });
};

const fetchCostReportStart = (state) => {
  return updateObject(state, {
    loading: true,
  });
};

const fetchCostReportFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
    costReport: undefined,
  });
};

const costReport = (state, action) => {
  if (typeof state === 'undefined') {
    return initialState;
  }

  switch (action.type) {
    case actionTypes.FETCH_COST_REPORT_START:
      return fetchCostReportStart(state);
    case actionTypes.FETCH_COST_REPORT_SUCCESS:
      return fetchCostReportSuccess(state, action);
    case actionTypes.FETCH_COST_REPORT_FAIL:
      return fetchCostReportFail(state, action);
    default:
      return state;
  }
};

export default costReport;
