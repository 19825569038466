import React from 'react';

export function CreateAppIcon(): JSX.Element {
  return (
    <svg width="106px" height="60px" viewBox="0 0 106 111" version="1.1">
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="3"
        fill="none"
        fillRule="evenodd"
      >
        <g id="MDP-My-apps" transform="translate(-107.000000, -166.000000)">
          <g
            id="Additional-benefits"
            transform="translate(108.000000, 166.000000)"
          >
            <g id="Group-6">
              <path
                d={[
                  'M36.0177566,6.91458422 C22.0777852,11.3681983 10.1301957,21.6000107 4.07883532,36.0196375',
                  'C-6.86449642,62.1474414 5.68127924,92.071226 32.1136611,102.8979 C37.5308449,105.112814',
                  '43.1108544,106.337846 48.6528878,106.686034',
                ].join()}
                id="Stroke-1"
                stroke="#0C0F22"
              ></path>
              <path
                d={[
                  'M66.72779,104.618486 C81.155494,100.37967 93.5947876,90.0120149 99.8107112,75.2003092',
                  'C110.756277,49.074467 98.2082673,19.1504371 71.7758854,8.32376333 C66.0397518,5.97791045',
                  '60.1254033,4.73546908 54.26442,4.47923241',
                ].join()}
                id="Stroke-3"
                stroke="#DA3332"
              ></path>
              <path
                d={[
                  'M55.2103159,5.43015564 C54.5050889,7.49733714 52.2354695,8.60788516 50.1429336,7.91120311',
                  'C48.050553,7.21313996 46.9262285,4.97224832 47.6316108,2.90522028 C48.3382359,0.838038791',
                  '50.6064572,-0.272509234 52.6989932,0.424172821 C54.7926165,1.12100833 55.9156983,3.36312761 55.2103159,5.43015564',
                ].join()}
                id="Fill-4"
                fill="#DA3332"
              ></path>
              <path
                d={[
                  'M53.2103159,107.585958 C52.5050889,109.65314 50.2354695,110.763688 48.1429336,110.067006',
                  'C46.050553,109.368942 44.9262285,107.128051 45.6316108,105.059795 C46.3382359,102.993995',
                  '48.6064572,101.883293 50.6989932,102.579975 C52.7926165,103.276811 53.9156983,105.519084 53.2103159,107.585958',
                ].join()}
                id="Fill-5"
                fill="#0C0F22"
              ></path>
            </g>
            <path
              d="M52.5,31 C52.5,31 52.5,81.004878 52.5,82 M78,56.5 C78,56.5 27.995122,56.5 27,56.5"
              id="Stroke-7"
              stroke="#0C0F22"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
