import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
  sections: [],
  singleDoc: {},
};

export const fetchDocsSuccess = (state, action) => {
  return {
    ...state,
    sections: action.structure.sections,
  };
};

export const fetchDocSuccess = (state, action) => {
  return updateObject(state, {
    singleDoc: action.singleDoc,
  });
};

const documentations = (state, action) => {
  if (typeof state === 'undefined') {
    return initialState;
  }
  switch (action.type) {
    case actionTypes.FETCH_DOCS_SUCCESS:
      return fetchDocsSuccess(state, action);
    case actionTypes.FETCH_DOC_SUCCESS:
      return fetchDocSuccess(state, action);
    default:
      return state;
  }
};

export default documentations;
