import React, { useEffect, useState } from 'react';
import { Spinner } from '../../components/UI/Spinner/Spinner';
import { AppList } from './AppList/AppList';
import { DropDown } from './DropDown/DropDown';
import { SearchBarComponent } from './SearchBar/SearchBar';
import { Footer } from '../../components/Footer/Footer';
import { Pagination } from '../../components/UI/Pagination/Pagination';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectApplications,
  selectIsLoading,
} from '../../store/selectors/application';
import { debounce } from 'lodash';
import { changeApplicationsPage } from '../../store/actions/pagination';
import { selectUser } from '../../store/selectors/user';
import { isUserAdmin } from '../../shared/utility';
import { selectApplicationsPage } from '../../store/selectors/pagination';
import { fetchApplications } from '../../store/actions/index';
import { CreateAppModal } from './CreateAppModal/CreateAppModal';

export default function Applications() {
  const { type } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [queryString, setQueryString] = useState<string>('');
  const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  const loading = useSelector(selectIsLoading);
  const { applications, totalCount } = useSelector(selectApplications);
  const user = useSelector(selectUser);
  const currentPage = useSelector(selectApplicationsPage);

  const handleSearch = debounce((term: string) => {
    dispatch(changeApplicationsPage(0));
    setQueryString(term);

    navigate(`/applications/${type}${term ? '?search=' + term : ''}`);
  }, 300);

  const onDropdownChangeHandler = (newType: string) => {
    dispatch(changeApplicationsPage(0));

    navigate(
      `/applications/${newType}${queryString ? '?search=' + queryString : ''}`
    );
  };

  useEffect(() => {
    setIsAdmin(user && isUserAdmin(user));
  }, [user]);

  useEffect(() => {
    if (currentPage !== undefined) {
      dispatch(
        fetchApplications(type, {
          page: currentPage,
          search: queryString ?? '',
          size: 23,
        })
      );
    }
  }, [type, queryString, currentPage]);

  useEffect(() => {
    if (applications && applications.length === 0 && currentPage !== 0) {
      dispatch(changeApplicationsPage(0));
    }
  }, [applications, currentPage]);

  return (
    <main className="layout__main">
      <div className="apps-wrapper">
        <div className="row">
          <div className="apps-header">
            <SearchBarComponent search={handleSearch} term={queryString} />
            <DropDown
              dropDownValue={type}
              onDropdownChange={onDropdownChangeHandler}
              isAdmin={isAdmin}
            />
          </div>
        </div>
        <div className="content">
          <div className="row">
            {loading ? (
              <Spinner />
            ) : (
              <div className="row">
                <AppList
                  apps={applications}
                  createApplication={() => setIsCreateModalOpen(true)}
                  profile={user}
                />
              </div>
            )}
          </div>
        </div>
        <div className="apps-pagination">
          <div className="row">
            {totalCount > 23 && !loading ? (
              <Pagination
                pages={totalCount / 23}
                changePage={(pageNumber) =>
                  dispatch(changeApplicationsPage(pageNumber.selected))
                }
                pageNumber={currentPage}
              />
            ) : null}
          </div>
        </div>
        {isCreateModalOpen && (
          <CreateAppModal setIsCreateModalOpen={setIsCreateModalOpen} />
        )}
      </div>
      <div className="layout__footer">
        <Footer />
      </div>
    </main>
  );
}
