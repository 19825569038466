import React, { Fragment } from 'react';
import { AppPlaceholderIcon } from '../../../components/UI/Icons/AppPlaceholderIcon/AppPlaceholderIcon';
import { CreateApp } from './CreateApp/CreateApp';
import uuid from 'uuid';
import { Invites } from './Invites/Invites';
import text from '../../../shared/translations/en';
import { Link } from 'react-router-dom';
import { Application } from '../../../shared/types/application';
import { CognitoUser } from '../../../shared/types/user';

const convertUnixExpiryTime = (timestamp) => {
  const d = new Date(timestamp);
  let renderField = null;
  const todaysTimestamp = new Date().getTime();
  if (timestamp < todaysTimestamp) {
    renderField = (
      <Fragment>
        <h6>{text.en['expired-on']}</h6>
        <p className="u-color-red">
          {d.toLocaleDateString(navigator.language)}
        </p>
      </Fragment>
    );
  } else {
    renderField = (
      <Fragment>
        <h6>{text.en['expires-on']}</h6>
        <p>{d.toLocaleDateString(navigator.language)}</p>
      </Fragment>
    );
  }

  return renderField;
};

interface IProps {
  apps: Application[];
  createApplication: () => void;
  profile?: CognitoUser;
}

export function AppList({ apps, createApplication, profile }: IProps) {
  return (
    <div className="applist__wrapper">
      <CreateApp createApplication={createApplication} />

      {apps &&
        apps.map((app: Application) => {
          return (
            <div className="applist__box" key={uuid.v4()}>
              <Link
                to={{
                  pathname: `/application/${app.appId}`,
                  hash: '#overview',
                }}
                data-test="applist-box"
              >
                <h5 className="u-text-2-lines">{app.name}</h5>
                <div className={`app__icon`}>
                  {app.iconUrl ? (
                    <img src={app.iconUrl} alt="" />
                  ) : (
                    <AppPlaceholderIcon />
                  )}
                </div>
                {app.enabled ? (
                  <React.Fragment>
                    <div className={`app__plan`}>
                      <h6>
                        Plan
                        <span className="app__plan-type u-label u-bg-green">
                          {app.plan as string}
                        </span>
                      </h6>
                    </div>
                    <div className={`app__expiration`}>
                      {convertUnixExpiryTime(new Date(app.expiry))}
                    </div>
                  </React.Fragment>
                ) : (
                  <div className="app__status_wrap">
                    <div className="app__status">{text.en.disabled}</div>
                  </div>
                )}
              </Link>
              {app.pendingInvite && (
                <Invites appId={app.appId} email={profile?.attributes.email} />
              )}
            </div>
          );
        })}
    </div>
  );
}
