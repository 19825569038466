import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { MobileNav } from '../MobileNav/MobileNav';
import srLogo from '../../../assets/images/SR_logo_basic.png';
import mdpLogo from '../../../assets/images/MDP_logo.png';
import mdpLogoMobile from '../../../assets/images/MDP_logo-mobile.png';
import { selectUser } from '../../../store/selectors/user';
import useAmplifyAuth from '../../../utils/useAmplifyAuth';
import { AuthNav } from '../AuthNav/AuthNav';
import { UnAuthNav } from '../UnAuthNav/UnAuthNav';

export default function Toolbar(): JSX.Element {
  const user = useSelector(selectUser);
  const { handleSignout } = useAmplifyAuth();

  return (
    <Fragment>
      <div className="toolbar">
        <div className="sr-logo">
          <Link to="/">
            <img src={srLogo} alt="Sportradar" />
          </Link>
        </div>
        <div className="sr-logo--mobile">
          <Link to="/">
            <img src={mdpLogoMobile} alt="Sportradar" />
          </Link>
        </div>
        <div className="mdp-logo">
          <Link to="/">
            <img src={mdpLogo} alt="Mobile development platform" />
          </Link>
        </div>
        <div className="navigation">
          {user ? (
            <AuthNav user={user} handleSignout={handleSignout} />
          ) : (
            <UnAuthNav />
          )}
        </div>
        <MobileNav isAuthenticated={user !== undefined} />
      </div>
    </Fragment>
  );
}
