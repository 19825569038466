import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { selectUser } from 'store/selectors/user';
import useAmplifyAuth from 'utils/useAmplifyAuth';

export function ProtectedRoute() {
  useAmplifyAuth();

  const user = useSelector(selectUser);

  if (user === null) {
    return <Navigate replace to="/login" />;
  }

  return <Outlet />;
}
