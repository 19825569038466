import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import text from '../../shared/translations/en';
import { Application } from '../../shared/types/application';
import {
  selectAvailableDevices,
  selectQrCode,
} from '../../store/selectors/devices';
import { DevicesList } from './DevicesList';
import {
  fetchAvailableDevices,
  getQrCode,
  registerDevice,
} from '../../store/actions/devices';
import { Device } from '../../shared/types/device';

interface IProps {
  app: Application;
}

export function AvailableDevices({ app }: IProps): JSX.Element {
  const dispatch = useDispatch();

  const [countDown, setCountDown] = useState<number>();

  const availableDevices = useSelector(selectAvailableDevices);
  const qrCode = useSelector(selectQrCode);

  const handleRegisterDevice = useCallback(
    (device: Device) => {
      const data = {
        deviceId: device.id,
        mode: device.modeType,
        displayName: '',
        platform: device.platform,
        appId: app.appId ?? app.id,
      };
      dispatch(registerDevice(data));
    },
    [app]
  );

  const startCountDown = useCallback(() => {
    let temp = 60;
    const interval = setInterval(() => {
      setCountDown(temp - 1);
      temp = temp - 1;
      if (temp === 0) {
        clearInterval(interval);
        setCountDown(undefined);
      }
    }, 1000);
  }, []);

  useEffect(() => {
    if (app.appId) {
      setInterval(() => {
        dispatch(fetchAvailableDevices({ appId: app.appId }));
      }, 5000);
      dispatch(getQrCode(app.appId));
    }
  }, [app]);

  return (
    <div className="available-devices">
      <h3>{text.en['available-devices']}</h3>
      <p>{text.en['connect-devices']}</p>
      <div className="qr-code-wrapper">
        {qrCode && <img src={qrCode} className="qr-code" />}
        <button
          className="scanned sr-btn sr-btn-fill sr-btn__md btn-default"
          onClick={startCountDown}
          disabled={countDown && countDown !== 0}
        >
          {text.en.scanned}
        </button>
        {countDown && (
          <p className="countdown">
            Please wait {countDown} seconds and then open the mobile
            application.
          </p>
        )}
      </div>
      <DevicesList
        extraClass={['srm-available']}
        devices={availableDevices}
        columns={['device-id', 'mode', 'platform', 'actions']}
        handleConfirmDevice={handleRegisterDevice}
        confirmed={false}
      />
    </div>
  );
}
