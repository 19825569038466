import React, { Fragment, useCallback, useEffect, useState } from 'react';
import text from '../../shared/translations/en';
import { ContentTabs } from '../../components/ContentTabs/ContentTabs';
import { useDispatch, useSelector } from 'react-redux';
import { selectApplication } from 'store/selectors/application';
import {
  IOSCredentials,
  Platform,
  PlatformEnum,
} from '../../shared/types/application';
import { PlatformModal } from './PlatformModal';
import Android from './Android/Android';
import { isEmpty } from 'lodash';
import { deletePlatform } from '../../store/actions/applications';
import IOS from './iOS/iOS';
import AndroidIcon from '../../assets/images/ico-Android.png';
import iOSIcon from '../../assets/images/ico-iOS.png';
import WebIcon from '../../assets/images/ico-Web.png';
import ServerIcon from '../../assets/images/ico-Server.png';
import SafariIcon from '../../assets/images/ico-Safari.png';
import { PushSettingsForm } from '../../components/PushSettingsForm/PushSettingsForm';
import { WebSafari } from './WebSafari/WebSafari';
import { UniversalPlatform } from './UniversalPlatform/UniversalPlatform';
import { PlatformItem } from '../../shared/types/platform';

const platforms: PlatformItem[] = [
  {
    name: PlatformEnum.ANDROID,
    bigIcon: AndroidIcon,
    translationKey: 'android',
  },
  { name: PlatformEnum.IOS, bigIcon: iOSIcon, translationKey: 'ios' },
  { name: PlatformEnum.WEB, bigIcon: WebIcon, translationKey: 'web' },
  {
    name: PlatformEnum.SERVER,
    bigIcon: ServerIcon,
    translationKey: 'server',
  },
  {
    name: PlatformEnum.SAFARI,
    bigIcon: SafariIcon,
    translationKey: 'web-safari',
  },
];

export function Platforms(): JSX.Element {
  const dispatch = useDispatch();

  const [activePlatform, setActivePlatform] = useState<PlatformEnum>(
    PlatformEnum.ANDROID
  );
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [platformSettings, setPlatformSettings] = useState<Platform[]>([]);

  const app = useSelector(selectApplication);

  const handleCloseDialog = (
    ev: React.MouseEvent<HTMLElement>,
    forceClose: boolean
  ) => {
    // Note: close only on modal overlay click or in case of forceClose
    if (
      forceClose ||
      (ev.target as HTMLElement).classList.contains('modal-overlay')
    ) {
      setIsDialogOpen(false);
    }
  };

  useEffect(() => {
    if (app && app.platformSettings) {
      setPlatformSettings(app.platformSettings[activePlatform] ?? {});
    }
  }, [app, activePlatform]);

  const handleRemoveCredentials = useCallback(
    (platform: string, environment: string) => {
      dispatch(deletePlatform(platform, environment));
      setIsDialogOpen(false);
    },
    []
  );

  const renderPlatform = (data: unknown, environment: string) => {
    switch (activePlatform) {
      case PlatformEnum.IOS:
        return (
          <IOS
            data={data as IOSCredentials}
            environment={environment}
            handleRemoveCredentials={handleRemoveCredentials}
          />
        );
      case PlatformEnum.WEB:
        return (
          <UniversalPlatform
            platform={PlatformEnum.WEB}
            data={data as Platform}
            environment={environment}
            handleRemoveCredentials={handleRemoveCredentials}
          />
        );
      case PlatformEnum.SERVER:
        return (
          <UniversalPlatform
            platform={PlatformEnum.SERVER}
            data={data as Platform}
            environment={environment}
            handleRemoveCredentials={handleRemoveCredentials}
          />
        );
      case PlatformEnum.SAFARI:
        return (
          <WebSafari
            data={data as IOSCredentials}
            environment={environment}
            handleRemoveCredentials={handleRemoveCredentials}
          />
        );
      default:
        return (
          <Android
            data={data as Platform}
            environment={environment}
            handleRemoveCredentials={handleRemoveCredentials}
          />
        );
    }
  };

  return (
    <Fragment>
      <h1>
        <span className="icon-header">
          <i className="icon-16-149-layers-stack" />
        </span>
        {text.en['platforms-and-environments']}
      </h1>
      <div className={'platform__menu'}>
        <ContentTabs
          items={platforms}
          activeItem={activePlatform}
          hadnleOnClick={(platform: PlatformEnum) =>
            setActivePlatform(platform)
          }
        />
        <button
          className="platform__btn-add sr-btn sr-btn-fill sr-btn__md btn-default"
          onClick={() => setIsDialogOpen(true)}
          data-test="add-platform-button"
        >
          <i className="icon-16-152-plus" />
          {text.en['add-environment']}
        </button>
      </div>
      {isEmpty(platformSettings) ? (
        <span className={'platform__no-environments'}>
          {text.en['no-env-added-yet']}
        </span>
      ) : (
        <>
          <div className="platform__box">
            {Object.keys(platformSettings).indexOf('pushSettings') !== -1 && (
              <PushSettingsForm
                serverKey={platformSettings['pushSettings'].serverKey}
                platform={activePlatform}
              />
            )}
          </div>
          <div className="platform__box">
            {Object.keys(platformSettings).map(
              (environment, index) =>
                environment !== 'pushSettings' && (
                  <span key={index}>
                    {renderPlatform(platformSettings[environment], environment)}
                  </span>
                )
            )}
          </div>
        </>
      )}
      {/* Modal dialog */}
      {isDialogOpen && (
        <div
          className={'modal-overlay'}
          onClick={(event: React.MouseEvent<HTMLElement>) =>
            handleCloseDialog(event, false)
          }
        >
          <PlatformModal
            handleCloseDialog={handleCloseDialog}
            selectedPlatform={activePlatform}
          />
        </div>
      )}
    </Fragment>
  );
}
