export const getDocumentationUrl = () => {
  const hostname = window.location.hostname;
  const documentationUrls = {
    dev: 'https://artifacts.mdp.sportradar.com/docs/mdp/develop/index.html',
    devNonProd:
      'https://artifacts.mapinonprod.sportradar.com/docs/mdp/develop/index.html',
    stg: 'https://artifacts.mdp.sportradar.com/docs/mdp/staging/index.html',
    prod: 'https://artifacts.mdp.sportradar.com/docs/mdp/production/index.html',
  };

  let environment = '';
  if (hostname.indexOf('localhost') > -1) {
    environment = 'dev';
  }
  if (hostname.indexOf('artifacts.mapinonprod.sportradar.com') > -1) {
    environment = 'devNonProd';
  }
  if (hostname.indexOf('dashboard.mapinonprod.sportradar.com') > -1) {
    environment = 'stg';
  }
  if (hostname.indexOf('mdp.sportradar.com') > -1) {
    environment = 'prod';
  }

  return documentationUrls[environment];
};
