import React from 'react';

export function Indemnification(): JSX.Element {
  return (
    <>
      <h2>13. Indemnification</h2>
      <p>
        To the fullest extent permitted by law, you agree to defend, indemnify
        and hold harmless Sportradar, its affiliates and their respective
        directors, officers, employees and agents from and against any and all
        claims, actions, suits or proceedings, as well as any and all direct or
        indirect losses, liabilities, damages, costs and expenses (including
        reasonable attorney fees) arising out of or accruing from (a) your use
        of the MDP, or Mobile SDK, (b) any application you develop with the use
        of MDP or Mobile SDK that infringes any copyright, trademark, trade
        secret, trade dress, patent or other intellectual property right of any
        person or defames any person or violates their rights of publicity or
        privacy, and (c) any non-compliance by you with this Agreement.
      </p>
    </>
  );
}
