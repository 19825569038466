import { RootState } from 'shared/types/store';

export const selectApplication = (state: RootState) => state.apps.singleApp;

export const selectUserApplications = (state: RootState) => state.admin.user;

export const selectError = (state: RootState) => state.apps.error;

export const selectIsLoading = (state: RootState) => state.apps.loading;

export const selectApplications = (state: RootState) => state.apps.response;
