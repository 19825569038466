import React from 'react';

export function SDKLicense(): JSX.Element {
  return (
    <>
      <h2>4. SDK license</h2>
      <h3>4.1 License Provision</h3>
      <p>
        According to the terms of these Terms and Conditions, shall you accept
        them, Sportradar grants you a limited, worldwide, royalty-free,
        non-assignable and non-exclusive license to use the SDK solely according
        to the terms set out below.
      </p>
      <h3>4.2 Intellectual Property Rights</h3>
      <p>
        You agree that Sportradar or third parties own all legal right, title
        and interest in and to the SDK, including any Intellectual Property
        Rights that subsist in the SDK. "Intellectual Property Rights" means any
        and all rights under patent law, copyright law, trade secret law,
        trademark law, and any and all other proprietary rights. Sportradar
        reserves all rights not expressly granted to you.
      </p>
      <h3>4.3 Copying and Redistribution</h3>
      <p>
        You may not use the SDK for any purpose not expressly permitted by this
        Agreement.
      </p>
      <h3>4.4 Derivatives Distribution</h3>
      <p>
        You agree that you will not take any actions that may cause or result in
        the fragmentation of Sportradar’s software, including but not limited to
        distributing, participating in the creation of, or promoting in any way
        a software development kit derived from the SDK.
      </p>
      <h3>4.5 Included Open Source Code</h3>
      <p>
        Use, reproduction and distribution of components of the SDK licensed
        under an open source software license are governed solely by the terms
        of that open source software license and not these Terms and Conditions.
      </p>
      <h3>4.6 SDK Changes</h3>
      <p>
        You agree that the form and nature of the SDK that Sportradar provides
        may change without prior notice to you and that future versions of the
        SDK may be incompatible with applications developed on previous versions
        of the SDK. You agree that Sportradar may stop (permanently or
        temporarily) providing the SDK (or any features within the SDK) to you
        or to users generally at Sportradar's sole discretion, without prior
        notice to you.
      </p>
      <h3>4.7 Use of Trademarks</h3>
      <p>
        Nothing in this Agreement gives you a right to use any of Sportradar's
        tradenames, trademarks, service marks, logos, domain names, or other
        distinctive brand features.
      </p>
      <h3>4.8 Proprietary Rights Notices</h3>
      <p>
        You agree that you will not remove, obscure, or alter any proprietary
        rights notices (including copyright and trademark notices) that may be
        affixed to or contained within the SDK.
      </p>
    </>
  );
}
