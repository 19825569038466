import React from 'react';
import { Acceptance } from './Acceptance';
import { Account } from './Account';
import { Applications } from './Applications';
import { DeveloperCredentials } from './DeveloperCredentials';
import { Exclusion } from './Exclusion';
import { General } from './General';
import { Indemnification } from './Indemnification';
import { Jurisdiction } from './Jurisdiction';
import { MDPUsage } from './MDPUsage';
import { Miscellaneous } from './Miscellaneous';
import { Privacy } from './Privacy';
import { SDKLicense } from './SDKLicense';
import { SubjectMatter } from './SubjectMatter';
import { Termination } from './Termination';
import { Usage } from './Usage';
import { Violations } from './Violations';

export function Terms(): JSX.Element {
  return (
    <div className="section-terms">
      <section className="section-terms-heading">
        <h1>Sportradar Mobile Development Platform – Terms and Conditions</h1>
        <p>
          By registering for a Sportradar Mobile Development Platform account
          you agree to the following
        </p>
      </section>
      <hr color="lightgrey" />
      <section className="section-terms-content">
        <General />
        <SubjectMatter />
        <Acceptance />
        <SDKLicense />
        <Usage />
        <Account />
        <DeveloperCredentials />
        <Privacy />
        <Applications />
        <MDPUsage />
        <Termination />
        <Exclusion />
        <Indemnification />
        <Violations />
        <Jurisdiction />
        <Miscellaneous />
      </section>
    </div>
  );
}
