import React, { useEffect, useState } from 'react';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import text from '../../../shared/translations/en';

import './DropDown.scss';

const styles = {
  icon: {
    enabled: { fill: 'rgba(0, 0, 0, 0.87)' },
    width: '24px',
    height: '24px',
  },
  menuItem: {
    color: '#404040',
    fontFamily: 'Source Sans Pro',
    fontSize: '15px',
    display: 'block',
    padding: '6px 16px',
  },
  root: {
    fontFamily: 'Source Sans Pro',
    marginLeft: '15px',
    fontSize: '15px',
  },
  wrapper: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
};

interface IProps {
  dropDownValue: string;
  onDropdownChange: (value: string) => void;
  isAdmin: boolean;
}

export function DropDown({ dropDownValue, isAdmin, onDropdownChange }: IProps) {
  const [options, setOptions] = useState<JSX.Element[]>();

  const handleChange = (event: SelectChangeEvent) =>
    onDropdownChange(event.target.value);

  const defaultOptions = [
    <MenuItem key={'my'} value={'my'} style={styles.menuItem}>
      {text.en['my-apps']}
    </MenuItem>,
  ];

  const adminOptions = [
    <MenuItem key={'all'} value={'all'} style={styles.menuItem}>
      {text.en['all-apps']}
    </MenuItem>,
    <MenuItem key={'disabled'} value={'disabled'} style={styles.menuItem}>
      {text.en['disabled-apps']}
    </MenuItem>,
  ];

  useEffect(() => {
    if (isAdmin) {
      setOptions(defaultOptions.concat(adminOptions));
    } else {
      setOptions(defaultOptions);
    }
  }, [isAdmin]);

  return (
    <div style={styles.wrapper}>
      <span>
        <FilterListIcon style={styles.icon} />
      </span>
      {options?.length > 0 && (
        <Select
          onChange={handleChange}
          value={dropDownValue}
          variant="standard"
          style={styles.root}
          disableUnderline
        >
          {options.map((option: JSX.Element) => option)}
        </Select>
      )}
    </div>
  );
}
