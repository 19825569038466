import text from './en';
// set for translating app
export default {
  en: {
    'app.title': 'English title',
    'app.button': 'English title for button',
    'app.button.save': text.en['save'],
  },
  ru: {
    'app.title': 'Russian title',
    'app.button': 'Russian title for button',
  },
};
/*
    procedure to set another language translations
    ** index.js addLocaleData(lang);
    ** actions and reducers are set to receive lang
    ** in component import { FormattedMessage } from 'react-intl'
    ** use it <p className="u-color-orange"><FormattedMessage id={"app.title"} defaultMessage="Default message"/></p>
*/
