import React from 'react';
import { IPushSettings, PlatformEnum } from '../../../shared/types/application';
import text from '../../../shared/translations/en';
import APNCredentials from '../APNCredentials/APNCredentials';

interface IProps {
  environment: string;
  pushSettings: IPushSettings;
}

export function PushSettings({
  pushSettings,
  environment,
}: IProps): JSX.Element {
  return (
    <div className="col-1-of-2" key={`ios-${environment}-pushSettings`}>
      <APNCredentials
        token={pushSettings.token}
        certificate={pushSettings.certificate}
        platform={PlatformEnum.IOS}
        mode={environment}
      />
      {/* buttons */}
      <div className="btn-wrapper">
        <button
          className="sr-btn sr-btn__sm btn-cancel"
          title={text.en.cancel}
          onClick={() => {}}
        >
          {text.en.cancel}
        </button>
        <button
          className="sr-btn sr-btn__sm btn-default"
          title={text.en.save}
          onClick={() => {}}
        >
          {text.en.save}
        </button>
      </div>
    </div>
  );
}
