import React, { useEffect, useState } from 'react';
import { Filters } from '../SettingsTable/Filters';
import text from '../../shared/translations/en';
import { Application } from '../../shared/types/application';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectRegisteredDevices,
  selectActivePlatforms,
  selectActiveEnvironments,
} from '../../store/selectors/devices';
import { DevicesList } from './DevicesList';
import {
  fetchRegisteredDevices,
  setSelectedPlatforms,
  setSelectedEnvironments,
} from '../../store/actions/devices';
import {
  getActivePlatforms,
  getAllEnvironments,
} from '../../utils/DataHelpers';
import { Loader } from '../Loader/Loader';
import { Device } from '../../shared/types/device';
import { PushNotificationControl } from './PushNotificationControl';

interface IProps {
  app: Application;
}

export function RegisteredDevices({ app }: IProps): JSX.Element {
  const dispatch = useDispatch();

  const [filteredDevices, setFilteredDevices] = useState<Device[]>([]);

  const registeredDevices = useSelector(selectRegisteredDevices);
  const selectedPlatforms = useSelector(selectActivePlatforms);
  const selectedEnvironments = useSelector(selectActiveEnvironments);

  const platforms =
    app.platformSettings && getActivePlatforms(app.platformSettings);
  const environments =
    app.platformSettings && getAllEnvironments(app.platformSettings);

  useEffect(() => {
    if (app.appId) {
      dispatch(fetchRegisteredDevices({ appId: app.appId }));
    }
  }, [app]);

  useEffect(() => {
    setFilteredDevices(registeredDevices);
  }, [registeredDevices]);

  useEffect(() => {
    let devices = registeredDevices;

    if (!selectedEnvironments.includes('All')) {
      devices = registeredDevices.filter((device) =>
        selectedEnvironments.includes(device.modeType)
      );
    }

    if (!selectedPlatforms.includes('All')) {
      devices = devices.filter((device) =>
        selectedPlatforms.includes(device.platform)
      );
    }

    setFilteredDevices(devices);
  }, [selectedEnvironments, selectedPlatforms, registeredDevices]);

  if (
    !platforms ||
    !environments ||
    !selectedPlatforms ||
    !selectedEnvironments
  ) {
    return <Loader loading={true} />;
  }
  return (
    <div className="app-devices-filters">
      <Filters
        title={text.en.platforms}
        align={'left'}
        items={platforms}
        selectedItems={selectedPlatforms}
        onClick={(platform: string) => dispatch(setSelectedPlatforms(platform))}
      />
      <Filters
        title={text.en.environments}
        items={environments}
        align={'right'}
        selectedItems={selectedEnvironments}
        onClick={(environment: string) =>
          dispatch(setSelectedEnvironments(environment))
        }
      />
      <div className={'app-devices-filters-content'}>
        <h3 className={'app-devices-title srm-registered'}>
          {text.en['registered-devices']}
        </h3>
        <p className="app-devices-description">
          {text.en['registered-devices-desc']}
        </p>
        <div className={'app-devices-info'}>
          Selected devices:
          <em>{registeredDevices ? registeredDevices.length : 0}</em>
        </div>
        <DevicesList
          devices={filteredDevices}
          columns={[
            'device-name',
            'device-id',
            'mode',
            'platform',
            'actions',
            'push-token',
            'language',
          ]}
          confirmed={true}
          app={app.appId}
        />
        <h3 className={'app-devices-title srm-registered'}>
          {text.en['demo-notifications']}
        </h3>
        <p className="app-devices-description">
          {text.en['demo-notifications-desc']}
        </p>
        <PushNotificationControl devices={registeredDevices} />
      </div>
    </div>
  );
}
