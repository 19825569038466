import React from 'react';

export function Applications(): JSX.Element {
  return (
    <>
      <h2>9. Applications from third parties</h2>
      <h3>9.1 Loss or Damage Liability</h3>
      <p>
        If you use the MDP or the Mobile SDK to run applications developed by a
        third party or that access data, content or resources provided by a
        third party, you agree that Sportradar is not responsible for those
        applications, data, content, or resources and that Sportradar is not
        liable for any loss or damage that you may experience as a result of the
        use or access of any of those third party applications, data, content,
        or resources.{' '}
      </p>
      <h3>9.2 Intellectual Property Rights</h3>
      <p>
        You should be aware that the data, content, and resources presented to
        you through such a third-party application may be protected by
        intellectual property rights which are owned by the providers (or by
        other persons or companies on their behalf). You may not modify, rent,
        lease, loan, sell, distribute or create derivative works based on these
        data, content, or resources (either in whole or in part) unless you have
        been specifically given permission to do so by the relevant owners.
      </p>
      <h3>9.3 Third Party Terms and Agreements</h3>
      <p>
        You acknowledge that your use of such third-party applications, data,
        content, or resources may be subject to separate terms between you and
        the relevant third party. In that case, this License Agreement does not
        affect your legal relationship with these third parties.
      </p>
    </>
  );
}
