import React from 'react';
import { PieChart as ReactPieChart } from 'react-minimal-pie-chart';
import { Service } from '../../shared/types/costReport';
import { ChartLegend } from './ChartLegend';

import './PieChart.scss';

interface IProps {
  reports: Service[];
}

const colors = [
  '#16034d',
  '#2d077d',
  '#4409ae',
  '#5a0cdf',
  '#711110',
  '#882441',
  '#9f276c',
  '#b6299c',
  '#cd2bc7',
];

export function PieChart({ reports }: IProps) {
  const data = reports
    .map((report, index) => ({
      title: report.service,
      value: +report.cost,
      color: index < 10 ? colors[index] : '#fff',
    }))
    .filter((chartData) => chartData.value !== 0);

  return (
    <div className="pie-chart">
      <ReactPieChart
        data={data.length < 10 ? data : data.slice(0, 10)}
        lineWidth={15}
        paddingAngle={5}
        style={{ height: '300px', width: '50%' }}
        radius={35}
        labelPosition={112}
      />
      <ChartLegend data={data.length < 10 ? data : data.slice(0, 10)} />
    </div>
  );
}
