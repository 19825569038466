import * as actionTypes from './actionTypes';
import { toastr } from 'react-redux-toastr';
import { text } from '../../shared/translations/en-v1';
import { API } from 'aws-amplify';

// All modifiers
export const fetchAllPushModifiersSuccess = (allModifiers) => {
  return {
    type: actionTypes.FETCH_ALL_PUSH_MODIFIERS_SUCCESS,
    allModifiers,
  };
};

export const fetchAllPushModifiersFail = () => {
  return { type: actionTypes.FETCH_ALL_PUSH_MODIFIERS_FAIL };
};

export const getAllPushModifiers = () => {
  return (dispatch) => {
    API.get('mdp', `/push_modifier`, {})
      .then((response) => {
        dispatch(fetchAllPushModifiersSuccess(response));
      })
      .catch(() => {
        dispatch(fetchAllPushModifiersFail());
      });
  };
};
// App modifiers
export const fetchAppPushModifiersSuccess = (appModifiers) => {
  return {
    type: actionTypes.FETCH_APP_PUSH_MODIFIERS_SUCCESS,
    appModifiers,
  };
};

export const fetchAppPushModifiersFail = () => {
  return { type: actionTypes.FETCH_APP_PUSH_MODIFIERS_FAIL };
};

export const getAppPushModifiers = (appId) => {
  return (dispatch) => {
    API.get('mdp', `/applications/${appId}/push_modifier`, {})
      .then((response) => {
        dispatch(fetchAppPushModifiersSuccess(response));
      })
      .catch(() => {
        dispatch(fetchAppPushModifiersFail());
      });
  };
};

export const addAppPushModifier = (configObj) => {
  return async (dispatch, getState) => {
    try {
      await API.post(
        'mdp',
        `/applications/${
          getState().apps.singleApp.id ?? getState().apps.singleApp.appId
        }/push_modifier`,
        { body: configObj }
      );
      toastr.success(text.success, text.modifierCreated);
      dispatch(
        getAppPushModifiers(
          getState().apps.singleApp.id ?? getState().apps.singleApp.appId
        )
      );
      dispatch({ type: actionTypes.ADD_PUSH_MODIFIER_SUCCESS });
    } catch (error) {
      toastr.error(
        text.error,
        error.response.data.message || text.genericError
      );
      dispatch({ type: actionTypes.ADD_PUSH_MODIFIER_FAIL });
    }
  };
};

export const deleteAppPushModifier = (className) => {
  return async (dispatch, getState) => {
    try {
      await API.del(
        'mdp',
        `/applications/${
          getState().apps.singleApp.id ?? getState().apps.singleApp.appId
        }/push_modifier/${className}`,
        {}
      );
      toastr.success(text.success, text.modifierDeleted);
      dispatch(
        getAppPushModifiers(
          getState().apps.singleApp.id ?? getState().apps.singleApp.appId
        )
      );
    } catch (error) {
      toastr.error(
        text.error,
        error.response.data.message || text.genericError
      );
    }
  };
};
