import React, { useCallback, useEffect, useState } from 'react';
import { PlatformEnum } from '../../shared/types/application';
import text from '../../shared/translations/en';
import { useDispatch } from 'react-redux';
import { updateFirebaseCredentials } from '../../store/actions/applications';

interface IProps {
  serverKey: string;
  platform: PlatformEnum;
}

export function PushSettingsForm({ serverKey, platform }: IProps) {
  const dispatch = useDispatch();

  const [inputValue, setInputValue] = useState<string>(serverKey);
  const [showButtons, setShowButtons] = useState<boolean>(false);

  useEffect(() => {
    setInputValue(serverKey);
  }, [serverKey]);

  useEffect(() => {
    setShowButtons(inputValue !== serverKey && inputValue !== '');
  }, [inputValue, serverKey]);

  const handleUpdateServerKey = useCallback(() => {
    const data = {
      platform,
      serverKey: inputValue,
    };
    dispatch(updateFirebaseCredentials(data));
  }, [inputValue, platform]);

  return (
    <div className="app-detail__container" key={`${platform}-pushSettings`}>
      <div className="col-1-of-2">
        <div className="input-row__wrapper">
          <label className="input-row__label">{text.en['server-key']}</label>
          <div className="input-row__input-contanier">
            <span className="input-icon">
              <i className="icon-16-006-edit" />
            </span>
            <input
              value={inputValue ?? ''}
              className="input-row__input"
              onChange={(e) => setInputValue(e.target.value)}
              placeholder={text.en['server-key-placeholder']}
            />
          </div>
          {/*Additional text*/}
          <div className="input-row__support-msg"></div>
        </div>
      </div>
      <div className="col-1-of-2">
        {/*Buttons*/}
        {showButtons ? (
          <div className="btn-wrapper">
            <button
              className="sr-btn sr-btn__sm btn-default"
              title={text.en.save}
              onClick={handleUpdateServerKey}
            >
              {text.en.save}
            </button>
            <button
              className="sr-btn sr-btn__sm btn-cancel"
              title={text.en.cancel}
              onClick={() => setInputValue(serverKey)}
            >
              {text.en.cancel}
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
}
