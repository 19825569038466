import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextInput } from '../../../components/UI/Input/TextInput';
import { PlatformEnum, IPushSettings } from '../../../shared/types/application';
import APNCredentials from '../APNCredentials/APNCredentials';
import { uploadApnsCertificate } from '../../../store/actions/applications';
import text from '../../../shared/translations/en';
import { selectModalError } from '../../../store/selectors/modalError';

interface IProps {
  environment: string;
  data: IPushSettings;
}

export function PushSettingsForm({ environment, data }: IProps): JSX.Element {
  const dispatch = useDispatch();

  const [urlFormat, setUrlFormat] = useState<string>(
    data.certificate?.extra ?? ''
  );
  const [showButtons, setShowButtons] = useState<boolean>(false);

  const error = useSelector(selectModalError);

  useEffect(() => {
    setUrlFormat(data.certificate?.extra ?? '');
  }, [data]);

  useEffect(() => {
    setShowButtons(urlFormat !== data.certificate?.extra && urlFormat !== '');
  }, [urlFormat, data]);

  const handleUpdateUrlFormat = useCallback(() => {
    const credentials = {
      platform: PlatformEnum.SAFARI,
      mode: environment,
      extra: urlFormat,
      file: undefined,
    };
    dispatch(uploadApnsCertificate(credentials));
  }, [urlFormat]);

  return (
    <div className="col-1-of-2" key={`safari-${environment}-pushSettings`}>
      <APNCredentials
        token={data.token}
        certificate={data.certificate}
        mode={environment}
        platform={PlatformEnum.SAFARI}
      />
      <TextInput
        label={text.en['url-format']}
        onInputChange={(e: ChangeEvent<HTMLInputElement>) =>
          setUrlFormat(e.target.value)
        }
        inputValue={urlFormat}
        inputName={text.en.extra}
        inputPlaceholder={text.en['url-format-placeholder']}
        requiredField={false}
      />
      {/* buttons */}
      {showButtons ? (
        <div className="btn-wrapper">
          <button
            className="sr-btn sr-btn__sm btn-cancel"
            title={text.en.cancel}
            onClick={() => setUrlFormat(data.certificate.extra ?? '')}
          >
            {text.en.cancel}
          </button>
          <button
            className="sr-btn sr-btn__sm btn-default"
            title={text.en.save}
            onClick={handleUpdateUrlFormat}
          >
            {text.en.save}
          </button>
        </div>
      ) : null}
      {error && error.message && (
        <div style={{ color: 'red' }}>There was an error</div>
      )}
    </div>
  );
}
