import React from 'react';
import text from '../../shared/translations/en';
import { Service } from '../../shared/types/costReport';

interface IProps {
  reports: Service[];
}
export function CostReportPerService({ reports }: IProps) {
  return (
    <div>
      <h3>{text.en['cost-per-service']}</h3>
      <table>
        <thead>
          <tr>
            <td>{text.en['service']}</td>
            <td>{text.en['cost']} (USD)</td>
          </tr>
        </thead>
        <tbody>
          {[...reports]
            .sort((a: Service, b: Service) => (+a.cost < +b.cost ? 1 : -1))
            .map((report: Service) => (
              <tr key={report.service}>
                <td>{report.service}</td>
                <td>{report.cost}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}
