import React, { Fragment } from 'react';
import text from '../../shared/translations/en';
import { PushSetting } from '../../shared/types/pushSettings';

interface IProps {
  pushModifiers: PushSetting[];
  selectedModifier: PushSetting;
  setSelectedModifier: (modifier: PushSetting) => void;
}

export function PushModifiers({
  pushModifiers,
  selectedModifier,
  setSelectedModifier,
}: IProps) {
  return (
    <Fragment>
      <label className="input-row__label" htmlFor="">
        &nbsp;
      </label>
      <select
        className="select"
        onChange={(e) => setSelectedModifier(pushModifiers[e.target.value])}
      >
        <option value="">{text.en['select-modifier']}</option>
        {pushModifiers.map((current, index) => (
          <option key={index} value={index}>
            {current.className}
          </option>
        ))}
      </select>
      <p className="body-3 u-margin-top-18">
        {selectedModifier && selectedModifier.description
          ? selectedModifier.description
          : null}
      </p>
    </Fragment>
  );
}
