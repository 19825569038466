import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import text from '../../shared/translations/en';
import { fetchCostReport } from '../../store/actions/costReport';
import {
  selectCostReport,
  selectIsLoading,
} from '../../store/selectors/costReport';
import { CostReportView } from './CostReportView';

const years = Array.from({ length: 10 }, (_, i) =>
  (new Date().getFullYear() - i).toString()
);
const allMonths: string[] = text.en.months;

export function CostReport(): JSX.Element {
  const dispatch = useDispatch();

  const [selectedMonth, setSelectedMonth] = useState<string>(
    new Date().getMonth().toString()
  );
  const [selectedYear, setSelectedYear] = useState<string>(years[0]);
  const [months, setMonths] = useState<string[]>(allMonths);

  const costReport = useSelector(selectCostReport);
  const isLoading = useSelector(selectIsLoading);

  useEffect(() => {
    selectedMonth &&
      selectedYear &&
      dispatch(fetchCostReport(selectedMonth, selectedYear));
  }, [selectedMonth, selectedYear]);

  useEffect(() => {
    if (+selectedYear === new Date().getFullYear()) {
      setMonths(allMonths.slice(0, new Date().getMonth() + 1));
    } else {
      setMonths(allMonths);
    }
  }, [selectedYear]);

  return (
    <main className="layout__main">
      <div className="cost-report">
        <div className="row">
          <h1>{text.en['cost-report']}</h1>
        </div>
        <div className="row">
          <div className="col-1-of-2">
            <select
              className="select"
              onChange={(event: ChangeEvent<HTMLSelectElement>) =>
                setSelectedMonth(event.target.value)
              }
              value={selectedMonth}
            >
              {months.map((month: string, index: number) => (
                <option key={month} value={index}>
                  {month}
                </option>
              ))}
            </select>
          </div>
          <div className="col-1-of-2">
            <select
              className="select"
              onChange={(event: ChangeEvent<HTMLSelectElement>) =>
                setSelectedYear(event.target.value)
              }
              value={selectedYear}
            >
              {years.map((year: string) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="row">
          <CostReportView costReport={costReport} isLoading={isLoading} />
        </div>
      </div>
    </main>
  );
}
