import * as actionTypes from './actionTypes';

export const hideModal = () => {
  return {
    type: actionTypes.CLOSE_MODAL,
  };
};

export const modalError = (errorObj) => {
  return {
    type: actionTypes.MODAL_ERROR,
    payload: errorObj,
  };
};

export const modalUploadSucces = () => {
  return {
    type: actionTypes.MODAL_UPLOAD_SUCCESS,
  };
};
