import React, { Fragment, useState } from 'react';
import {
  IOSCredentials,
  PlatformEnum,
} from '../../../shared/types/application';
import Modal from '../../../components/Modal/Modal';
import { PlatformRow } from '../../../components/PlatformRow/PlatformRow';
import { PlatformBox } from '../../../components/PlatformBox/PlatformBox';
import text from '../../../shared/translations/en';
import { ModalButton } from '../../../components/UI/ModalButton/ModalButton';
import { Credentials } from './Credentials';
import { PushSettings } from './PushSettings';

interface IProps {
  data: IOSCredentials;
  environment: string;
  handleRemoveCredentials: (platform: string, environment: string) => void;
}

export default function IOS({
  data,
  environment,
  handleRemoveCredentials,
}: IProps): JSX.Element {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  return (
    <Fragment key="ios">
      <PlatformBox>
        <PlatformRow>
          <>
            <div className="platform__box-header">
              {environment}
              {/* delete button */}
              <button
                title="Remove"
                onClick={() => setIsDialogOpen(true)}
                className="platform__btn-remove sr-btn-white sr-btn sr-btn__sm btn-default u-btn-right"
              >
                <i className="icon-16-079-trash" />
                {text.en.remove}
              </button>
            </div>
            <div className="platform__box-content">
              {Object.keys(data).map((property) =>
                property && property === 'pushSettings' ? (
                  <Fragment key={property}>
                    <PushSettings
                      environment={environment}
                      pushSettings={data[property]}
                    />
                  </Fragment>
                ) : (
                  <Fragment key={property}>
                    <Credentials
                      environment={environment}
                      bundle={data[property]?.value}
                    />
                  </Fragment>
                )
              )}
            </div>
          </>
        </PlatformRow>
      </PlatformBox>
      {isDialogOpen ? (
        <Modal
          title={`Delete "${environment}" environment?`}
          contentText={`This action will affect all devices in ${environment} environment.`}
          onDismiss={() => setIsDialogOpen(false)}
          actions={() => (
            <Fragment>
              <ModalButton
                btnText={text.en.no}
                actionHandler={() => setIsDialogOpen(false)}
                type="transparent"
              />
              <ModalButton
                btnText={text.en.yes}
                actionHandler={() =>
                  handleRemoveCredentials(PlatformEnum.IOS, environment)
                }
                type="colored"
              />
            </Fragment>
          )}
        />
      ) : null}
    </Fragment>
  );
}
