import React from 'react';
import text from '../../shared/translations/en';
import iconRestricted from '../../assets/images/ico-restricted.png';
import { useNavigate } from 'react-router';

export function NotAllowed(): JSX.Element {
  const navigate = useNavigate();
  const redirect = () => navigate('/applications/my');

  setTimeout(redirect, 5000);
  return (
    <div className="error-container">
      <div className="error-wrapper">
        <div className="error-icon">
          <img src={iconRestricted} alt={text.en.forbidden} />
        </div>
        <h1 className="error-title">{text.en['restricted-area']}!</h1>
        <p>{text.en['not-allowed-main']}</p>
        <h3 className="error-code">Error Code: &nbsp;403</h3>
        <p>{text.en['redirecting']}...</p>
      </div>
    </div>
  );
}
