import React, { Fragment, useState } from 'react';
import Modal from '../../../components/Modal/Modal';
import { ModalButton } from '../../../components/UI/ModalButton/ModalButton';
import text from '../../../shared/translations/en';
import {
  IOSCredentials,
  PlatformEnum,
} from '../../../shared/types/application';
import { PlatformRow } from '../../../components/PlatformRow/PlatformRow';
import { Credentials } from './Credentials';
import { PushSettingsForm } from './PushSettingsForm';

interface IProps {
  data: IOSCredentials;
  environment: string;
  handleRemoveCredentials: (platform: string, environment: string) => void;
}

export function WebSafari({
  data,
  environment,
  handleRemoveCredentials,
}: IProps): JSX.Element {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  return (
    <Fragment>
      <PlatformRow key={`safari-${environment}`}>
        <>
          <div className="platform__box-header">
            {environment}
            {/* delete button */}
            <button
              title="Remove"
              className="platform__btn-remove sr-btn sr-btn__sm btn-default sr-btn-white u-btn-right"
              onClick={() => setIsDialogOpen(true)}
            >
              <i className="icon-16-079-trash" />
              {text.en.remove}
            </button>
          </div>
          <div className="platform__box-content">
            {Object.keys(data).map((property, idx) =>
              property && property === 'pushSettings' ? (
                <Fragment key={`websafari-${idx}`}>
                  <PushSettingsForm
                    environment={environment}
                    data={data.pushSettings}
                  />
                </Fragment>
              ) : (
                <Fragment key={`websafari-${idx}`}>
                  {data && (
                    <Credentials
                      environment={environment}
                      data={data.credentials}
                    />
                  )}
                </Fragment>
              )
            )}
          </div>
        </>
      </PlatformRow>
      {isDialogOpen ? (
        <Modal
          title={`Delete "${environment}" environment?`}
          contentText={`This action will affect all devices in ${environment} environment.`}
          actions={() => (
            <Fragment>
              <ModalButton
                btnText={text.en.no}
                actionHandler={() => setIsDialogOpen(false)}
                type="transparent"
              />
              <ModalButton
                btnText={text.en.yes}
                actionHandler={() =>
                  handleRemoveCredentials(PlatformEnum.SAFARI, environment)
                }
                type="colored"
              />
            </Fragment>
          )}
          onDismiss={() => setIsDialogOpen(false)}
        />
      ) : null}
    </Fragment>
  );
}
