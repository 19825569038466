import React, { useCallback, useState } from 'react';
import { Device } from '../../shared/types/device';
import { toastr } from 'react-redux-toastr';
import * as Clipboard from '../../utils/Clipboard';

interface IProps {
  pushBadge: string;
  pushTokenText: string;
  device: Device;
  hasPushToken: string;
}

export function PushTokenActions({
  pushBadge,
  pushTokenText,
  device,
  hasPushToken,
}: IProps): JSX.Element {
  const [copied, setCopied] = useState<Device>();

  const copyToClipboard = useCallback(() => {
    Clipboard.copy(device?.pushToken ?? 'No token');
    toastr.success('Token was successfully copied to clipboard');
    setCopied(device);
  }, [device]);

  const handleShowCopied = useCallback(
    () => (copied && copied.id === device.id ? ' srm-visible' : ''),
    []
  );

  return (
    <span className={'srm-push-token'}>
      <span className={`srm-badge ${pushBadge}`}>{pushTokenText}</span>
      {hasPushToken && (
        <>
          <i onClick={copyToClipboard} className={'icon icon-16-346-copy'} />
          <span className={`srm-push-token-tooltip ${handleShowCopied}`}>
            Copied to clipboard!
          </span>
        </>
      )}
    </span>
  );
}
