import { UserWithRole } from './user';

export interface Application {
  appId: string;
  id: string;
  name: string;
  description: string | null;
  iconUrl: string | null;
  enabled: boolean;
  settings: PlatformSettings[];
  adminSettings: PlatformSettings[];
  platformSettings: Platform[];
  users: UserWithRole[];
  ownerEmail: string;
  pushNotificationsDelay: number;
  invites: UserWithRole[];
  plan: Plan | string;
  expiry: string;
  pendingInvite: boolean;
}

export interface Platform {
  value: string;
  extra: string;
}

export enum PlatformEnum {
  ANDROID = 'Android',
  IOS = 'iOS',
  WEB = 'Web',
  SERVER = 'Server',
  SAFARI = 'WebSafari',
}

export interface PlatformOptions {
  'development-environment': PlatformModalOptions;
  'production-environment': PlatformModalOptions;
  'custom-environment': PlatformModalOptions;
}

export interface PlatformModalOptions {
  type: string;
  checked: boolean;
  value: string;
}

export interface IOSCredentials {
  credentials: Platform;
  pushSettings: IPushSettings;
}

export interface IPushSettings {
  certificate: Certificate;
  token: TokenData;
}

export interface PlatformSettings {
  key: string;
  value: string;
  platform: string;
  modeType: string;
}

export interface Certificate {
  extra: string;
  filename: string;
  expiryDate: string;
}

export interface TokenData {
  email: string;
  keyId: string;
  teamId: string;
}

export interface Plan {
  name: string;
  expiryTime: string;
}
