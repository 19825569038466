import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { PlatformEnum } from '../../../shared/types/application';
import { TextInput } from '../../../components/UI/Input/TextInput';
import text from '../../../shared/translations/en';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../../../store/selectors/user';
import { updatePlatformCredentials } from '../../../store/actions/applications.js';

interface IProps {
  bundle: string;
  environment: string;
}
export function Credentials({ environment, bundle }: IProps): JSX.Element {
  const platform = PlatformEnum.IOS;

  const dispatch = useDispatch();

  const user = useSelector(selectUser);

  const [inputValue, setInputValue] = useState<string>(bundle);
  const [showSaveButton, setShowSaveButton] = useState<boolean>(false);

  const handleUpdateCredentials = useCallback(() => {
    const data = {
      platform,
      mode: environment,
      credentials: inputValue,
      extra: null,
    };
    dispatch(updatePlatformCredentials(data, user));
    setShowSaveButton(false);
  }, [user, inputValue]);

  useEffect(() => setInputValue(bundle), [bundle]);

  useEffect(
    () => setShowSaveButton(bundle !== inputValue && inputValue !== ''),
    [bundle, inputValue]
  );

  return (
    <div className="col-1-of-2" key={`${platform}--${environment}`}>
      {/* Bundle id */}
      <TextInput
        label={text.en['app-bundle-id']}
        onInputChange={(e: ChangeEvent<HTMLInputElement>) =>
          setInputValue(e.target.value)
        }
        inputValue={inputValue}
        inputName="value"
        inputPlaceholder={text.en['app-bundle-placeholder']}
        requiredField
      />
      {/*  buttons */}
      {showSaveButton && (
        <div className="btn-wrapper">
          <button
            className="sr-btn sr-btn__sm btn-cancel"
            title={text.en.cancel}
            onClick={() => setInputValue(bundle)}
          >
            {text.en.cancel}
          </button>
          <button
            className="sr-btn sr-btn__sm btn-default"
            title={text.en.save}
            onClick={handleUpdateCredentials}
          >
            {text.en.save}
          </button>
        </div>
      )}
    </div>
  );
}
