import React, { Fragment, useEffect } from 'react';
import text from '../../shared/translations/en';
import { useDispatch, useSelector } from 'react-redux';
import { getImageFromURL } from '../../store/actions/admin';
import { Users } from '../Users/Users';
import { selectUsageImage } from '../../store/selectors/usage';
import { USAGE_IMAGES_ENUM } from '../../shared/types/admin';

interface IProps {
  appId: string;
}

export function Overview({ appId }: IProps): JSX.Element {
  const dispatch = useDispatch();

  const usageImage = useSelector(selectUsageImage);

  useEffect(() => {
    dispatch(
      getImageFromURL(`notifications/${appId}`, USAGE_IMAGES_ENUM.PUSH_APP)
    );
    dispatch(getImageFromURL(`logins/${appId}`, USAGE_IMAGES_ENUM.LOGIN_APP));
  }, [appId]);

  return (
    <Fragment>
      <h1>
        <span className="icon-header">
          <i className="icon-16-144-menu-square" />
        </span>
        {text.en.overview}
      </h1>
      <div className="app-detail__row">
        <div className="col-1-of-2 u-margin-top-42">
          <h3>{text.en['app-key']}</h3>
          <input
            type="text"
            value={appId}
            className="input-row__input u-margin-top-18"
            disabled
          />
          <p
            className="body-3 u-margin-top-18"
            dangerouslySetInnerHTML={{ __html: text.en['overview-lead'] }}
          />
        </div>
      </div>
      {(usageImage[USAGE_IMAGES_ENUM.LOGIN_APP] ||
        usageImage[USAGE_IMAGES_ENUM.PUSH_APP]) && (
        <div className="row u-margin-top-42">
          <h3 className="u-margin-bottom-42">{text.en['app-usage-title']}</h3>

          {usageImage[USAGE_IMAGES_ENUM.LOGIN_APP] && (
            <div className="chart-box">
              <div className="chart-box__title">
                {text.en['app-usage-login']}
              </div>
              <div className="chart-box__content">
                <img
                  alt="loginCount"
                  src={usageImage[USAGE_IMAGES_ENUM.LOGIN_APP]}
                  width="100%"
                />
              </div>
            </div>
          )}
          {usageImage[USAGE_IMAGES_ENUM.PUSH_APP] && (
            <div className="chart-box">
              <div className="chart-box__title">{text.en['app-usage-pn']}</div>
              <div className="chart-box__content">
                <img
                  alt="pushCount"
                  src={usageImage[USAGE_IMAGES_ENUM.PUSH_APP]}
                  width="100%"
                />
              </div>
            </div>
          )}
        </div>
      )}
      <div className="row u-margin-top-42">
        <Users />
      </div>
    </Fragment>
  );
}
