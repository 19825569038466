import React, { useCallback, useEffect, useState } from 'react';
import { Filters } from './Filters';
import { Table } from './Table';
import text from '../../shared/translations/en';
import {
  getActivePlatforms,
  getAllEnvironments,
} from '../../utils/DataHelpers';
import { Cell, Column, defaultData } from '../../shared/types/table';
import {
  addPlaceholders,
  addTableKeys,
  createTable,
  fillMissingCells,
  getKeyIndex,
  handleLastRow,
  isDefaultValueColumn,
  isModeOrPlatform,
  updateKeys,
  updateValues,
} from '../../utils/Table';
import _ from 'lodash';
import { Platform, PlatformSettings } from '../../shared/types/application';
import { ConfirmModal } from './ConfirmModal';

interface IProps {
  settings: PlatformSettings[];
  platformSettings: Platform[];
  updateSettings: (settings: PlatformSettings[]) => void;
}

export function SettingsTable({
  settings,
  platformSettings,
  updateSettings,
}: IProps): JSX.Element {
  const platforms = getActivePlatforms(platformSettings);
  const environments = getAllEnvironments(platformSettings);
  const [selectedPlatformItems, setSelectedPlatrofmItems] = useState<string[]>([
    'All',
  ]);
  const [selectedEnvironmentItems, setSelectedEnvironmentItems] = useState<
    string[]
  >(['All']);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [tableData, setTableData] = useState<Column[]>(defaultData);

  const onCellClick = (column: Column, index: number) => {
    const updatedTableData = [...tableData];
    updatedTableData.forEach((platform) => {
      if (platform.id === column.id) {
        platform.values.forEach((value, ix) => {
          if (index === ix) {
            value.activeInput = true;
          }
        });
      }
    });
    setTableData(updatedTableData);
  };

  const onCellInputChange = (
    column: Column,
    cell: Cell,
    index: number,
    value: string
  ) => {
    const updatedTableData = [...tableData];

    updatedTableData.forEach((platform) => {
      if (platform.id === column.id) {
        platform.values.forEach((val, ix) => {
          if (index === ix) {
            // Note when value is changed for the first time we set feedKey
            if (!val.changed && column.id === 'key') {
              val.feedKey = cell.value;
            }
            val.value = value;
            val.changed = true;
            val.platform = column.platform;
            val.modeType = column.modeType;
          }
        });
      }
    });

    // add new row if any of last cell's receive value
    if (column.values.length - 1 === index && value.length) {
      updatedTableData.forEach((platform) => {
        platform.values.push({ value: '' });
      });
    }

    setTableData(updatedTableData);
  };

  const handleClickOutOfActiveCell = () => {
    if (openDialog) {
      return;
    }

    const updatedTableData = [...tableData];
    const keyIndex = 0;
    let showDialog = false;

    updatedTableData.forEach((platform, platformIx) => {
      platform.values.forEach((value, valIx) => {
        const keyWasChanged = updatedTableData[keyIndex].values[valIx].changed;
        const anyKeyValueEmpty =
          platformIx === keyIndex &&
          valIx !== platform.values.length - 1 &&
          value.value === '';
        if (anyKeyValueEmpty && keyWasChanged) {
          showDialog = true;
        } else {
          value.activeInput = false;
        }
      });
    });
    setTableData(updatedTableData);

    if (showDialog) {
      setOpenDialog(true);
    } else {
      saveData();
    }
  };

  const saveData = () => {
    const keyIndex = 0;

    tableData.forEach((column, columnIx) => {
      column.values.forEach((val, ix) => {
        if (val.changed) {
          const isLastRow = column.values.length - 1 === ix;

          // Note if key was updated
          if (column.id === 'key' && !isLastRow) {
            updateKeys(val, updateSettings, settings, tableData, setTableData);
          } else {
            const key = tableData[keyIndex].values[ix].value;
            if (key && key.length) {
              updateValues(column, key, val, updateSettings, settings);
            }
          }
          // Note: set changed value of current changed data
          // back to false otherwise app will try to save it again and again
          const newTableData = JSON.parse(JSON.stringify(tableData));
          newTableData[columnIx].values[ix].changed = false;

          setTableData(newTableData);
        }
      });
    });
  };

  const handleChangeActivePlatformsOrEnvironments = useCallback(
    (selected: string, setter: (item: string[]) => void, current: string[]) => {
      if (selected === 'All') {
        setter(['All']);
      } else {
        if (current.indexOf('All') !== -1) {
          setter([selected]);
          return;
        }

        if (current.indexOf(selected) > -1) {
          setter(current.filter((val) => val !== selected));
          return;
        } else {
          setter(current.concat([selected]));
          return;
        }
      }
    },
    []
  );

  const closeDialog = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      // Note: close only on modal overlay click or in case of forceClose
      if ((event.target as Element).classList.contains('modal-overlay')) {
        setOpenDialog(false);
      }
    },
    []
  );

  const handleTableData = () => {
    const data = createTable(
      tableData,
      selectedPlatformItems,
      selectedEnvironmentItems,
      environments,
      platforms,
      platformSettings
    );

    // Fill table data
    data.forEach((item) => {
      settings?.forEach((setting) => {
        let isPlaceholder = true;

        // Fill keys
        if (item.id === 'key') {
          addTableKeys(item, setting);
          isPlaceholder = false;
        }

        const itemIndex = getKeyIndex(data, setting);

        // Default value
        if (isDefaultValueColumn(item, itemIndex, setting)) {
          item.values[itemIndex] = {
            value: _.get(setting, 'value', undefined), // Note we need undefined to later attach placeholder if there is no value
          };

          isPlaceholder = false;
        }

        // Mode or platform cells
        if (isModeOrPlatform(setting, item, itemIndex)) {
          item.values[itemIndex] = {
            value: setting.value,
          };

          isPlaceholder = false;
        }

        // If there is no real value we will put placeholder with default value if exists otherwise we leave it empty
        addPlaceholders(data, item, itemIndex, isPlaceholder);
      });

      // Always make empty row at the end if it doesn't already exists
      handleLastRow(data, item);
    });

    fillMissingCells(data);
    setTableData(data);
  };

  useEffect(() => {
    handleTableData();
  }, []);

  useEffect(() => {
    handleTableData();
  }, [
    selectedEnvironmentItems,
    selectedPlatformItems,
    settings,
    platformSettings,
  ]);

  return (
    <React.Fragment>
      <Filters
        title={text.en.platforms}
        align={'left'}
        items={platforms}
        selectedItems={selectedPlatformItems}
        onClick={(item) =>
          handleChangeActivePlatformsOrEnvironments(
            item,
            setSelectedPlatrofmItems,
            selectedPlatformItems
          )
        }
      />
      <Filters
        title={text.en.environments}
        align={'right'}
        items={environments}
        selectedItems={selectedEnvironmentItems}
        onClick={(item) =>
          handleChangeActivePlatformsOrEnvironments(
            item,
            setSelectedEnvironmentItems,
            selectedEnvironmentItems
          )
        }
      />
      <Table
        data={tableData}
        onCellClick={onCellClick}
        onCellInputChange={onCellInputChange}
        handleClickOutOfActiveCell={handleClickOutOfActiveCell}
      />
      {openDialog && (
        <div className={'settings-dialog-modal-overlay'} onClick={closeDialog}>
          <ConfirmModal saveData={saveData} setOpenDialog={setOpenDialog} />
        </div>
      )}
    </React.Fragment>
  );
}
