export interface Column {
  id: string;
  text: string;
  values: Cell[];
  platform?: string;
  modeType?: string;
}

export interface Cell {
  placeholder?: string;
  value?: string;
  activeInput?: boolean;
  changed?: boolean;
  feedKey?: string;
  platform?: string;
  modeType?: string;
}

export const defaultData: Column[] = [
  {
    id: 'key',
    text: 'KEY',
    values: [],
  },
  {
    id: 'default',
    text: 'Default value',
    values: [],
  },
];
