import { Auth, Hub } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { HubCapsule, HubPayload } from '@aws-amplify/core';
import {
  fetchUserSuccess,
  fetchUserFail,
  resetUser,
} from '../store/actions/user';

const useAmplifyAuth = () => {
  const [triggerFetch, setTriggerFetch] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    let isMounted = true;

    const fetchUserData = async () => {
      if (isMounted) {
        try {
          const data = await Auth.currentAuthenticatedUser();
          if (data) {
            dispatch(fetchUserSuccess(data));
          }
        } catch (error) {
          dispatch(fetchUserFail());
        }
      }
    };

    const HubListener = () => {
      Hub.listen('auth', (data: HubCapsule) => {
        const { payload } = data;
        onAuthEvent(payload);
      });
    };

    const onAuthEvent = (payload: HubPayload) => {
      if (payload.event === 'signIn') {
        if (isMounted) {
          setTriggerFetch(true);
          console.log('signed in');
        }
      } else {
        return;
      }
    };

    HubListener();
    fetchUserData().catch((error) => {
      console.log('Error fetching user data ', error);
    });

    return () => {
      Hub.remove('auth', () => {
        //do nothing
      });
      isMounted = false;
    };
  }, [dispatch, triggerFetch]);

  const handleSignout = async () => {
    try {
      console.log('signed out');
      await Auth.signOut();
      dispatch(resetUser());
      setTriggerFetch(false);
      location.assign('/login');
    } catch (error) {
      console.error('Error signing out user ', error);
    }
  };

  return { handleSignout };
};

export default useAmplifyAuth;
