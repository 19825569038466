import axios from 'axios';
import * as actionTypes from './actionTypes';
import { getApnTokens } from './apnTokens';
import { hideModal, modalError, modalUploadSucces } from './modals';
import { getToken } from '../../shared/utility';
import { asyncActionStart, asyncActionFinish, asyncActionError } from './async';
import { toastr } from 'react-redux-toastr';
import { text } from '../../shared/translations/en-v1';
import {
  ApplicationJsonCharsetUTF8,
  ContentTypeMultiFormData,
} from '../../utils/Enums';
import { API } from 'aws-amplify';
import { PlatformEnum } from '../../shared/types/application';
import { acceptInviteSuccess } from './user';

const modalErrorObj = {
  field: null,
  message: null,
};

export const fetchAppsStart = () => {
  return { type: actionTypes.FETCH_APPLICATIONS_START };
};

export const fetchAppsSuccess = (applications) => {
  return {
    type: actionTypes.FETCH_APPLICATIONS_SUCCESS,
    applications,
  };
};

export const fetchAppsFail = () => {
  return { type: actionTypes.FETCH_APPLICATIONS_FAIL };
};

export const fetchAppStart = () => {
  return { type: actionTypes.FETCH_APP_START };
};

export const fetchAppSuccess = (application, user) => {
  return {
    type: actionTypes.FETCH_APP_SUCCESS,
    payload: { application, user },
  };
};

export const fetchAppFail = (errorCode) => {
  return {
    type: actionTypes.FETCH_APP_FAIL,
    errorCode,
  };
};

export const fetchApplications = (
  type = 'my',
  query = { size: 23, page: 0 }
) => {
  return (dispatch) => {
    dispatch(asyncActionStart());
    dispatch(fetchAppStart());
    const endpoint = type !== 'my' ? `/applications/${type}` : `/applications`;
    API.get('mdp', endpoint, {
      queryStringParameters: query,
    })
      .then((response) => {
        dispatch(fetchAppsSuccess(response));
        dispatch(asyncActionFinish());
      })
      .catch((error) => {
        dispatch(fetchAppsFail());
        dispatch(asyncActionError());
      });
  };
};

export const fetchApp = (id, user) => {
  return (dispatch, getState) => {
    if (!id) {
      return;
    }
    dispatch(fetchAppStart());
    API.get('mdp', `/applications/${id}`, {})
      .then((response) => {
        dispatch(fetchAppSuccess(response, user ?? getState().profile.user));
        dispatch(getApnTokens(id));
      })
      .catch((error) => {
        if (error?.response) {
          if (error.response.status === 403) {
            redirect('/not-allowed');
          }
          if (error.response.status === 400) {
            redirect('/api/applications/my');
          }
          dispatch(fetchAppFail(error.response.status));
        }
      });
  };
};

export const uploadStart = () => {
  return { type: actionTypes.UPLOAD_START };
};

export const uploadSuccess = () => {
  return { type: actionTypes.UPLOAD_SUCCESS };
};

export const uploadFail = () => {
  return { type: actionTypes.UPLOAD_FAIL };
};

export const uploadApnsCertificateFail = (errorObj) => {
  return {
    type: actionTypes.UPLOAD_APNS_CERTIFICATE_FAIL,
    errorObj,
  };
};

export const uploadIconFail = () => {
  return { type: actionTypes.UPLOAD_ICON_FAIL };
};

export const uploadIconSuccess = () => {
  return { type: actionTypes.UPLOAD_ICON_SUCCESS };
};

export const uploadIcon = (icon) => {
  const fd = new FormData();
  fd.append('file', icon);
  return async (dispatch, getState) => {
    try {
      await API.post(
        'mdp',
        `/applications/${getState().apps.singleApp.appId}/icon`,
        { body: fd }
      );
      toastr.success(text.success, text.iconUploaded);
      dispatch(uploadIconSuccess());
    } catch (error) {
      const errorText = error.response.data?.message;
      toastr.error(text.error, errorText || text.genericError);
      dispatch(uploadIconFail());
    }
  };
};

export const updateFirebaseCredentials = (firebaseSettings) => {
  return (dispatch, getState) => {
    dispatch(uploadStart());
    API.put(
      'mdp',
      `/applications/${
        getState().apps.singleApp.id ?? getState().apps.singleApp.appId
      }/firebase`,
      { body: firebaseSettings }
    )
      .then(() => {
        toastr.success(text.success, text.platformUpdated);
        dispatch(fetchApp(getState().apps.singleApp.id));
      })
      .catch((error) => {
        let errorMsg = error.response.data.message;
        dispatch(uploadFail());
        if (error.response.data.errors?.length > 0) {
          error.response.data.errors.forEach((err) => {
            errorMsg = `${err.field} ${err.message}`;
          });
        }
        toastr.error(text.error, errorMsg || text.genericError);
      });
  };
};

export const inviteUser = (user) => {
  const data = [];
  data.push(user);
  return (dispatch, getState) => {
    dispatch(uploadStart());
    API.put('mdp', `/applications/${getState().apps.singleApp.appId}/invite`, {
      body: data,
    })
      .then((response) => {
        toastr.success(text.success, text.inviteSent);
        dispatch(
          fetchApp(
            getState().apps.singleApp.id ?? getState().apps.singleApp.appId,
            undefined
          )
        );
      })
      .catch((error) => {
        toastr.error(
          text.error,
          error.response.data.message || text.genericError
        );
      });
  };
};

export const acceptInvite = (appId) => {
  return (dispatch) => {
    API.put('mdp', `/applications/${appId}/invitation`, null)
      .then((response) => {
        dispatch(fetchApplications());
        dispatch(acceptInviteSuccess());
        toastr.success(text.success, text.acceptSuccess);
      })
      .catch((error) => {
        dispatch(uploadFail());
        location.assign('/login');
        toastr.error(error.message);
      });
  };
};

export const rejectInvite = (appId, email) => {
  return (dispatch) => {
    API.del('mdp', `/applications/${appId}/invite?email=${email}`, {})
      .then((response) => {
        dispatch(fetchApplications());
        toastr.success(text.success, text.rejectSuccess);
      })
      .catch((error) => {
        dispatch(uploadFail());
        toastr.error(error.response.data.message);
      });
  };
};

export const uploadApnsCertificate = (obj) => {
  const formData = new FormData();
  const { file, mode, platform } = obj;
  formData.append('file', file);
  formData.append('mode', mode);
  formData.append('platform', platform);
  if (obj.password) {
    formData.append('password', obj.password);
  }
  if (obj.platform === PlatformEnum.SAFARI) {
    formData.append('extra', obj.extra);
  }

  return (dispatch, getState) => {
    dispatch(uploadStart());
    API.post(
      'mdp',
      `/applications/${
        getState().apps.singleApp.id ?? getState().apps.singleApp.appId
      }/aps_certificate`,
      {
        headers: {
          'Content-Type': ContentTypeMultiFormData,
        },
        body: formData,
      }
    )
      .then((response) => {
        toastr.success(text.success, text.platformUpdated);
        dispatch(uploadSuccess());
        dispatch(
          fetchApp(
            getState().apps.singleApp.id ?? getState().apps.singleApp.appId,
            undefined
          )
        );
        dispatch(hideModal());
        dispatch(modalUploadSucces());
      })
      .catch((error) => {
        let message, field;
        if (
          error.response.data.errors &&
          error.response.data.errors.length > 0
        ) {
          field = error.response.data.errors[0].field;
          message = error.response.data.errors[0].message;
          modalErrorObj.field = field;
          modalErrorObj.message = message;
        } else {
          message = error.response.data.message;
          modalErrorObj.field = 'extra';
          modalErrorObj.message = message;
        }
        const dataObject = {
          platform: platform,
          errorMessage: message,
          mode: mode,
          field,
        };
        dispatch(uploadApnsCertificateFail(dataObject));
        dispatch(uploadFail());
        dispatch(modalError(modalErrorObj));
      });
  };
};

export const deleteCertificate = (appId, platform, environment) => {
  return (dispatch, getState) => {
    dispatch(uploadStart());
    API.del(
      'mdp',
      `/applications/${appId}/aps_certificate/${platform}/${environment}`,
      {}
    )
      .then((response) => {
        dispatch(uploadSuccess());
        toastr.success(text.success, text.certificateDeleted);
        dispatch(fetchApp(appId, getState().profile.user));
      })
      .catch((error) => {
        dispatch(uploadFail());
        toastr.error(text.error, text.genericError);
      });
  };
};

const handleError = (error) => {
  const errorMsg = error.response.data.message || text.genericError;
  toastr.error(text.error, errorMsg);

  return (dispatch) => {
    dispatch(uploadFail());
  };
};

export const changeRole = (appId, users) => {
  return (dispatch) => {
    dispatch(uploadStart());
    API.put('mdp', `/applications/${appId}/change_role`, { body: users }, {})
      .then((response) => {
        dispatch(uploadSuccess());
        toastr.success(text.success, text.roleChanged);
        dispatch(fetchApp(appId));
      })
      .catch((error) => {
        toastr.error(error.message);
        handleError(error);
      });
  };
};

export const cancelError = () => {
  return { type: actionTypes.CANCEL_ERROR };
};

export const deleteAppUser = (appId, userEmail) => {
  return (dispatch) => {
    dispatch(uploadStart());
    API.del('mdp', `/applications/${appId}/user/${userEmail}`, {})
      .then((response) => {
        dispatch(uploadSuccess());
        toastr.success(text.success, text.userDeleted);
        dispatch(fetchApp(appId));
      })
      .catch((error) => {
        const errorMsg = error.response.data.detail || text.genericError;
        dispatch(uploadFail());
        toastr.error(text.error, errorMsg);
      });
  };
};

export const createApplication = (app) => {
  return (dispatch) => {
    dispatch(uploadStart());
    API.post('mdp', '/applications', { body: app })
      .then((response) => {
        dispatch(uploadSuccess());
        dispatch(fetchApplications());
        toastr.success(text.success, text.appCreated);
      })
      .catch((error) => {
        handleError(error);
      });
  };
};

export const updateSettingsSuccess = (settings) => {
  return {
    type: actionTypes.UPDATE_SETTINGS_SUCCESS,
    payload: settings,
  };
};

export const updateSettings = (settings) => {
  return (dispatch, getState) => {
    dispatch(uploadStart());
    API.put(
      'mdp',
      `/applications/${
        getState().apps.singleApp.appId ?? getState().apps.singleApp.id
      }/settings`,
      {
        body: settings,
      }
    )
      .then((response) => {
        dispatch(uploadSuccess());
        dispatch(updateSettingsSuccess(settings));
        toastr.success(text.success, text.genericSuccess);
      })
      .catch((error) => {
        toastr.error(text.error, text.genericError);
      });
  };
};

export const updateAdminSettingsSuccess = (settings) => {
  return {
    type: actionTypes.UPDATE_ADMIN_SETTINGS_SUCCESS,
    payload: { settings },
  };
};

export const updateAdminSettings = (settings) => {
  return (dispatch, getState) => {
    API.put(
      'mdp',
      `/applications/${
        getState().apps.singleApp.appId ?? getState().apps.singleApp.id
      }/settings/admin`,
      { body: { settings } }
    )
      .then((response) => {
        //dispatch( fetchApp(getState().apps.singleApp.id))
        dispatch(updateAdminSettingsSuccess(settings));
        toastr.success(text.success, text.genericSuccess);
      })
      .catch((error) => {
        const errorMsg = error.response.data.message || text.genericError;
        toastr.error(text.error, errorMsg);
      });
  };
};

export const udpatePushNotificationDelay = (value) => {
  return (dispatch, getState) => {
    dispatch(uploadStart());
    API.put(
      'mdp',
      `/applications/${
        getState().apps.singleApp.id ?? getState().apps.singleApp.appId
      }/push_delay`,
      { body: { delaySeconds: value } }
    )
      .then((response) => {
        dispatch(uploadSuccess());
        dispatch(fetchApp(getState().apps.singleApp.id));
        toastr.success(text.success, text.genericSuccess);
      })
      .catch((error) => {
        dispatch(uploadFail());
        toastr.error(text.error, text.genericError);
      });
  };
};

export const updateAppName = (appName, user) => {
  return (dispatch, getState) => {
    API.put('mdp', `/applications/${getState().apps.singleApp.appId}/name`, {
      body: appName,
    })
      .then((response) => {
        dispatch(fetchApp(getState().apps.singleApp.appId, user));
        toastr.success(text.success, text.genericSuccess);
      })
      .catch((error) => {
        toastr.error(text.error, text.genericError);
      });
  };
};

// Plaftorms / Credentials
export const addPlatforms = (platforms, user) => {
  return (dispatch, getState) => {
    API.post(
      'mdp',
      `/applications/${
        getState().apps.singleApp.appId ?? getState().apps.singleApp.id
      }/credentials`,
      { body: platforms }
    )
      .then((response) => {
        toastr.success(text.success, text.platformCreated);
        dispatch(
          fetchApp(
            getState().apps.singleApp.id ?? getState().apps.singleApp.appId,
            getState().profile.user
          )
        );
      })
      .catch((error) => {
        const errorMsg = error.response.data.message || text.genericError;
        toastr.error(text.error, errorMsg);
      });
  };
};

export const deletePlatform = (platform, environment) => {
  return (dispatch, getState) => {
    API.del(
      'mdp',
      `/applications/${
        getState().apps.singleApp.id ?? getState().apps.singleApp.appId
      }/credentials/${platform}/${environment}`
    )
      .then((response) => {
        toastr.success(text.success, text.platformDeleted);
        dispatch(
          fetchApp(
            getState().apps.singleApp.id ?? getState().apps.singleApp.appId,
            getState().profile.user
          )
        );
      })
      .catch((error) => {
        const errorMsg = error.response.data.message || text.genericError;
        toastr.error(text.error, errorMsg);
      });
  };
};

export const updatePlatformCredentials = (credentials, user) => {
  return (dispatch, getState) => {
    dispatch(uploadStart());
    API.put(
      'mdp',
      `/applications/${
        getState().apps.singleApp.id ?? getState().apps.singleApp.appId
      }/credentials`,
      {
        body: credentials,
      }
    )
      .then((response) => {
        dispatch(uploadSuccess());
        toastr.success(text.success, text.genericSuccess);
        dispatch(
          fetchApp(
            getState().apps.singleApp.id ?? getState().apps.singleApp.appId,
            getState().profile.user
          )
        );
      })
      .catch((error) => {
        let errorMsg = error.response.data.message;
        // conflict error
        if (error.response.status === 409) {
          errorMsg = text.en['error-platform-same-values'];
        }
        dispatch(uploadFail());
        toastr.error(text.error, errorMsg);
      });
  };
};

export const updatePushSubcriptions = (pushSettings) => {
  return (dispatch, getState) => {
    dispatch(uploadStart());
    axios
      .put(
        `/api/applications/${getState().apps.singleApp.id}/push-subscriptions`,
        pushSettings,
        {
          headers: { authorization: `Bearer ${getState().auth.token}` },
        }
      )
      .then((response) => {
        toastr.success(text.success, text.genericSuccess);
        dispatch(uploadSuccess());
        dispatch(fetchApp(getState().apps.singleApp.id));
      })
      .catch((error) => {
        let errorMsg = error.response.data.message;
        dispatch(uploadFail());
        if (
          error.response.data.errors &&
          error.response.data.errors.length > 0
        ) {
          error.response.data.errors.forEach((err) => {
            errorMsg = `${err.field} ${err.message}`;
          });
        }
        toastr.error(text.error, errorMsg);
      });
  };
};

// Api access key
export const fetchApiAccessKeyStart = () => {
  return { type: actionTypes.FETCH_API_ACCESS_KEY_START };
};

export const fetchApiAccessKeySuccess = (accessKey) => {
  return { type: actionTypes.FETCH_API_ACCESS_KEY_SUCCESS, accessKey };
};

export const fetchApiAccessKeyFail = () => {
  return { type: actionTypes.FETCH_API_ACCESS_KEY_FAIL };
};

export const createApiAccessKey = () => {
  return (dispatch, getState) => {
    dispatch(fetchApiAccessKeyStart());
    axios
      .put(
        `/api/applications/${getState().apps.singleApp.id}/app_access_key`,
        null,
        {
          headers: { Authorization: `Bearer ${getToken()}` },
        }
      )
      .then((response) => {
        toastr.success(text.success, text.accesKeyCreated);
        dispatch(fetchApiAccessKeySuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchApiAccessKeyFail());
        toastr.error(text.error, text.genericError);
      });
  };
};

// Notification history report for 48 hours
export const getNotificationHistoryReport = (deviceId) => {
  return (dispatch, getState) => {
    dispatch(uploadStart());
    API.get(
      'mdp',
      `/test_device/${
        getState().apps.singleApp.appId
      }/${deviceId}/notificationHistory`
    )
      .then((response) => {
        dispatch({
          type: actionTypes.FETCH_NOTIFICATION_HISTORY_REPORT,
          payload: response,
        });
        dispatch(uploadSuccess());
      })
      .catch((error) => {
        dispatch(uploadFail());
      });
  };
};

// application reporting
export const getApplicationReport = (formValues) => {
  return (dispatch, getState) => {
    dispatch(uploadStart());
    axios({
      method: 'GET',
      url: `/api/applications/${getState().apps.singleApp.id}/simplereport`,
      params: { ...formValues },
      headers: { Authorization: `Bearer ${getState().auth.token}` },
    })
      .then((response) => {
        dispatch({
          type: actionTypes.FETCH_SIMPLE_REPORT,
          payload: response.data,
        });
        dispatch(uploadSuccess());
      })
      .catch((error) => {
        dispatch(uploadFail());
      });
  };
};

// Api access key
export const changeUserRoleStart = () => {
  return { type: actionTypes.CHANGE_USER_ROLE_START };
};

export const changeUserRoleSuccess = (userEmail, admin) => {
  return {
    type: actionTypes.CHANGE_USER_ROLE_SUCCESS,
    payload: { userEmail, admin },
  };
};

export const changeUserRoleFail = () => {
  return { type: actionTypes.CHANGE_USER_ROLE_FAIL };
};

export const changeUserRole = (userEmail, isAdmin) => {
  return (dispatch) => {
    dispatch(changeUserRoleStart());
    API.put('mdp', `/user/admin`, { body: { userEmail, isAdmin } })
      .then(() => {
        dispatch(changeUserRoleSuccess(userEmail, isAdmin));
        toastr.success(text.success, text.roleChanged);
      })
      .catch((error) => {
        const errorMsg =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          text.genericError;
        dispatch(changeUserRoleFail());
        toastr.error(text.error, errorMsg);
      });
  };
};

// Activate user
export const changeUserActivationStart = () => {
  return { type: actionTypes.CHANGE_USER_ACTIVATION_START };
};

export const changeUserActivationSuccess = (userId) => {
  return {
    type: actionTypes.CHANGE_USER_ACTIVATION_SUCCESS,
    payload: { userId },
  };
};

export const changeUserActivationFail = () => {
  return { type: actionTypes.CHANGE_USER_ACTIVATION_FAIL };
};

export const changeUserActivation = (userId) => {
  return (dispatch) => {
    dispatch(changeUserActivationStart());
    axios
      .post(
        `/api/user/${userId}/activate`,
        { isActivated: true },
        {
          headers: {
            'Content-Type': ApplicationJsonCharsetUTF8,
            authorization: `Bearer ${getToken()}`,
          },
        }
      )
      .then((response) => {
        dispatch(changeUserActivationSuccess(userId));
        toastr.success(text.success, text.roleChanged);
      })
      .catch((error) => {
        const errorMsg =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          text.genericError;
        dispatch(changeUserActivationFail());
        toastr.error(text.error, errorMsg);
      });
  };
};

export const changeUserLockStart = () => {
  return { type: actionTypes.CHANGE_USER_LOCK_START };
};

export const changeUserLockSuccess = (userEmail, isLocked) => {
  return {
    type: actionTypes.CHANGE_USER_LOCK_SUCCESS,
    payload: { userEmail, isLocked },
  };
};

export const changeUserLockFail = () => {
  return { type: actionTypes.CHANGE_USER_LOCK_FAIL };
};

export const changeUserLock = (user) => {
  return (dispatch) => {
    dispatch(changeUserLockStart());
    API.put('mdp', `/user/change_lock`, {
      body: { userEmail: user.userEmail, locked: !user.isLocked },
    })
      .then((response) => {
        dispatch(changeUserLockSuccess(user.userEmail, !user.isLocked));
        toastr.success(text.success, text.lockChanged);
      })
      .catch((error) => {
        const errorMsg =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          text.genericError;
        dispatch(changeUserLockFail());
        toastr.error(text.error, errorMsg);
      });
  };
};

export const removeInvitation = (email, id) => {
  return (dispatch) => {
    if (!id) {
      return;
    }
    API.del('mdp', `/applications/${id}/invite?email=${email}`, {})
      .then((response) => {
        dispatch(removeUserInvitation(email));
      })
      .catch((error) => {
        toastr.error(error.message ?? error);
      });
  };
};

const removeUserInvitation = (email) => {
  return {
    type: actionTypes.REMOVE_INVITATION,
    payload: { email },
  };
};
