import React, { Fragment } from 'react';
import text from '../../../shared/translations/en';
import { IDialog } from '../../../shared/types/device';

interface IProps {
  dialog: IDialog;
  displayName: string;
  setDisplayName: (name: string) => void;
}

export function DialogContent({
  dialog,
  displayName,
  setDisplayName,
}: IProps): JSX.Element {
  if (dialog.edit) {
    return (
      <React.Fragment>
        <div className={'mdp-modal-input-container'}>
          <label className="mdp-modal-label" htmlFor="modal-input-device-name">
            {text.en['device-name']}
          </label>
          <input
            className={'mdp-modal-input'}
            type="text"
            name="modal-input-device-name"
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
          />
        </div>
        <div className={'mdp-modal-input-container'}>
          <label className="mdp-modal-label" htmlFor="modal-input-device-id">
            {text.en['device-id']}
          </label>
          <input
            className={'mdp-modal-input mdp-modal-input-device srm-disabled'}
            type="text"
            name="modal-input-device-id"
            value={dialog.device.id ? dialog.device.id : ''}
            disabled={true}
          />
        </div>
      </React.Fragment>
    );
  } else if (dialog.remove) {
    return <Fragment>{text.en['are-you-sure-remove-device']}</Fragment>;
  }
  return <Fragment>{text.en['are-you-sure-confirm-device']}</Fragment>;
}
