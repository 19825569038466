import React, { useCallback, useState } from 'react';
import { Footer } from '../../../components/Footer/Footer';
import text from '../../../shared/translations/en';
import { Navigate } from 'react-router-dom';
import srLogo from '../../../assets/images/SR_logo_basic.png';
import {
  PdsEyePrivacyViewIcon,
  PdsEyePublicViewIcon,
} from '@podium-design-system/react-icons';
import { Auth } from 'aws-amplify';
import { useSelector } from 'react-redux';
import { selectTemporaryUser } from 'store/selectors/user';
import { toastr } from 'react-redux-toastr';

export const SetPassword: React.FC = () => {
  const [passwordShown, setPasswordShown] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');
  const [repeatPassword, setRepeatPassword] = useState<string>('');
  const [redirect, setRedirect] = useState<string>();

  const user = useSelector(selectTemporaryUser);

  const togglePassword = useCallback(() => {
    setPasswordShown(!passwordShown);
  }, [passwordShown]);

  const handleSetPassword = useCallback(async () => {
    try {
      if (password === '') {
        toastr.error('Passwords should not be empty!');
      } else if (password !== repeatPassword) {
        toastr.error('Passwords must match!');
      } else {
        await Auth.completeNewPassword(user, password);

        setRedirect('/login');
      }
    } catch (e: unknown) {
      toastr.error((e as Error).message);
    }
  }, [user, password, repeatPassword]);

  if (redirect) {
    return <Navigate replace to={redirect} />;
  }

  return (
    <main className="layout__main sr-forms">
      <div className="sr-forms-content">
        <div
          data-test="login-component"
          className="login-form sr-forms-component"
        >
          <div className="sr-forms-title">
            <span className="sr-logo">
              <img src={srLogo} alt="Sportradar" />
            </span>
            <span className="sr-forms-mdp-brand">MDP</span>
          </div>
          <div className="sr-forms-form">
            <h3>{text.en['set-password']}</h3>
            <div className="u-margin-top-28">
              <div className={'sr-forms-input-container'}>
                <label className="u-margin-top-8">{text.en.password}*</label>
                <input
                  type={passwordShown ? 'text' : 'password'}
                  className={`input ${password.length ? '' : 'srm-empty'}`}
                  data-test="input-password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder={text.en['password-placeholder']}
                />
                <div className="toggle-password" onClick={togglePassword}>
                  {passwordShown ? (
                    <PdsEyePrivacyViewIcon />
                  ) : (
                    <PdsEyePublicViewIcon />
                  )}
                </div>
              </div>
            </div>
            <div className="u-margin-top-28">
              <div className={'sr-forms-input-container'}>
                <label className="u-margin-top-8">
                  {text.en['repeat-password']}*
                </label>
                <input
                  type={passwordShown ? 'text' : 'password'}
                  className={`input ${
                    repeatPassword.length ? '' : 'srm-empty'
                  }`}
                  data-test="input-password"
                  value={repeatPassword}
                  onChange={(e) => setRepeatPassword(e.target.value)}
                  placeholder={text.en['password-placeholder']}
                />
                <div className="toggle-password" onClick={togglePassword}>
                  {passwordShown ? (
                    <PdsEyePrivacyViewIcon />
                  ) : (
                    <PdsEyePublicViewIcon />
                  )}
                </div>
              </div>
            </div>
            <div className="u-right-text u-margin-top-32">
              <button
                className="sr-btn sr-btn__lg u-btn-right btn-dark-blue"
                data-test="login-button"
                onClick={handleSetPassword}
              >
                {text.en['set-password']}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="layout__footer">
        <Footer />
      </div>
    </main>
  );
};
