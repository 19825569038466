import React, { useCallback, useEffect, useState } from 'react';
import text from '../../shared/translations/en';
import Modal from '../../components/Modal/Modal';
import { DeletePushSettingModalActions } from './DeletePushSettingModalActions';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../store/actions/index';
import { selectAllPushModifiers } from '../../store/selectors/pushModifiers';
import { PushModifiers } from './PushModifiers';
import {
  ConfigOne,
  ConfigTwo,
  PushSetting,
} from '../../shared/types/pushSettings';
import { ConfigDetailForm } from './ConfigDetailForm/ConfigDetailForm';
import { formConfig } from './config';
import { ModifierExpansionPanel } from './ModifierExpansionPanel/ModifierExpansionPanel';

export function PushSettings() {
  const dispatch = useDispatch();

  const [selectedModifier, setSelectedModifier] = useState<PushSetting>();
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>();
  const [modifierName, setModifierName] = useState<string>();

  const pushModifiers = useSelector(selectAllPushModifiers);

  const handleFormSubmit = useCallback(
    (configuration: ConfigOne | ConfigTwo) => {
      const data = {
        className: selectedModifier.className,
        configurationJson: configuration,
      };

      dispatch(actions.addAppPushModifier(data));
    },
    [selectedModifier]
  );

  const handleOpenDeleteDialog = useCallback((name: string) => {
    setOpenDeleteDialog(true);
    setModifierName(name);
  }, []);

  useEffect(() => {
    dispatch(actions.getAllPushModifiers());
  }, []);

  return (
    <section className={'u-margin-top-20'}>
      <div className="row">
        <h3 className="u-margin-top-42">{text.en['push-modifiers']}</h3>
        <div className="col-1-of-2 u-margin-top-18">
          <p className="body-3">{text.en['push-settings-lead']}</p>
          {pushModifiers && (
            <PushModifiers
              pushModifiers={pushModifiers}
              selectedModifier={selectedModifier}
              setSelectedModifier={setSelectedModifier}
            />
          )}
          {selectedModifier && Object.keys(selectedModifier).length > 0 ? (
            <p className="body-3 u-margin-top-18">
              {text.en['comma-separated']}
            </p>
          ) : null}
        </div>
        <div className="col-1-of-2">
          {selectedModifier && Object.keys(selectedModifier).length > 0 && (
            <ConfigDetailForm
              formType={formConfig[selectedModifier.className].formView}
              onFormSubmit={handleFormSubmit}
            />
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-1-of-2">
          <h5>{text.en['app-modifiers']}</h5>
          <ModifierExpansionPanel onDeleteBtnClick={handleOpenDeleteDialog} />
        </div>
        <div className="col-1-of-2"></div>
        {openDeleteDialog && (
          <Modal
            title={`Warning`}
            contentText={`Are you sure you want to delete ${modifierName}?`}
            actions={() => (
              <DeletePushSettingModalActions
                modifierName={modifierName}
                setOpenDeleteDialog={setOpenDeleteDialog}
              />
            )}
            onDismiss={() => this.closeDialog()}
          />
        )}
      </div>
    </section>
  );
}
