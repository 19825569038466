import React, { useCallback, useState } from 'react';
import text from '../../../shared/translations/en';
import srLogo from '../../../assets/images/SR_logo_basic.png';
import {
  PdsEyePrivacyViewIcon,
  PdsEyePublicViewIcon,
} from '@podium-design-system/react-icons';
import { Auth } from 'aws-amplify';
import { setConfig } from '../../../shared/utility';
import { useSelector } from 'react-redux';
import { selectConfig } from '../../../store/selectors/config';
import { toastr } from 'react-redux-toastr';
import { useForm } from 'react-hook-form';

export function RegisterForm(): JSX.Element {
  const [passwordShown, setPasswordShown] = useState<boolean>(false);
  const [repeatPasswordShown, setRepeatPasswordShown] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { handleSubmit, register, watch, formState } = useForm({
    mode: 'onChange',
  });
  const { errors, isValid } = formState;

  const config = useSelector(selectConfig);

  const signUp = useCallback((data) => {
    const { email, password } = data;

    setConfig('customer', config);
    setLoading(true);
    Auth.signUp({
      username: email,
      password,
    })
      .then(() => Auth.signIn(email, password))
      .catch((error) =>
        toastr.error(error.message.replace('PreSignUp failed with error ', ''))
      )
      .finally(() => setLoading(false));
  }, []);

  return (
    <form onSubmit={handleSubmit(signUp)} className="sr-forms-content">
      <div
        data-test="login-component"
        className="login-form sr-forms-component"
      >
        <div className="sr-forms-title">
          <span className="sr-logo">
            <img src={srLogo} alt="Sportradar" />
          </span>
          <span className="sr-forms-mdp-brand">MDP</span>
        </div>
        <div className="sr-forms-form">
          <div className="sr-forms-input-container">
            <h3>{text.en.register}</h3>
            <label>{text.en.email}*</label>
            <input
              type="email"
              className="input"
              data-test="input-email"
              {...register('email', { required: true })}
            />
          </div>
          <div className="u-margin-top-28">
            <div className="sr-forms-input-container">
              <label className="u-margin-top-8">{text.en.password}*</label>
              <input
                type={passwordShown ? 'text' : 'password'}
                className="input"
                data-test="input-password"
                {...register('password', { required: true })}
              />
              <div
                className="toggle-password"
                onClick={() => setPasswordShown(!passwordShown)}
              >
                {passwordShown ? (
                  <PdsEyePrivacyViewIcon />
                ) : (
                  <PdsEyePublicViewIcon />
                )}
              </div>
            </div>
          </div>
          <div className="u-margin-top-28">
            <div className="sr-forms-input-container">
              <label className="u-margin-top-8">
                {text.en['repeat-password']}*
              </label>
              <input
                type={repeatPasswordShown ? 'text' : 'password'}
                className="input"
                data-test="input-password"
                {...register('repeatPassword', {
                  required: true,
                  validate: (val: string) => {
                    if (watch('password') !== val) {
                      return 'Your passwords do no match';
                    } else {
                      return null;
                    }
                  },
                })}
              />
              <div
                className="toggle-password"
                onClick={() => setRepeatPasswordShown(!repeatPasswordShown)}
              >
                {repeatPasswordShown ? (
                  <PdsEyePrivacyViewIcon />
                ) : (
                  <PdsEyePublicViewIcon />
                )}
              </div>
            </div>
          </div>
          {errors.repeatPassword && (
            <small>{errors.repeatPassword.message as string}</small>
          )}
          <div className="u-right-text u-margin-top-32">
            <button
              className="sr-btn sr-btn__lg u-btn-right btn-dark-blue"
              data-test="login-button"
              disabled={loading || !isValid}
              onClick={() => handleSubmit(signUp)}
            >
              {text.en.register}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}
