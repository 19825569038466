import { RootState } from '../../shared/types/store';

export const selectAvailableDevices = (state: RootState) =>
  state.devices.availableDevices;

export const selectRegisteredDevices = (state: RootState) =>
  state.devices.registeredDevices;

export const selectActivePlatforms = (state: RootState) =>
  state.devices.selectedPlatforms;

export const selectActiveEnvironments = (state: RootState) =>
  state.devices.selectedEnvironments;

export const selectQrCode = (state: RootState) => state.devices.qrCode;

export const selectIsSending = (state: RootState) => state.devices.sending;

export const selectError = (state: RootState) => state.devices.error;

export const selectIsPushNotificationSent = (state: RootState) =>
  state.devices.pushNotificationSent;
