import React, { ChangeEvent, useEffect, useState } from 'react';
import { availableEventTypes } from '../../../shared/utility';
import { grey } from '@mui/material/colors';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { EventType } from '../../../shared/types/pushSettings';

const styles = {
  root: {
    color: grey[600],
    '&$checked': {
      color: grey[500],
    },
  },
  checked: {},
  controlLabel: {
    width: '40%',
  },
  label: {
    fontSize: '1.2rem',
  },
};

interface IProps {
  onCheckboxClick: (eventType: EventType) => void;
  checkedTypes: string[];
}

export function CheckboxLabels({ onCheckboxClick, checkedTypes }: IProps) {
  const [objFromArray, setObjFromArray] = useState({});

  const handleChange =
    (name: string) => (event: ChangeEvent<HTMLInputElement>) => {
      const obj: EventType = { name, status: event.target.checked.toString() };
      onCheckboxClick(obj);
    };

  useEffect(() => {
    setObjFromArray(
      availableEventTypes.reduce((acc: {}, curr: string) => {
        acc[curr] = false;
        return acc;
      }, {})
    );
  }, []);

  const renderCheckboxes = () => {
    return availableEventTypes.map((type: string) => {
      let isChecked = false;
      checkedTypes.forEach((element) => {
        if (element === type) {
          isChecked = true;
        }
      });
      return (
        <FormControlLabel
          style={styles.controlLabel}
          key={type}
          control={
            <Checkbox
              checked={isChecked}
              onChange={handleChange(type)}
              value={`${objFromArray[type]}`}
            />
          }
          label={type}
        />
      );
    });
  };

  if (!objFromArray) {
    return <></>;
  }

  return <FormGroup row>{renderCheckboxes()}</FormGroup>;
}
