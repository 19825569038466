import React from 'react';
import text from '../../../shared/translations/en';
import { Link } from 'react-router-dom';
import { Logo } from '../../../components/Logo/Logo';
import { useSelector } from 'react-redux';
import { selectConfig } from '../../../store/selectors/config';
import { setConfig } from '../../../shared/utility';

export function Login(): JSX.Element {
  const config = useSelector(selectConfig);

  const signIn = async () => {
    try {
      if (config) {
        setConfig('sportradar', config);

        // Build custom login URL to avoid showing middle screen with Sign in with Sportradar ID
        window.open(
          `https://${config.Auth.sportradar.oauth.domain}/oauth2/authorize?identity_provider=Sportradar&redirect_uri=` +
            `${window.location.origin}/login&response_type=CODE&client_id=${config.Auth.sportradar.userPoolWebClientId}` +
            `&scope=aws.cognito.signin.user.admin%20email%20openid`,
          '_self'
        );
      }
    } catch (e: unknown) {
      alert((e as Error).message);
    }
  };

  return (
    <div className="login-form sr-forms-component">
      <div className="login-wrapper">
        <Logo />

        <div className="primary-button" onClick={signIn}>
          <div>{text.en['login-sso']}</div>
        </div>

        <div className="sr-forms-links">
          <Link to="/login">{text.en['use-manual-login']}</Link>
        </div>
      </div>
    </div>
  );
}
