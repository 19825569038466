import _ from 'lodash';
import {
  ADD_NOTIFICATION,
  CLEAR_NOTIFICATIONS,
  REMOVE_NOTIFICATION,
} from '../actions/actionTypes';

const initialState = {
  data: [],
  addPermanents: true,
};

const notifications = (state, { type, payload }) => {
  if (typeof state === 'undefined') {
    return initialState;
  }

  switch (type) {
    case ADD_NOTIFICATION: {
      return {
        data: [payload, ...state.data],
        addPermanents: false,
      };
    }
    case REMOVE_NOTIFICATION: {
      return {
        data: state.data.filter(
          (notification) => notification.id !== payload.id
        ),
      };
    }
    case CLEAR_NOTIFICATIONS: {
      return {
        data: [],
        addPermanents: true,
      };
    }
    default: {
      return state;
    }
  }
};

export default notifications;

export const getNotifications = (state) =>
  _.get(state, 'notifications.data', []);
export const getNotificationsCanAddPermanents = (state) =>
  _.get(state, 'notifications.addPermanents', null);
