import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { PlatformEnum } from '../../../shared/types/application';
import { FormDialog } from '../../UI/FormDialog/FormDialog';
import { ModalButton } from '../../../components/UI/ModalButton/ModalButton';
import text from '../../../shared/translations/en';
import { useDispatch, useSelector } from 'react-redux';
import { uploadApnsCertificate } from '../../../store/actions/applications';
import {
  selectModalError,
  selectUploadSuccess,
} from '../../../store/selectors/modalError';
import { hideModal } from '../../../store/actions/modals';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';

interface IProps {
  setEditField: (field: string) => void;
  certificate: string;
  platform: PlatformEnum;
  mode: string;
}

export function CerftificateDialog({
  setEditField,
  certificate,
  platform,
  mode,
}: IProps): JSX.Element {
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');
  const [extra, setExtra] = useState<string>('');
  const [file, setFile] = useState<File>();
  const [fileName, setFileName] = useState<string>(certificate);

  const error = useSelector(selectModalError);
  const uploadSuccess = useSelector(selectUploadSuccess);

  const handleFileChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const uploadedFile = event.target.files[0];
      setFileName(uploadedFile.name);
      setFile(uploadedFile);
    },
    []
  );

  const handleUploadApnCertificate = useCallback(() => {
    const obj = {
      file,
      password,
      mode,
      platform,
      extra,
    };
    dispatch(uploadApnsCertificate(obj));
  }, [file, password, platform, mode, extra]);

  const closeModal = useCallback(() => {
    dispatch(hideModal());
    setEditField(undefined);
  }, []);

  useEffect(() => {
    if (uploadSuccess) {
      setEditField(undefined);
      dispatch(hideModal());
    }
  }, [uploadSuccess]);

  return (
    <FormDialog open={true} handleClose={closeModal}>
      <DialogTitle id="form-dialog-title">Certificate</DialogTitle>
      <DialogContent>
        <FormControl style={{ position: 'relative', width: 300 }}>
          <div style={{ marginBottom: 8 }}>
            <label htmlFor="apnCertificate" className="formDialog--label">
              {!certificate && !fileName
                ? 'Choose file'
                : fileName ?? 'Choose file'}
            </label>
            <input
              type="file"
              id="apnCertificate"
              className="formDialog--input"
              onChange={handleFileChange}
            />
          </div>
          <TextField
            id="filled-adornment-password"
            variant="outlined"
            error={error && error.field === 'passphrase'}
            type={showPassword ? 'text' : 'password'}
            label="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment variant="filled" position="end">
                  <IconButton
                    aria-label="Toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      <i
                        className="icon-16-082-eye-privacy"
                        style={{ fontSize: 20 }}
                      />
                    ) : (
                      <i
                        className="icon-16-081-eye-public"
                        style={{ fontSize: 20 }}
                      />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {/* Websafari extra field */}
          <br />
          {platform === PlatformEnum.SAFARI && (
            <>
              <TextField
                variant="outlined"
                label="Url format"
                onChange={(e) => setExtra(e.target.value)}
                value={extra}
              />
              <FormHelperText
                style={{ fontSize: '1rem' }}
                dangerouslySetInnerHTML={{
                  __html: text.en['websafari-extra-lead'],
                }}
              />
            </>
          )}
          {error && error.message && (
            <FormHelperText
              id="component-error-text"
              style={{ fontSize: '1rem' }}
              error
            >
              {error.message}
            </FormHelperText>
          )}
        </FormControl>
      </DialogContent>
      <DialogActions>
        <ModalButton
          btnText={text.en.cancel}
          actionHandler={closeModal}
          type="transparent"
        />
        <ModalButton
          btnText="use"
          actionHandler={handleUploadApnCertificate}
          type="colored"
          isDisabled={fileName === certificate}
        />
      </DialogActions>
    </FormDialog>
  );
}
