import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { removeInvitation } from '../../store/actions/applications';

interface IProps {
  email: string;
  appId: string;
}

export function PendingUser({ email, appId }: IProps): JSX.Element {
  const dispatch = useDispatch();

  const removePendingUser = useCallback(() => {
    dispatch(removeInvitation(email, appId));
  }, []);

  return (
    <div className="users__box-row">
      <div className="users__box-col-email">{email} ( pending )</div>
      <div className="users__role-controls">
        <button className="sr-btn right" onClick={removePendingUser}>
          <i className="icon-16-079-trash" />
        </button>
      </div>
    </div>
  );
}
