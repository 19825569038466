import React, { ChangeEvent } from 'react';
import text from '../../shared/translations/en';
import { MdpUser } from '../../shared/types/user';

interface IProps {
  user: MdpUser;
  accessEnabled: boolean;
  onEnableAccessClick: (event: ChangeEvent<HTMLInputElement>) => void;
}

export function UserCard({
  user,
  onEnableAccessClick,
  accessEnabled,
}: IProps): JSX.Element {
  return (
    <div className="user__card">
      <span className="user__icon">
        <i className="icon-16-029-user" />
      </span>
      <h3 className="user__name">{user.userEmail}</h3>
      {!user.isSportradar && user.userExists && (
        <span className="user__enable_access">
          <label>{text.en['lock-user']}</label>
          <label className="mdp-switch">
            <input
              type="checkbox"
              checked={accessEnabled}
              onChange={onEnableAccessClick}
            />
            <span className="mdp-slider"></span>
          </label>
        </span>
      )}
    </div>
  );
}
