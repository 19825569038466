import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
  loading: false,
  subscriptions: [],
  history: [],
};

const fetchSubscriptionsStart = (state) => {
  return updateObject(state, {
    loading: true,
    subscriptions: [],
  });
};

const fetchSubscriptionsSuccess = (state, action) => {
  return updateObject(state, {
    subscriptions: action.subscriptions,
    loading: false,
  });
};

const fetchSubscriptionsFail = (state) => {
  return updateObject(state, {
    subscriptions: [],
    loading: false,
  });
};

const pnReportStart = (state) => {
  return updateObject(state, {
    loading: true,
    history: [],
  });
};

const pnReportSuccess = (state, action) => {
  return updateObject(state, {
    history: action.report,
    loading: false,
  });
};

const pnReportFail = (state) => {
  return updateObject(state, {
    history: [],
    loading: false,
  });
};

const customPN = (state, action) => {
  if (typeof state === 'undefined') {
    return initialState;
  }

  switch (action.type) {
    case actionTypes.FETCH_SUBSCRIPTIONS_START:
      return fetchSubscriptionsStart(state);
    case actionTypes.FETCH_SUBSCRIPTIONS_SUCCESS:
      return fetchSubscriptionsSuccess(state, action);
    case actionTypes.FETCH_SUBSCRIPTIONS_FAIL:
      return fetchSubscriptionsFail(state);
    case actionTypes.PN_REPORT_START:
      return pnReportStart(state);
    case actionTypes.PN_REPORT_FAIL:
      return pnReportFail(state);
    case actionTypes.PN_REPORT_SUCCESS:
      return pnReportSuccess(state, action);
    default:
      return state;
  }
};

export default customPN;
