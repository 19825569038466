import { Amplify, Auth, API } from 'aws-amplify';
import axios from 'axios';
import * as actionTypes from './actionTypes';

export async function currentUserToken() {
  return Auth.currentAuthenticatedUser().then(
    (user) => user.signInUserSession.idToken.jwtToken
  );
}

export const applyConfig = () => {
  return (dispatch) => {
    axios
      .get(`${process.env.REACT_APP_REDIRECT_URL ?? ''}/api/config/web`)
      .then((response) => {
        const { data } = response;

        const config = {
          Auth: {
            sportradar: {
              mandatorySignIn: true,
              region: data.sportradar.region,
              userPoolId: data.sportradar.userPoolId,
              userPoolWebClientId: data.sportradar.clientId,
              oauth: {
                domain: data.sportradar.webDomain.replace(/^https?:\/\//, ''),
                scope: ['email', 'openid', 'aws.cognito.signin.user.admin'],
                redirectSignIn: `${
                  process.env.REACT_APP_LOGIN_URL ?? window.location.origin
                }/login`,
                redirectSignOut: `${
                  process.env.REACT_APP_LOGIN_URL ?? window.location.origin
                }/logout`,
                responseType: 'code',
              },
            },
            customer: {
              mandatorySignIn: true,
              region: data.customer.region,
              userPoolId: data.customer.userPoolId,
              userPoolWebClientId: data.customer.clientId,
              oauth: {
                domain: data.customer.webDomain.replace(/^https?:\/\//, ''),
                scope: ['username', 'openid', 'aws.cognito.signin.user.admin'],
                redirectSignIn: `${
                  process.env.REACT_APP_LOGIN_URL ?? window.location.origin
                }/login`,
                redirectSignOut: `${
                  process.env.REACT_APP_LOGIN_URL ?? window.location.origin
                }/logout`,
                responseType: 'code',
              },
            },
          },
          API: {
            endpoints: [
              {
                name: 'mdp',
                endpoint: `${process.env.REACT_APP_REDIRECT_URL ?? ''}/api`,
                region: 'eu-west-1',
                custom_header: async () => {
                  return {
                    Authorization: `Bearer ${await currentUserToken()}`,
                  };
                },
              },
              {
                name: 'mdp-public',
                endpoint: `${process.env.REACT_APP_REDIRECT_URL ?? ''}/api`,
                region: 'eu-west-1',
              },
              {
                name: 'lambda',
                endpoint:
                  'https://6lewjscre9.execute-api.eu-west-1.amazonaws.com/',
                region: 'eu-west-1',
              },
            ],
          },
        };

        // Read loginType from localStorage to get user pool for logged in user
        const loginType = localStorage.getItem('login');

        Amplify.configure({
          Auth:
            loginType === 'customer'
              ? config.Auth.customer
              : config.Auth.sportradar,
          API: config.API,
        });
        API.configure();

        dispatch(setConfig(config));
      });
  };
};

export const setConfig = (config) => {
  return { type: actionTypes.SET_CONFIG, config };
};
