import React from 'react';

interface IProps {
  title: string;
  icon: JSX.Element;
}
export function ContactIcon({ title, icon }: IProps): JSX.Element {
  return (
    <div className="sr-footer__contact-icon">
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 26 26"
      >
        <title>{title}</title>
        {icon}
      </svg>
    </div>
  );
}
