import React from 'react';

import './ChartLegend.scss';

interface IProps {
  data: {
    title: string;
    color: string;
    value: number;
  }[];
}

export function ChartLegend({ data }: IProps) {
  const sum = data.reduce((acc, key) => acc + key.value, 0);

  const getPercentages = (value: number) => (
    <>{`(${Math.floor((value / sum) * 100)}%)`}</>
  );

  return (
    <div className="legend">
      {data.map((element) => (
        <div className="element" key={element.title}>
          <div className="color" style={{ background: element.color }}></div>
          {element.title} {getPercentages(element.value)}
        </div>
      ))}
    </div>
  );
}
