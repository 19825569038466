import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
  token: null,
  error: null,
  loading: false,
  success: false,
  authRedirectPath: '/applications/my',
};

const handleSuccess = (state) => {
  return updateObject(state, {
    loading: false,
    error: false,
    success: true,
  });
};

const authSuccess = (state, action) => {
  return updateObject(state, {
    token: action.idToken ? action.idToken : localStorage.getItem('token'),
    error: null,
    loading: false,
  });
};

const authLogout = (state) => {
  return updateObject(state, { token: null });
};

const authUploadStart = (state) => {
  return updateObject(state, {
    loading: true,
  });
};

const authUploadSuccess = (state) => {
  return handleSuccess(state);
};

const authUploadFail = (state) => {
  return updateObject(state, {
    error: true,
    loading: false,
  });
};

const setAuthRedirectPath = (state, action) => {
  return updateObject(state, { authRedirectPath: action.path });
};

const userActivationSuccess = (state) => {
  return handleSuccess(state);
};

const userActivationFail = (state) => {
  return updateObject(state, {
    loading: false,
    error: true,
  });
};

const auth = (state, action) => {
  if (typeof state === 'undefined') {
    return initialState;
  }

  switch (action.type) {
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case actionTypes.AUTH_LOGOUT:
      return authLogout(state);
    case actionTypes.AUTH_UPLOAD_START:
      return authUploadStart(state);
    case actionTypes.AUTH_UPLOAD_SUCCESS:
      return authUploadSuccess(state);
    case actionTypes.AUTH_UPLOAD_FAIL:
      return authUploadFail(state);
    case actionTypes.SET_AUTH_REDIRECT_PATH:
      return setAuthRedirectPath(state, action);
    case actionTypes.USER_ACTIVATION_SUCCESS:
      return userActivationSuccess(state);
    case actionTypes.USER_ACTIVATION_FAIL:
      return userActivationFail(state);
    default:
      return state;
  }
};

export default auth;
