import React from 'react';
import ReactDOM from 'react-dom/client';
import Root, { store } from './Root';
import { unregister } from './registerServiceWorker';
import './index.scss';
import { App } from './App';
import axios from 'axios';
import { logout } from './store/actions/auth';
import { localeSet } from './store/actions/locale';
import ReduxToastr from 'react-redux-toastr';
import { createTheme, ThemeProvider } from '@mui/material';

// @ts-ignore
const muiTheme = createTheme({
  typography: {fontFamily: 'Source Sans Pro' },
});

// interceptors for globally handling response
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // unauthorized 401 from mdp-service should not loged out
    const mdpServiceUrl = error.response.data?.path?.startsWith('/api/v3');
    // unauthorized error
    if (error.response.status === 401 && !mdpServiceUrl) {
      store.dispatch(logout());
    }
    return Promise.reject(error);
  }
);
// set language to redux state
if (localStorage.alhubLang) {
  store.dispatch(localeSet(localStorage.alhubLang));
}

const app = (
  <Root>
    <>
      <ReduxToastr
        position="bottom-center"
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        progressBar
      />
      <ThemeProvider theme={muiTheme}>
        <App />
      </ThemeProvider>
    </>
  </Root>
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(app);
unregister();
