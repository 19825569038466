import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import text from '../../shared/translations/en';
import { selectApplication } from '../../store/selectors/application';
import { MdpRoles } from '../../shared/types/user';
import { AppUsers } from './AppUsers';
import { inviteUser } from '../../store/actions/applications';

export function Users(): JSX.Element {
  const dispatch = useDispatch();

  const [inviteEmail, setInviteEmail] = useState<string>('');
  const [inviteRole, setInviteRole] = useState<string>(MdpRoles.DEVELOPER);
  const [showButton, setShowButton] = useState<boolean>(false);

  const app = useSelector(selectApplication);

  const handleInviteUser = useCallback(() => {
    dispatch(inviteUser({ email: inviteEmail, role: text.en[inviteRole] }));
    clearForm();
  }, [inviteEmail, inviteRole]);

  const clearForm = useCallback(() => {
    setShowButton(false);
    setInviteEmail('');
    setInviteRole(MdpRoles.DEVELOPER);
  }, []);

  useEffect(() => {
    setShowButton(inviteEmail !== '');
  }, [inviteEmail]);

  return (
    <section className="section">
      <h1>
        <span className="icon-header">
          <i className="icon-16-030-people" />
        </span>
        {text.en.users}
      </h1>
      <div className="u-margin-top-42"></div>
      <div className="app-detail__row">
        <div className="col-1-of-2">
          <p className="body-3">{text.en['users-lead']}</p>
        </div>
      </div>
      <h4 className="u-margin-top-42">{text.en['users-invite']}</h4>
      <div className="row">
        {/* email */}
        <div className="col-1-of-2">
          <div className="input-row__wrapper u-content-margin-top-extra-small">
            <label className="input-row__label">{text.en.email}</label>
            <div className="input-row__input-contanier">
              <span className="input-icon">
                <i className="icon-16-006-edit" />
              </span>
              <input
                value={inviteEmail}
                className="input-row__input u-grey-bg"
                onChange={(e) => setInviteEmail(e.target.value)}
                onKeyDown={(e) => e.key === 'Enter' && handleInviteUser()}
              />
            </div>
          </div>
        </div>
        {/* role */}
        <div className="col-1-of-2">
          <div className="input-row__wrapper u-content-margin-top-extra-small">
            <label className="input-row__label">{text.en.role}</label>
            <div className="input-row__input-contanier">
              <select
                id="select-role"
                className="select select-grey"
                value={inviteRole}
                onChange={(event) => setInviteRole(event.target.value)}
              >
                <option value={text.en[MdpRoles.DEVELOPER]}>
                  {text.en[MdpRoles.DEVELOPER]}
                </option>
                <option value={text.en[MdpRoles.VIEWER]}>
                  {text.en[MdpRoles.VIEWER]}
                </option>
                <option value={text.en[MdpRoles.SALES]}>
                  {text.en[MdpRoles.SALES]}
                </option>
              </select>
            </div>
            <div className="input-row__support-msg">
              {inviteRole === text.en[MdpRoles.VIEWER] && (
                <p className="u-color-blue">{text.en['role-viewer-info']}</p>
              )}
            </div>
            {showButton && (
              <div className="btn-wrapper">
                <button
                  className="sr-btn sr-btn__sm btn-cancel"
                  title={text.en.cancel}
                  onClick={clearForm}
                >
                  {text.en.cancel}
                </button>
                <button
                  className="sr-btn sr-btn__sm btn-default"
                  title={text.en.save}
                  onClick={() => handleInviteUser()}
                >
                  {text.en.save}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="users__box">
        <div className="users__box-heading">
          <span className="users__box-heading-primary">{text.en.email}</span>
          <span className="users__box-heading-secondary">{text.en.role}</span>
        </div>
        <AppUsers app={app} />
      </div>
    </section>
  );
}
