import {
  ASYNC_ACTION_START,
  ASYNC_ACTION_FINISH,
  ASYNC_ACTION_ERROR,
  ASYNC_ACTION_SUCCESS,
  ASYNC_ACTION_CLEAR,
  ASYNC_ACTION_REMOVE_ERROR,
} from '../actions/actionTypes';

const initialState = {
  loading: false,
  elementName: null,
  errors: [],
  asyncSuccess: false,
};

const async = (state, payload) => {
  if (typeof state === 'undefined') {
    return initialState;
  }

  switch (payload.type) {
    case ASYNC_ACTION_START: {
      return {
        ...state,
        loading: true,
        errors: [],
        asyncSuccess: false,
      };
    }
    case ASYNC_ACTION_FINISH: {
      return {
        ...state,
        loading: false,
        errors: [],
        asyncSuccess: false,
      };
    }
    case ASYNC_ACTION_SUCCESS: {
      return {
        ...state,
        loading: false,
        errors: [],
        asyncSuccess: true,
      };
    }
    case ASYNC_ACTION_ERROR: {
      const { errors } = payload;
      return {
        ...state,
        loading: false,
        errors,
      };
    }
    case ASYNC_ACTION_CLEAR: {
      return {
        loading: false,
        elementName: null,
        errors: [],
        asyncSuccess: false,
      };
    }
    case ASYNC_ACTION_REMOVE_ERROR: {
      let errors = state.errors.splice();
      errors = errors.filter((e, i) => i !== payload.errorIndex);
      return {
        errors: errors,
      };
    }
    default:
      return state;
  }
};

export default async;
