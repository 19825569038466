import React from 'react';

export function Miscellaneous(): JSX.Element {
  return (
    <>
      <h2>16. Miscellaneous</h2>
      <h3>16.1 Changes to the Terms</h3>
      <p>
        Sportradar may make changes to this Agreement as it distributes new
        versions of the MDP and Mobile SDK. When these changes are made,
        Sportradar will make a new version of the Terms and Conditions available
        on the website where the MDP is made available.
      </p>
      <h3>16.2 Entirety of Agreement</h3>
      <p>
        This Agreement contains the entire agreement between Sportradar and the
        Customer (the arties) in respect of the subject matter hereof, the MDP
        and Mobile SDK, and supersedes and cancels all previous agreements,
        negotiations, commitments and writings between the parties hereto in
        respect of the subject matter hereof.
      </p>
      <h3>16.3 Amendments</h3>
      <p>
        Any amendment of or modification to this Agreement shall be made in
        writing (including any amendment or modification of this clause). The
        Parties agree with binding effect that oral side agreements shall not be
        concluded, unless they are confirmed in writing. Any waiver by the
        Parties of this written form requirement shall be subject to form
        requirements.
      </p>
      <h3>16.4 Assignment to Third Party</h3>
      <p>
        Neither Party may assign to any third Party, transfer nor otherwise
        dispose of this Agreement nor any obligation with respect thereto
        without the prior written consent of the other Party, provided, however,
        that Sportradar may transfer and assign any or all of its rights and
        obligations hereunder to any Affiliate of Sportradar without any
        explicit consent of the Customer. Any purported or attempted assignment
        in violation of this paragraph shall be null and void.
      </p>
      <h3>16.5 Invalidity</h3>
      <p>
        If any term hereof is invalid or ineffective, this shall not affect the
        validity of the remaining terms hereof. The invalid or ineffective term
        shall be reasonably replaced by a term that most closely reflects the
        intended purpose of the Agreement. The same applies in case of any gap
        and as regards the interpretation hereof.
      </p>
      <h3>16.6 Governance</h3>
      <p>
        This agreement shall be governed by and construed in accordance with
        Swiss law. Exclusive place of jurisdiction shall be St. Gallen,
        Switzerland.
      </p>
      <h3>16.7 Authority to Act</h3>
      <p>
        Nothing in this Agreement is intended to or shall operate to create a
        partnership or joint venture of any kind between the parties, or to
        authorise either Party to act as agent for the other, and neither Party
        shall have authority to act in the name or on behalf of or otherwise to
        bind the other in any way (including the making of any representation or
        warranty, the assumption of any obligation or liability and the exercise
        of any right or power).
      </p>
      <h3>16.8 Voluntary Nature of Data Input</h3>
      <p>
        If input of personal or business data (email addresses, name, addresses
        etc.) is made by customer, the data will be used for the proper
        functioning of the service only. This also includes billing of services
        and accounting purposes as far as applicable.
      </p>
      <h3>16.9 Open source libraries used by SDK</h3>
      <ul>
        <li>
          <a href="https://www.slf4j.org/license.html">
            Simple Logging Façade for Java
          </a>
        </li>
        <li>
          <a href="https://square.github.io/okhttp/">OkHttp</a>
        </li>
        <li>
          <a href="https://github.com/ReactiveX/RxJava">RxJava</a>
        </li>
        <li>
          <a href="https://github.com/AFNetworking/AFNetworking/blob/master/LICENSE">
            AFNetworking
          </a>
        </li>
        <li>
          <a href="https://github.com/pinterest/PINCache">PINCache</a>
        </li>
      </ul>
    </>
  );
}
