export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const FETCH_APPLICATIONS_START = 'FETCH_APPLICATIONS_START';
export const FETCH_APPLICATIONS_SUCCESS = 'FETCH_APPLICATIONS_SUCCESS';
export const FETCH_APPLICATIONS_FAIL = 'FETCH_APPLICATIONS_FAIL';

export const FETCH_APP_START = 'FETCH_APP_START';
export const FETCH_APP_SUCCESS = 'FETCH_APP_SUCCESS';
export const FETCH_APP_FAIL = 'FETCH_APP_FAIL';

export const UPLOAD_START = 'UPLOAD_START';
export const UPLOAD_SUCCESS = 'UPLOAD_SUCCESS';
export const UPLOAD_FAIL = 'UPLOAD_FAIL';
export const UPLOAD_APNS_CERTIFICATE_FAIL = 'UPLOAD_APNS_CERTIFICATE_FAIL';
export const UPLOAD_ICON_FAIL = 'UPLOAD_ICON_FAIL';
export const UPLOAD_ICON_SUCCESS = 'UPLOAD_ICON_SUCCESS';

export const CANCEL_ERROR = 'CANCEL_ERROR';
export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';

export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
export const CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS';

export const AUTH_UPLOAD_START = 'AUTH_UPLOAD_START';
export const AUTH_UPLOAD_SUCCESS = 'AUTH_UPLOAD_SUCCESS';
export const AUTH_UPLOAD_FAIL = 'AUTH_UPLOAD_FAIL';
// documentation
export const FETCH_DOCS_SUCCESS = 'FETCH_DOCS_SUCCESS';
export const FETCH_DOC_SUCCESS = 'FETCH_DOC_SUCCESS';

export const USER_ACTIVATION_SUCCESS = 'USER_ACTIVATION_SUCCESS';
export const USER_ACTIVATION_FAIL = 'USER_ACTIVATION_FAIL';
//  user - profile
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAIL = 'FETCH_USER_FAIL';

// add user to users
export const USER_REGISTRATION_SUCCESS = 'USER_REGISTRATION_SUCCESS';
export const CLEAR_USER_REGISTRATION = 'CLEAR_USER_REGISTRATION';

// users - admin access
export const FETCH_ALL_USERS_START = 'FETCH_ALL_USERS_START';
export const FETCH_ALL_USERS_SUCCESS = 'FETCH_ALL_USERS_SUCCESS';
export const FETCH_ALL_USERS_FAIL = 'FETCH_ALL_USERS_FAIL';
export const FETCH_USER_APPLICATIONS = 'FETCH_USER_APPLICATIONS';
// api key
export const FETCH_API_ACCESS_KEY_START = 'FETCH_API_ACCESS_KEY_START';
export const FETCH_API_ACCESS_KEY_SUCCESS = 'FETCH_API_ACCESS_KEY_SUCCESS';
export const FETCH_API_ACCESS_KEY_FAIL = 'FETCH_API_ACCESS_KEY_FAIL';
// custom push notifications
export const CUSTOM_PN_START = 'CUSTOM_PN_START';
export const CUSTOM_PN_SUCCESS = 'CUSTOM_PN_SUCCESS';
export const CUSTOM_PN_FAIL = 'CUSTOM_PN_FAIL';
export const PN_REPORT_START = 'PN_REPORT_START';
export const PN_REPORT_SUCCESS = 'PN_REPORT_SUCCESS';
export const PN_REPORT_FAIL = 'PN_REPORT_FAIL';
// subscriptions
export const FETCH_SUBSCRIPTIONS_START = 'FETCH_SUBSCRIPTIONS_START';
export const FETCH_SUBSCRIPTIONS_SUCCESS = 'FETCH_SUBSCRIPTIONS_SUCCESS';
export const FETCH_SUBSCRIPTIONS_FAIL = 'FETCH_SUBSCRIPTIONS_FAIL';
// recordings
export const FETCH_RECORDINGS_START = 'FETCH_RECORDINGS_START';
export const FETCH_RECORDINGS_SUCCESS = 'FETCH_RECORDINGS_SUCCESS';
export const FETCH_RECORDINGS_FAIL = 'FETCH_RECORDINGS_FAIL';
export const FETCH_ACTIVE_PLAYBACKS_SUCCESS = 'FETCH_ACTIVE_PLAYBACKS_SUCCESS';
export const CLEAR_ACTIVE_PLAYBACKS = 'CLEAR_ACTIVE_PLAYBACKS';
export const ACTIVATE_PLAYBACK_FAIL = 'ACTIVATE_PLAYBACK_FAIL';
export const DELETE_PLAYBACK_FAIL = 'DELETE_PLAYBACK_FAIL';
export const INTERVAL_STOP = 'INTERVAL_STOP';
export const FETCH_RECORDING_PATHS_START = 'FETCH_RECORDING_PATHS_START';
export const FETCH_RECORDING_PATHS_SUCCESS = 'FETCH_RECORDING_PATHS_SUCCESS';
export const FETCH_RECORDING_PATHS_FAIL = 'FETCH_RECORDING_PATHS_FAIL';
export const FETCH_RECORDING_FILE_START = 'FETCH_RECORDING_FILE_START';
export const FETCH_RECORDING_FILE_SUCCESS = 'FETCH_RECORDING_FILE_SUCCESS';
export const FETCH_RECORDING_FILE_FAIL = 'FETCH_RECORDING_FILE_FAIL';
export const SET_RECORDING_PUBLIC_FAIL = 'SET_RECORDING_PUBLIC_FAIL';
export const SET_RECORDING_PUBLIC_START = 'SET_RECORDING_PUBLIC_START';
export const SET_RECORDING_PUBLIC_SUCCESS = 'SET_RECORDING_PUBLIC_SUCCESS';
export const SET_RECORDING = 'SET_RECORDING';
export const FETCH_APP_RECORDINGS_START = 'FETCH_APP_RECORDINGS_START';
export const FETCH_APP_RECORDINGS_SUCCESS = 'FETCH_APP_RECORDINGS_SUCCESS';
export const FETCH_APP_RECORDINGS_FAIL = 'FETCH_APP_RECORDINGS_FAIL';
export const SET_RECORDING_TO_APP_START = 'SET_RECORDING_TO_APP_START';
export const SET_RECORDING_TO_APP_SUCCESS = 'SET_RECORDING_TO_APP_SUCCESS';
export const SET_RECORDING_TO_APP_FAIL = 'SET_RECORDING_TO_APP_FAIL';

// devices
export const FETCH_DEVICES_START = 'FETCH_DEVICES_START';
export const FETCH_DEVICES_SUCCESS = 'FETCH_DEVICES_SUCCESS';
export const FETCH_DEVICES_FAIL = 'FETCH_DEVICES_FAIL';
export const FETCH_REGISTERED_DEVICES_START = 'FETCH_REGISTERED_DEVICES_START';
export const FETCH_REGISTERED_DEVICES_SUCCESS =
  'FETCH_REGISTERED_DEVICES_SUCCESS';
export const FETCH_REGISTERED_DEVICES_FAIL = 'FETCH_REGISTERED_DEVICES_FAIL';
export const FETCH_DEVICE_REGISTER_START = 'FETCH_DEVICE_REGISTER_START';
export const FETCH_DEVICE_REGISTER_SUCCESS = 'FETCH_DEVICE_REGISTER_SUCCESS';
export const FETCH_DEVICE_REGISTER_FAIL = 'FETCH_DEVICE_REGISTER_FAIL';
export const FETCH_DEVICE_UPDATE_START = 'FETCH_DEVICE_UPDATE_START';
export const FETCH_DEVICE_UPDATE_SUCCESS = 'FETCH_DEVICE_UPDATE_SUCCESS';
export const FETCH_DEVICE_UPDATE_FAIL = 'FETCH_DEVICE_UPDATE_FAIL';
export const FETCH_DEVICE_DELETE_START = 'FETCH_DEVICE_DELETE_START';
export const FETCH_DEVICE_DELETE_SUCCESS = 'FETCH_DEVICE_DELETE_SUCCESS';
export const FETCH_DEVICE_DELETE_FAIL = 'FETCH_DEVICE_DELETE_FAIL';
export const DEVICES_CLEAR = 'DEVICES_CLEAR';
export const FETCH_DEVICES_STATE_START = 'FETCH_DEVICES_STATE_START';
export const FETCH_DEVICES_STATE_SUCCESS = 'FETCH_DEVICES_STATE_SUCCESS';
export const FETCH_DEVICES_STATE_FAIL = 'FETCH_DEVICES_STATE_FAIL';

// localization
export const LOCALE_SET = 'LOCALE_SET';
// admin
export const CHANGE_OWNER_SUCCESS = 'CHANGE_OWNER_SUCCESS';
export const CHANGE_OWNER_FAIL = 'CHANGE_OWNER_FAIL';
export const FETCH_PLANS_START = 'FETCH_PLANS_START';
export const FETCH_PLANS_SUCCESS = 'FETCH_PLANS_SUCCESS';
export const FETCH_PLANS_FAIL = 'FETCH_PLANS_FAIL';
export const SET_PLAN_START = 'SET_PLAN_START';
export const SET_PLAN_SUCCESS = 'SET_PLAN_SUCCESS';
export const SET_PLAN_FAIL = 'SET_PLAN_FAIL';
export const ENABLE_APP_SUCCESS = 'ENABLE_APP_SUCCESS';
export const ENABLE_APP_FAIL = 'ENABLE_APP_FAIL';
export const FETCH_USER_PLAN_START = 'FETCH_USER_PLAN_START';
export const FETCH_USER_PLAN_SUCCESS = 'FETCH_USER_PLAN_SUCCESS';
export const FETCH_USER_PLAN_FAIL = 'FETCH_USER_PLAN_FAIL';

// admin recordings
export const UPDATE_RECORDING_SUCCESS = 'UPDATE_RECORDING_SUCCESS';
export const UPDATE_RECORDING_FAIL = 'UPDATE_RECORDING_FAIL';
export const SCHEDULE_RECORDING_SUCCESS = 'SCHEDULE_RECORDING_SUCCESS';
export const SCHEDULE_RECORDING_FAIL = 'SCHEDULE_RECORDING_FAIL';
// push modifiers
export const FETCH_ALL_PUSH_MODIFIERS_SUCCESS =
  'FETCH_ALL_PUSH_MODIFIERS_SUCCESS';
export const FETCH_ALL_PUSH_MODIFIERS_FAIL = 'FETCH_ALL_PUSH_MODIFIERS_FAIL';
export const FETCH_APP_PUSH_MODIFIERS_SUCCESS =
  'FETCH_APP_PUSH_MODIFIERS_SUCCESS';
export const FETCH_APP_PUSH_MODIFIERS_FAIL = 'FETCH_APP_PUSH_MODIFIERS_FAIL';
// apn token
export const FETCH_APN_TOKENS_SUCCESS = 'FETCH_APN_TOKENS_SUCCESS';
export const FETCH_APN_TOKENS_FAIL = 'FETCH_APN_TOKENS_FAIL';
// modal dialog
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const MODAL_ERROR = 'MODAL_ERROR';
export const MODAL_UPLOAD_START = 'MODAL_UPLOAD_START';
export const MODAL_UPLOAD_SUCCESS = 'MODAL_UPLOAD_SUCCESS';
// report
export const FETCH_SIMPLE_REPORT = 'FETCH_SIMPLE_REPORT';
export const FETCH_NOTIFICATION_HISTORY_REPORT =
  'FETCH_NOTIFICATION_HISTORY_REPORT';
// async
export const ASYNC_ACTION_START = 'ASYNC_ACTION_START';
export const ASYNC_ACTION_FINISH = 'ASYNC_ACTION_FINISH';
export const ASYNC_ACTION_ERROR = 'ASYNC_ACTION_ERROR';
export const ASYNC_ACTION_SUCCESS = 'ASYNC_ACTION_SUCCESS';
export const ASYNC_ACTION_CLEAR = 'ASYNC_ACTION_CLEAR';
export const ASYNC_ACTION_REMOVE_ERROR = 'ASYNC_ACTION_REMOVE_ERROR';

// monitoring
export const FETCH_MONITORING_STATUS_SUCCESS =
  'FETCH_MONITORING_STATUS_SUCCESS';
export const FETCH_MONITORING_STATUSES_DETAILS =
  'FETCH_MONITORING_STATUSES_DETAILS';

// user role change / admin
export const CHANGE_USER_ROLE_START = 'CHANGE_USER_ROLE_START';
export const CHANGE_USER_ROLE_SUCCESS = 'CHANGE_USER_ROLE_SUCCESS';
export const CHANGE_USER_ROLE_FAIL = 'CHANGE_USER_ROLE_FAIL';

// user activation change / admin
export const CHANGE_USER_ACTIVATION_START = 'CHANGE_USER_ACTIVATION_START';
export const CHANGE_USER_ACTIVATION_SUCCESS = 'CHANGE_USER_ACTIVATION_SUCCESS';
export const CHANGE_USER_ACTIVATION_FAIL = 'CHANGE_USER_ACTIVATION_FAIL';

// user lock change / admin
export const CHANGE_USER_LOCK_START = 'CHANGE_USER_LOCK_START';
export const CHANGE_USER_LOCK_SUCCESS = 'CHANGE_USER_LOCK_SUCCESS';
export const CHANGE_USER_LOCK_FAIL = 'CHANGE_USER_LOCK_FAIL';

export const UPDATE_SETTINGS_SUCCESS = 'UPDATE_SETTINGS_SUCCESS';
export const UPDATE_ADMIN_SETTINGS_SUCCESS = 'UPDATE_ADMIN_SETTINGS_SUCCESS';

// pagination
export const CHANGE_APPLICATIONS_PAGE = 'CHANGE_APPLICATIONS_PAGE';
export const CHANGE_USERS_PAGE = 'CHANGE_USERS_PAGE';

// search
export const CHANGE_USERS_SEARCH = 'CHANGE_USERS_SEARCH';

export const APPLY_CONFIG = 'APPLY_CONFIG';
export const SET_CONFIG = 'SET_CONFIG';

export const RESET_USER = 'RESET_USER';
export const SET_TEMPORARY_USER = 'SET_TEMPORARY_USER';

export const SET_APPLICATION_ICON_SUCCESS = 'SET_APPLICATION_ICON_SUCCESS';
export const SET_APPLICATION_ICON_FAIL = 'SET_APPLICATION_ICON_FAIL';

export const ACCEPT_INVITE_SUCCESS = 'ACCEPT_INVITE_SUCCES';
export const REMOVE_INVITATION = 'REMOVE_INVITATION';

export const SET_SELECTED_PLATFORMS = 'SET_SELECTED_PLATFORMS';
export const SET_SELECTED_ENVIRONMENTS = 'SET_SELECTED_ENVIRONMENTS';

export const SET_QR_CODE = 'SET_QR_CODE';

export const FETCH_USAGE_IMAGE_SUCCESS = 'FETCH_USAGE_IMAGE_SUCCESS';
export const FETCH_USAGE_IMAGE_FAIL = 'FETCH_USAGE_IMAGE_FAIL';

export const SEND_PUSH_NOTIFICATION_START = 'SEND_PUSH_NOTIFICATION_START';
export const SEND_PUSH_NOTIFICATION_SUCCESS = 'SEND_PUSH_NOTIFICATION_SUCCESS';
export const SEND_PUSH_NOTIFICATION_FAIL = 'SEND_PUSH_NOTIFICATION_FAIL';
export const RESET_PUSH_NOTIFICATION_SENT = 'RESET_PUSH_NOTIFICATION_SENT';

export const FETCH_COST_REPORT_START = 'FETCH_COST_REPORT_START';
export const FETCH_COST_REPORT_SUCCESS = 'FETCH_COST_REPORT_SUCCESS';
export const FETCH_COST_REPORT_FAIL = 'FETCH_COST_REPORT_FAIL';

export const REGISTER_USER_START = 'REGISTER_USER_START';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';

export const ADD_PUSH_MODIFIER_SUCCESS = 'ADD_PUSH_MODIFIER_SUCCESS';
export const ADD_PUSH_MODIFIER_FAIL = 'ADD_PUSH_MODIFIER_FAIL';
