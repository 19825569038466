import React from 'react';
import { Device } from '../../shared/types/device';

interface IProps {
  device: Device;
}

export function PushSubscriptions({ device }: IProps): JSX.Element {
  return (
    <span className={'srm-push-token'}>
      <span
        className={`srm-badge ${device.hasSubscriptions ? ' srm-green' : ''}`}
      >
        {device.hasSubscriptions ? 'Yes' : 'No'}
      </span>
    </span>
  );
}
