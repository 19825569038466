import axios from 'axios';
import * as actionTypes from './actionTypes';
import { getToken } from '../../shared/utility';
import { API } from 'aws-amplify';
import { toastr } from 'react-redux-toastr';

export const fetchAvailableDevices = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_DEVICES_START,
    });

    API.get('mdp', `/test_device/${payload.appId}/available`, {})
      .then((response) => {
        dispatch({
          type: actionTypes.FETCH_DEVICES_SUCCESS,
          data: response,
        });
      })
      .catch(() => {
        dispatch({
          type: actionTypes.FETCH_DEVICES_FAIL,
        });
      });
  };
};

export const fetchRegisteredDevices = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_REGISTERED_DEVICES_START,
    });
    API.get('mdp', `/test_device/${payload.appId}/registered`, {})
      .then((response) => {
        dispatch({
          type: actionTypes.FETCH_REGISTERED_DEVICES_SUCCESS,
          data: response.map((device) => ({
            ...device.device,
            ...device,
            name: device.displayName,
          })),
        });
      })
      .catch(() => {
        dispatch({
          type: actionTypes.FETCH_REGISTERED_DEVICES_FAIL,
        });
      });
  };
};

export const fetchDeviceState = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_DEVICES_STATE_START,
    });
    axios
      .get(`/api/test_device/${payload.appId}/${payload.deviceId}/state`, {
        headers: { authorization: `Bearer ${getToken()}` },
      })
      .then((response) => {
        dispatch({
          type: actionTypes.FETCH_DEVICES_STATE_SUCCESS,
          data: response.data,
          deviceId: payload.deviceId,
        });
      })
      .catch(() => {
        dispatch({
          type: actionTypes.FETCH_DEVICES_STATE_FAIL,
        });
      });
  };
};

export const registerDevice = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_DEVICE_REGISTER_START,
    });
    API.post('mdp', `/test_device/${payload.appId}/register`, {
      body: payload,
    })
      .then((response) => {
        dispatch({
          type: actionTypes.FETCH_DEVICE_REGISTER_SUCCESS,
          data: response,
        });
        toastr.success('Device was successfully added as registered devices.');
      })
      .catch(() => {
        dispatch({
          type: actionTypes.FETCH_DEVICE_REGISTER_FAIL,
        });
      });
  };
};

export const deleteDevice = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_DEVICE_DELETE_START,
    });
    API.del('mdp', `/test_device/${payload.appId}`, {
      body: payload,
    })
      .then(() => {
        dispatch({
          type: actionTypes.FETCH_DEVICE_DELETE_SUCCESS,
          data: payload,
        });
      })
      .catch(() => {
        dispatch({
          type: actionTypes.FETCH_DEVICE_DELETE_FAIL,
        });
      });
  };
};

export const updateDevice = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_DEVICE_UPDATE_START,
    });
    API.put('mdp', `/test_device/${payload.appId}`, {
      body: payload,
    })
      .then((response) => {
        dispatch({
          type: actionTypes.FETCH_DEVICE_UPDATE_SUCCESS,
          data: response,
        });
      })
      .catch(() => {
        dispatch({
          type: actionTypes.FETCH_DEVICE_UPDATE_FAIL,
        });
      });
  };
};

export const setSelectedPlatforms = (platform) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.SET_SELECTED_PLATFORMS, platform });
  };
};

export const setSelectedEnvironments = (environment) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.SET_SELECTED_ENVIRONMENTS, environment });
  };
};

export const getQrCode = (appId) => {
  return (dispatch) =>
    API.get('mdp', `/test_device/${appId}/qr`, {
      responseType: 'blob',
    }).then((response) => dispatch(setQrCode(URL.createObjectURL(response))));
};

export const setQrCode = (qrCode) => ({
  type: actionTypes.SET_QR_CODE,
  qrCode,
});

export const sendPushNotifications = (device) => {
  const { id, appId, platform, modeType } = device;

  return (dispatch) => {
    dispatch(sendPushNotificationStart());
    API.post('mdp', '/test_device/demoNotification', {
      body: {
        id,
        appId,
        platform,
        modeType,
      },
    })
      .then(() => dispatch(sendPushNotificationSuccess()))
      .catch((error) => dispatch(sendPushNotificationFail(error)));
  };
};

export const sendPushNotificationStart = () => {
  return { type: actionTypes.SEND_PUSH_NOTIFICATION_START };
};

export const sendPushNotificationFail = (error) => {
  return { type: actionTypes.SEND_PUSH_NOTIFICATION_FAIL, error };
};

export const sendPushNotificationSuccess = (error) => {
  return { type: actionTypes.SEND_PUSH_NOTIFICATION_SUCCESS, error };
};

export const resetPushNotificationSent = () => {
  return { type: actionTypes.RESET_PUSH_NOTIFICATION_SENT };
};
