import { FormControl, FormGroup, MenuItem, TextField } from '@mui/material';
import React from 'react';
import { ApnToken } from '../../../shared/types/store';

interface IProps {
  apnTokens: ApnToken[];
  keyId: string;
  setKeyId: (key: string) => void;
}

export function ExistingToken({
  apnTokens,
  keyId,
  setKeyId,
}: IProps): JSX.Element {
  return (
    <FormGroup row>
      <FormControl style={{ position: 'relative', width: 300 }}>
        <TextField
          id="standard-select-keyId"
          select
          label="Key id"
          value={keyId}
          onChange={(e) => setKeyId(e.target.value)}
          margin="normal"
          variant="outlined"
        >
          {apnTokens
            ? apnTokens.map((apnToken) => (
                <MenuItem
                  key={apnToken.keyId}
                  value={apnToken.keyId}
                  style={{ width: '100%' }}
                >
                  {apnToken.keyId}
                </MenuItem>
              ))
            : null}
        </TextField>
      </FormControl>
    </FormGroup>
  );
}
