import React, { FormEvent, useCallback, useState } from 'react';
import text from '../../shared/translations/en';
import { IconDisabled } from '../UI/Icons/IconDisabled/IconDisabled';
import { ImageUploader } from '../ImageUploader/ImageUploader';
import { Application, Plan } from '../../shared/types/application';
import { CognitoUser, MdpUser } from '../../shared/types/user';
import { updateAppName } from '../../store/actions';
import { useDispatch } from 'react-redux';
import { convertUnixExpiryTime } from '../../utils/Date';
import { isPast } from 'date-fns';

interface IProps {
  app?: Application;
  user?: MdpUser | CognitoUser;
}

export function SidebarAppInfo({ app, user }: IProps): JSX.Element {
  const dispatch = useDispatch();

  const [touched, setTouched] = useState<boolean>(false);
  const [name, setName] = useState<string>('');

  const clickHandler = useCallback(() => {
    setName(app.name);
    setTouched(true);
  }, [app]);

  const changeHandler = useCallback((event: FormEvent<HTMLInputElement>) => {
    setName(event.currentTarget.value);
  }, []);

  const saveHandler = useCallback(() => {
    dispatch(updateAppName({ name }, user));
    setTouched(false);
  }, [user, name]);

  const {
    en: { plan, description, save, cancel },
  } = text;

  return (
    <div className="app__detail">
      <div className="app__detail-title" onClick={clickHandler}>
        {touched ? (
          <input type="text" onChange={changeHandler} value={name} />
        ) : (
          app.name
        )}
      </div>
      {!app.enabled ? (
        <div className="app__detail-status">
          <IconDisabled />
          {text.en.disabled}
        </div>
      ) : null}
      {touched ? (
        <div className="app__detail-controls">
          <span
            className="app__detail-controls-right"
            onClick={saveHandler}
            title={save}
          >
            <i className="icon-16-378-success2" />
          </span>
          <span
            className="app__detail-controls-left"
            onClick={() => setTouched(false)}
            title={cancel}
          >
            <i className="icon-16-377-close2" />
          </span>
        </div>
      ) : null}
      <div className="app__detail-icon">
        <ImageUploader iconUrl={app.iconUrl} />
      </div>
      <div className="app__detail-content-box">
        <div className={'app__detail-content-item'}>
          <span>{plan}:</span>
          <span className="app__detail-content-plan u-label u-bg-green">
            {app.plan ? (app.plan as Plan).name : ''}
          </span>
        </div>
        <div className="app__detail-content-item app__detail-expiration">
          <span>
            {isPast(new Date((app.plan as Plan).expiryTime))
              ? text.en['expired-on']
              : text.en['expires-on']}
            :
          </span>
          {app.plan
            ? convertUnixExpiryTime(new Date((app.plan as Plan).expiryTime))
            : ''}
        </div>
        {app.description ? (
          <div className="app__detail-content-desc" title={app.description}>
            <div>{description}:</div>
            <div>{app.description}</div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
