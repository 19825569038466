import { Platform } from '../shared/types/application';

export const getAllEnvironments = (platformSettings: Platform[]) => {
  const environments = [];

  Object.keys(platformSettings).forEach((platformKey) => {
    const platform = platformSettings[platformKey];
    Object.keys(platform).forEach((environment) => {
      if (environment !== 'pushSettings') {
        environments.push(environment);
      }
    });
  });

  const uniq = [...new Set(environments)];
  uniq.unshift('All');

  return uniq ?? [];
};

export const getActivePlatforms = (platformSettings: Platform[]) => {
  return ['All', ...Object.keys(platformSettings)];
};
