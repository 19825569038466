export const findParentBySelector = (elm, selector) => {
  const collectionHas = function (a, b) {
    for (let i = 0, len = a.length; i < len; i++) {
      if (a[i] === b) {
        return true;
      }
    }

    return false;
  };

  const all = document.querySelectorAll(selector);
  let cur = elm.parentNode;

  while (cur && !collectionHas(all, cur)) {
    cur = cur.parentNode;
  }

  return cur;
};
