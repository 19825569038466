import React, { ChangeEvent, useCallback, useState } from 'react';
import text from '../../../shared/translations/en';

interface IProps {
  delay: number;
  saveHandler: (value: string) => void;
}

export function Slider({ delay, saveHandler }: IProps) {
  const [value, setValue] = useState<string>(delay.toString());
  const [editing, setEditing] = useState<boolean>(false);

  const changeHandler = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    setEditing(true);
  }, []);

  const closeHandler = useCallback(() => {
    setValue(delay.toString());
    setEditing(false);
  }, [delay]);

  const handleOnSaveClick = useCallback(() => {
    setEditing(false);
    saveHandler(value);
  }, [value]);

  return (
    <div className="slider-wrapper u-margin-top-42">
      <p className="slider__title">
        {text.en.delay} ({value}s)
        {editing ? (
          <span className="u-pull-right" onClick={closeHandler}>
            <i className="icon icon__close icon-16-003-close-x" />
          </span>
        ) : null}
      </p>
      <input
        type="range"
        className="slider__input"
        min="0"
        max="15"
        step="5"
        value={value}
        onChange={changeHandler}
      />
      {editing ? (
        <button
          className="sr-btn sr-btn__md btn-default u-btn-right u-margin-top-12"
          onClick={handleOnSaveClick}
        >
          Save
        </button>
      ) : null}
    </div>
  );
}
