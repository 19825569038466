import React, { Component } from 'react';
import Toolbar from '../../components/Navigation/Toolbar/Toolbar.tsx';
import Notification from '../../components/Notification/Notification';
import { connect } from 'react-redux';
import { getNotifications } from '../../store/reducers/notifications';

class Layout extends Component {
  render() {
    const { notifications } = this.props;
    const notificationVisibleCn = notifications.length
      ? ' srm_notification'
      : '';

    return (
      <React.Fragment>
        <div className="layout__container">
          <header className="layout__header">
            <Toolbar />
          </header>
          <Notification />
          <div className={`layout__content ${notificationVisibleCn}`}>
            {this.props.children}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    notifications: getNotifications(state),
  };
};

export default connect(mapStateToProps)(Layout);
