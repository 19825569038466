import React from 'react';

export function Jurisdiction(): JSX.Element {
  return (
    <>
      <h2>15. Jurisdiction and Governing law</h2>
      <p>
        Sportradar makes no representations that the material and information
        accessible through the Portal or the SDK are correct, appropriate or
        available in all national locations or languages.
      </p>
      <p>
        You and the Sportradar agree that any controversy or claim arising from
        or pertaining to the use of this website and/or application shall be
        governed by Swiss law and be submitted to the exclusive jurisdiction of
        the courts of Switzerland.
      </p>
    </>
  );
}
