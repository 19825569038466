import React, { useEffect, useState } from 'react';
import { ConfigThree } from '../../../shared/types/pushSettings';
import text from '../../../shared/translations/en';

interface IProps {
  onFormSubmit: (config: ConfigThree) => void;
}

export function FormThree({ onFormSubmit }: IProps) {
  const [configObj, setConfigObj] = useState<ConfigThree>({
    includeCategoryId: false,
    includeTournamentId: false,
    includeHomeTeamId: false,
    includeAwayTeamId: false,
  });
  const [items, setItems] = useState<JSX.Element[]>([]);

  const handleFormSubmit = () => {
    onFormSubmit(configObj);
  };

  const onSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const identifier = e.target.name;
    const value = e.target.value;
    setConfigObj({
      ...configObj,
      [identifier]: JSON.parse(value),
    });
  };

  useEffect(() => {
    if (configObj) {
      let selectField = null;
      const currItems = [];
      Object.keys(configObj).forEach((item) => {
        selectField = (
          <div key={item} className="modifiers-form-3__wrapper">
            <div className="box--left">
              <p>{item}</p>
            </div>
            <div className="box--right">
              <select className="select" onChange={onSelectChange} name={item}>
                <option value="false">{text.en.false}</option>
                <option value="true">{text.en.true}</option>
              </select>
            </div>
          </div>
        );
        currItems.push(selectField);
      });
      setItems(currItems);
    }
  }, [configObj]);

  return (
    <div className="input-row__wrapper">
      {items}
      <button
        className="sr-btn sr-btn__md btn-dark-blue u-margin-top-18 u-btn-right"
        onClick={handleFormSubmit}
      >
        {text.en.submit}
      </button>
    </div>
  );
}
