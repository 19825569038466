import React from 'react';

/**
 * Usage of filters component
 *
 * Available props:
 *
 *  title: 'Environments',
 *  align: 'left' | 'right' | 'center',
 *  items: ['Item1', 'Item2']
 *  selectedItems: ['Item1'],
 *  onClick: function(item)
 *
 * */

interface IProps {
  title: string;
  align: 'left' | 'right' | 'center';
  items: string[];
  selectedItems: string[];
  onClick: (item: string) => void;
}

export function Filters({
  title,
  align,
  items,
  selectedItems,
  onClick,
}: IProps): JSX.Element {
  return (
    <div className={`settings-table-filters srm-${align}`}>
      <div className={'settings-table-filters-title'}>{title}</div>
      <div className={'settings-table-filters-items'}>
        {items.map((item: string, ix: number) => (
          <div
            key={`${item}-${ix}`}
            className={`settings-table-filters-item ${
              selectedItems.indexOf(item) > -1 ? ' srm-selected' : ''
            }`}
            onClick={() => onClick(item)}
          >
            {item}
          </div>
        ))}
      </div>
    </div>
  );
}
