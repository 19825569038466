import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';
import { ApplicationState } from '../../shared/types/store';
import { Application } from '../../shared/types/application';

const initialState: ApplicationState = {
  response: {
    applications: [],
    totalCount: 0,
  },
  loading: false,
  error: false,
  success: false,
  errorObj: {},
  accessKey: '',
  canEdit: false,
  singleApp: {} as Application,
};

const fetchAppsStart = (state) => {
  return updateObject(state, {
    loading: true,
    error: false,
  });
};

const fetchAppsSuccess = (state, action) => {
  return updateObject(state, {
    response: action.applications,
    loading: false,
    error: false,
  });
};

const fetchAppsFail = (state) => {
  return updateObject(state, {
    applications: [],
    error: true,
  });
};

const fetchAppFail = (state, action) => {
  return updateObject(state, {
    error: action.errorCode,
  });
};

const fetchAppStart = (state) => {
  return updateObject(state, {
    loading: true,
    error: false,
    singleApp: {},
  });
};

const fetchAppSuccess = (state, action) => {
  const { application, user } = action.payload;

  // get the owner
  const owner = application.users.find(
    (currentUser) => currentUser.role === 'Owner'
  );
  const developers = application.users.map(
    (currentUser) => currentUser.role === 'Developer'
  );

  const canEdit =
    developers?.some((developer) => developer.email === user.email) ||
    owner?.email === user.email;

  // update the state
  return updateObject(state, {
    loading: false,
    error: false,
    canEdit,
    singleApp: { ...application, ownerEmail: owner?.email },
  });
};

const uploadStart = (state) => {
  return updateObject(state, {
    loading: true,
    notificationHistoryReport: [],
    success: false,
  });
};

const uploadSuccess = (state) => {
  return updateObject(state, {
    loading: false,
    error: false,
    success: true,
    errorObj: {},
  });
};

const uploadFail = (state) => {
  return updateObject(state, { error: true, success: false });
};

const uploadIconSuccess = (state) => {
  return updateObject(state, { error: false, loading: false });
};

const uploadIconFail = (state) => {
  return updateObject(state, { error: true, loading: false });
};

const uploadApnsCertificateFail = (state, action) => {
  return updateObject(state, { error: true, errorObj: action.errorObj });
};

const cancelError = (state) => {
  return updateObject(state, { error: false });
};

// ACCESS KEY
const fetchApiAccessKeyStart = (state) => {
  return updateObject(state, { loading: true, error: false });
};

const fetchApiAccessKeySuccess = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: false,
    accessKey: action.accessKey,
  });
};

const fetchApiAccessKeyFail = (state) => {
  return updateObject(state, { loading: false, error: true });
};

const updateSingleAppSettings = (state, action) => {
  const { settings } = action.payload;

  return {
    ...state,
    singleApp: {
      ...state.singleApp,
      settings,
    },
  };
};

const removeUserInvitation = (state, action) => {
  return {
    ...state,
    singleApp: {
      ...state.singleApp,
      invites: state.singleApp.invites.filter(
        (user) => user.email !== action.payload.email
      ),
    },
  };
};

const updateSingleAppAdminSettings = (state, action) => {
  const { settings } = action.payload;

  return {
    ...state,
    singleApp: {
      ...state.singleApp,
      adminSettings: settings,
    },
  };
};

const applications = (state, action) => {
  if (typeof state === 'undefined') {
    return initialState;
  }

  switch (action.type) {
    case actionTypes.FETCH_APPLICATIONS_START:
      return fetchAppsStart(state);
    case actionTypes.FETCH_APPLICATIONS_SUCCESS:
      return fetchAppsSuccess(state, action);
    case actionTypes.FETCH_APPLICATIONS_FAIL:
      return fetchAppsFail(state);
    case actionTypes.FETCH_APP_START:
      return fetchAppStart(state);
    case actionTypes.FETCH_APP_SUCCESS:
      return fetchAppSuccess(state, action);
    case actionTypes.FETCH_APP_FAIL:
      return fetchAppFail(state, action);
    case actionTypes.UPLOAD_START:
      return uploadStart(state);
    case actionTypes.UPLOAD_SUCCESS:
      return uploadSuccess(state);
    case actionTypes.UPLOAD_FAIL:
      return uploadFail(state);
    case actionTypes.CANCEL_ERROR:
      return cancelError(state);
    case actionTypes.UPLOAD_APNS_CERTIFICATE_FAIL:
      return uploadApnsCertificateFail(state, action);
    case actionTypes.FETCH_API_ACCESS_KEY_START:
      return fetchApiAccessKeyStart(state);
    case actionTypes.FETCH_API_ACCESS_KEY_SUCCESS:
      return fetchApiAccessKeySuccess(state, action);
    case actionTypes.FETCH_API_ACCESS_KEY_FAIL:
      return fetchApiAccessKeyFail(state);
    case actionTypes.UPLOAD_ICON_SUCCESS:
      return uploadIconSuccess(state);
    case actionTypes.UPLOAD_ICON_FAIL:
      return uploadIconFail(state);
    case actionTypes.UPDATE_SETTINGS_SUCCESS:
      return updateSingleAppSettings(state, action);
    case actionTypes.UPDATE_ADMIN_SETTINGS_SUCCESS:
      return updateSingleAppAdminSettings(state, action);
    case actionTypes.REMOVE_INVITATION:
      return removeUserInvitation(state, action);
    default:
      return state;
  }
};

export default applications;
