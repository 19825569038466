import React from 'react';
import { ContactIcon } from './ContactIcon';

interface IProps {
  url: string;
  provider: string;
  icon: JSX.Element;
}

export function SocialIcon({ url, provider, icon }: IProps): JSX.Element {
  return (
    <a
      title={`Visit our ${provider} page`}
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className="sr-footer__social-media--link"
    >
      <ContactIcon title={provider} icon={icon} />
    </a>
  );
}
