export const HOTKEYS = {
  ArrowLeft: 'left',
  ArrowRight: 'right',
  ArrowUp: 'up',
  ArrowDown: 'down',
  Enter: 'enter',
  Escape: 'escape',
  left: 'ArrowLeft',
  right: 'ArrowRight',
  down: 'ArrowDown',
  up: 'ArrowUp',
  enter: 'Enter',
  escape: 'Escape',
};

export const ApplicationJsonCharsetUTF8 = 'application/json;charset=UTF-8';
export const ContentTypeMultiFormData = 'multipart/form-data';
