import axiosPNInstance from '../../axiosPNInstance';
import * as actionTypes from './actionTypes';
import { text } from '../../shared/translations/en-v1';
import { toastr } from 'react-redux-toastr';
const CryptoJS = require('crypto-js');

export const fetchSubscriptionsStart = () => {
  return { type: actionTypes.FETCH_SUBSCRIPTIONS_START };
};
export const fetchSubscriptionsSuccess = (subscriptions) => {
  return {
    type: actionTypes.FETCH_SUBSCRIPTIONS_SUCCESS,
    subscriptions,
  };
};

export const fetchSubscriptionsFail = () => {
  return { type: actionTypes.FETCH_SUBSCRIPTIONS_FAIL };
};

const generateSignatureHash = (obj) => {
  let url;

  if (obj.method === 'GET') {
    url = `${obj.nonce}${obj.appId}${obj.method}/api/v3/${obj.path}`;
  } else if (obj.method === 'POST') {
    url = `${obj.nonce}${obj.appId}${obj.method}/api/v3/${obj.path}${obj.data}`;
  }
  const hash = CryptoJS.HmacSHA256(url, obj.apiKey);
  const hashString = hash.toString(CryptoJS.enc.Base64);

  return { hashString };
};

export const getChannelSubscriptions = (obj) => {
  const newObj = {
    method: 'GET',
    appId: obj.appId,
    apiKey: obj.apiKey,
    nonce: new Date().getTime(),
    path: 'channelSubscriptions',
  };
  const accesSignature = generateSignatureHash(newObj).hashString;

  return (dispatch) => {
    axiosPNInstance
      .get('channelSubscriptions', {
        headers: {
          'APP-KEY': newObj.appId,
          'ACCESS-NONCE': newObj.nonce,
          'ACCESS-SIGN': accesSignature,
        },
      })
      .then((response) => dispatch(fetchSubscriptionsSuccess(response.data)))
      .catch(() => dispatch(fetchSubscriptionsFail()));
  };
};

export const createCustomNotification = (app, body) => {
  const newObj = {
    method: 'POST',
    appId: app.appId,
    apiKey: app.apiKey,
    nonce: new Date().getTime(),
    path: 'customAttributedNotification',
    data: JSON.stringify(body),
  };

  const accesSignature = generateSignatureHash(newObj).hashString;

  return () => {
    axiosPNInstance
      .post('customAttributedNotification', newObj.data, {
        headers: {
          'Content-type': 'application/json',
          'APP-KEY': newObj.appId,
          'ACCESS-NONCE': newObj.nonce,
          'ACCESS-SIGN': accesSignature,
        },
      })
      .then(() => {
        toastr.success(text.success, text.notificationText);
      })
      .catch(() => {
        toastr.error(text.error, text.genericError);
      });
  };
};

export const pnReportStart = () => {
  return { type: actionTypes.PN_REPORT_START };
};
export const pnReportFail = () => {
  return { type: actionTypes.PN_REPORT_FAIL };
};
export const pnReportSuccess = (report) => {
  return { type: actionTypes.PN_REPORT_SUCCESS, report };
};

export const getReport = (app) => {
  const newObj = {
    method: 'GET',
    appId: app.appId,
    apiKey: app.apiKey,
    nonce: new Date().getTime(),
    path: 'customNotification',
  };
  const accesSignature = generateSignatureHash(newObj).hashString;
  return (dispatch) => {
    dispatch(pnReportStart());
    axiosPNInstance
      .get('customNotification', {
        headers: {
          'APP-KEY': newObj.appId,
          'ACCESS-NONCE': newObj.nonce,
          'ACCESS-SIGN': accesSignature,
        },
      })
      .then((response) => {
        dispatch(pnReportSuccess(response.data));
      })
      .catch(() => {
        dispatch(pnReportFail());
      });
  };
};

// demo push notification
export const sendDemoPn = (app, body, newGen) => {
  // In case old demo notification wont be used we can remove this newGen
  const url = newGen
    ? 'demoNotification'
    : `demoNotification/${body.subscriptionType}`;
  const newObj = {
    method: 'POST',
    appId: app.appId,
    apiKey: app.apiKey,
    nonce: new Date().getTime(),
    path: url,
    data: JSON.stringify(body),
  };

  const accesSignature = generateSignatureHash(newObj).hashString;

  return () => {
    axiosPNInstance
      .post(`${newObj.path}`, newObj.data, {
        headers: {
          'Content-type': 'application/json',
          'APP-KEY': newObj.appId,
          'ACCESS-NONCE': newObj.nonce,
          'ACCESS-SIGN': accesSignature,
        },
      })
      .then(() => {
        toastr.success(text.success, text.notificationSent);
      })
      .catch((error) => {
        let textMessage = text.genericError;
        if (error.response && error.response.data) {
          textMessage = error.response.data.message;
        }
        toastr.error(text.error, textMessage);
      });
  };
};
