import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import text from '../../../../shared/translations/en';
import {
  acceptInvite,
  rejectInvite,
} from '../../../../store/actions/applications';

interface IProps {
  email: string;
  appId: string;
}

export function Invites({ email, appId }: IProps): JSX.Element {
  const dispatch = useDispatch();

  const handleAcceptInvitation = useCallback(() => {
    dispatch(acceptInvite(appId));
  }, []);

  const handleRejectInvitation = useCallback(() => {
    dispatch(rejectInvite(appId, email));
  }, []);

  return (
    <div className="u-margin-top-20">
      <button
        className="btn-green sr-btn invite-button"
        onClick={handleAcceptInvitation}
      >
        {text.en.accept}
      </button>
      <button
        className="btn-red sr-btn invite-button"
        onClick={handleRejectInvitation}
      >
        {text.en.reject}
      </button>
    </div>
  );
}
