import React from 'react';
import _ from 'lodash';
import { Device, DeviceColumns } from '../../shared/types/device';
import { DeviceColumn } from './DeviceColumn';
import { PushSubscriptions } from './PushSubscriptions';
import { PushTokenActions } from './PushTokenActions';
import { DeviceActions } from './DeviceActions';

interface IProps {
  device: Device;
  handleRemoveDevice: (device: Device) => void;
  handleConfirmDevice: (device: Device) => void;
  handleEditDevice: (device: Device) => void;
  showColumn: (column: string) => boolean;
  columns: DeviceColumns;
  confirmed: boolean;
}

export function DeviceItem({
  device,
  handleRemoveDevice,
  handleConfirmDevice,
  handleEditDevice,
  showColumn,
  columns,
  confirmed,
}: IProps): JSX.Element {
  const hasPushToken = device && device.pushToken;
  const pushTokenText = hasPushToken ? 'Yes' : 'No';
  const pushBadge = hasPushToken ? ' srm-green' : '';

  return (
    <div className="app-devices-table-body-row">
      {showColumn(columns.DEVICE_NAME) ? (
        <span
          className="srm-device-name"
          onClick={() => handleEditDevice(device)}
        >
          {device.name}
          {confirmed && <i className="icon icon-16-006-edit srm-link-color" />}
        </span>
      ) : null}
      {showColumn(columns.DEVICE_ID) && <DeviceColumn columnText={device.id} />}
      {showColumn(columns.PLATFORM) && (
        <DeviceColumn columnText={device.platform} />
      )}
      {showColumn(columns.MODE) && (
        <DeviceColumn columnText={device.modeType} />
      )}
      {showColumn(columns.PUSH_TOKEN) && (
        <PushTokenActions
          device={device}
          pushTokenText={pushTokenText}
          pushBadge={pushBadge}
          hasPushToken={hasPushToken}
        />
      )}
      {showColumn(columns.SUBSCRIPTIONS) && (
        <PushSubscriptions device={device} />
      )}
      {showColumn(columns.LANGUAGE) && (
        <DeviceColumn columnText={_.get(device, 'lang', '')} />
      )}
      {showColumn(columns.ACTIONS) && (
        <DeviceActions
          confirmed={confirmed}
          handleRemoveDevice={handleRemoveDevice}
          handleConfirmDevice={handleConfirmDevice}
          device={device}
        />
      )}
    </div>
  );
}
