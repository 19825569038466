import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import text from '../../shared/translations/en';
import { addDays } from '../../utils/Date';
import { useDispatch } from 'react-redux';
import * as actions from '../../store/actions';
import { getPlans } from '../../store/actions/plan.js';
import { LoadingComponent } from '../../components/UI/LoadingComponent';
import { DateInput } from '../../components/DateInput/DateInput';
import { UserRegister } from '../../shared/types/user';

export function AddUserModal(): JSX.Element {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();

  const [expiryDate, setExpiryDate] = useState<Date>(addDays(new Date(), 30));
  const [plans, setPlans] = useState<string[]>([]);
  const [currentPlan, setCurrentPlan] = useState<string>('Trial');

  useEffect(() => {
    getPlans().then((response: string[]) => setPlans(response));
  }, []);

  const onSubmit = useCallback(
    (data: UserRegister) => {
      dispatch(
        actions.register({
          ...data,
          expiryDate: new Date(expiryDate).getTime(),
          salesRepresentative:
            data.salesRepresentative !== ''
              ? data.salesRepresentative
              : undefined,
        })
      );
    },
    [expiryDate]
  );

  return (
    <form
      className={'mdp-admin-add-user sr-forms'}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="col-1-of-2">
        <div className="input-row__wrapper u-margin-top-20">
          <label className="input-row__label">{text.en['user-email']}</label>
          <div className="input-row__input-contanier">
            <span className="input-icon">
              <i className="icon-16-006-edit" />
            </span>
            <input
              {...register('email', { required: true })}
              type="text"
              className="input-row__input u-grey-bg"
            />
            {errors['email'] && (
              <div className="input-error">
                <span className={'input-error-icon'}>!</span>
                {text.en['email-error']}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="col-1-of-2">
        <div className="input-row__wrapper u-margin-top-28">
          <label className="input-row__label">
            {text.en['sales-representative-email']}
          </label>
          <div className="input-row__input-contanier">
            <span className="input-icon">
              <i className="icon-16-006-edit" />
            </span>
            <input
              {...register('salesRepresentative')}
              type="text"
              className="input-row__input u-grey-bg"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-1-of-2 u-margin-top-20">
          <DateInput
            selectedDate={expiryDate}
            selectedPlan={currentPlan}
            onDateChange={setExpiryDate}
          />
        </div>
        {/* Plan */}
        <div className="col-1-of-2 u-margin-top-20">
          <div className="input-row__wrapper">
            <label htmlFor="expiryDate" className="input-row__label">
              {text.en['plan']}
            </label>
            {!plans.length ? (
              <LoadingComponent />
            ) : (
              <select
                id="plans"
                className="select"
                {...register('plan', {
                  onChange: (e) => setCurrentPlan(e.target.value),
                })}
              >
                {plans.map((plan) => (
                  <option value={plan} key={plan}>
                    {plan}
                  </option>
                ))}
              </select>
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <button
          type="submit"
          className="mdp-admin-add-user-btn-add sr-btn sr-btn__md btn-default u-btn-right"
        >
          <i className="icon-16-031-user-follow" />
          {text.en['add-user']}
        </button>
      </div>
    </form>
  );
}
