import React from 'react';

export function DeveloperCredentials(): JSX.Element {
  return (
    <>
      <h2>7. Credentials of the developer</h2>
      <p>
        You agree that you are responsible for maintaining the confidentiality
        of any developer credentials that may be issued to you by Sportradar or
        which you may choose yourself and that you will be solely responsible
        for all applications that are developed under your developer
        credentials.
      </p>
    </>
  );
}
