import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import text from '../../../shared/translations/en';
import { getDocumentationUrl } from '../../../utils/Documentation';

export function UnAuthNav(): JSX.Element {
  return (
    <Fragment>
      <a
        href={getDocumentationUrl()}
        target="_blank"
        className="navigation--btn"
      >
        <span>
          <i className="icon-16-144-menu-square" />
        </span>
        {text.en.docs}
      </a>
      <NavLink
        to="/login"
        className={({ isActive }) =>
          `navigation--btn ${isActive ? 'nav-active' : ''}`
        }
      >
        <span>
          <i className="icon-16-187-enter" />
        </span>
        {text.en.login}
      </NavLink>
    </Fragment>
  );
}
