import React, { Fragment } from 'react';
import { Application } from '../../shared/types/application';
import { AppUser } from './AppUser';
import { PendingUser } from './PendingUser';

interface IProps {
  app: Application;
}

export function AppUsers({ app }: IProps): JSX.Element {
  return (
    <React.Fragment>
      {app.users &&
        app.users.map((user) => (
          <Fragment key={user.email}>
            <AppUser user={user} appId={app.appId ?? app.id} />
          </Fragment>
        ))}
      {app.invites &&
        app.invites.map((user) => (
          <Fragment key={user.email}>
            <PendingUser email={user.email} appId={app.appId ?? app.id} />
          </Fragment>
        ))}
    </React.Fragment>
  );
}
