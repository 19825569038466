import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';
import { ModalState } from '../../shared/types/store';

const initialState: ModalState = {
  uploadSuccess: null,
  modalErrorObj: {
    field: null,
    message: null,
  },
};

const hideModal = (state) => {
  return updateObject(state, {
    uploadSuccess: null,
    modalErrorObj: updateObject(state.modalErrorObj, {
      field: null,
      message: null,
    }),
  });
};

const modalError = (state, action) => {
  return updateObject(state, {
    modalErrorObj: updateObject(state.modalErrorObj, {
      field: action.payload.field,
      message: action.payload.message,
    }),
  });
};

const modalUploadSuccess = (state) => {
  return updateObject(state, {
    uploadSuccess: true,
  });
};

const modals = (state, action) => {
  if (typeof state === 'undefined') {
    return initialState;
  }

  switch (action.type) {
    case actionTypes.CLOSE_MODAL:
      return hideModal(state);
    case actionTypes.MODAL_ERROR:
      return modalError(state, action);
    case actionTypes.MODAL_UPLOAD_SUCCESS:
      return modalUploadSuccess(state);
    default:
      return state;
  }
};

export default modals;
