import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import { selectUser } from '../../store/selectors/user';
import text from '../../shared/translations/en';
import { Footer } from '../../components/Footer/Footer';
import {
  PdsEyePrivacyViewIcon,
  PdsEyePublicViewIcon,
} from '@podium-design-system/react-icons';
import { selectConfig } from 'store/selectors/config';
import { setConfig } from '../../shared/utility';
import srLogo from '../../assets/images/SR_logo_basic.png';
import { Auth as AwsAuth } from 'aws-amplify';
import { toastr } from 'react-redux-toastr';
import { setTemporaryUser } from '../../store/actions/user.js';
import useAmplifyAuth from '../../utils/useAmplifyAuth';

export const Auth = () => {
  useAmplifyAuth();
  const dispatch = useDispatch();

  const [passwordShown, setPasswordShown] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [redirect, setRedirect] = useState<string>();

  const user = useSelector(selectUser);
  const config = useSelector(selectConfig);

  const togglePassword = useCallback(() => {
    setPasswordShown(!passwordShown);
  }, [passwordShown]);

  const handleLogin = useCallback(async () => {
    try {
      setConfig('customer', config);

      const signInUser = await AwsAuth.signIn({ username: email, password });

      if (signInUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
        dispatch(setTemporaryUser(signInUser));
        setRedirect('/password/set');
      }
    } catch (e: unknown) {
      toastr.error((e as Error).message);
    }
  }, [email, password]);

  if (user) {
    return <Navigate to="/applications/my" />;
  }

  if (redirect) {
    return <Navigate to={redirect} />;
  }

  return (
    <main className="layout__main sr-forms">
      <div className="sr-forms-content">
        <div
          data-test="login-component"
          className="login-form sr-forms-component"
        >
          <div className="sr-forms-title">
            <span className="sr-logo">
              <img src={srLogo} alt="Sportradar" />
            </span>
            <span className="sr-forms-mdp-brand">MDP</span>
          </div>
          <div className="sr-forms-form">
            <div className={'sr-forms-input-container'}>
              <h3>{text.en.login}</h3>
              <label>{text.en.email}*</label>
              <input
                type="email"
                className={`input ${email.length ? '' : 'xm-empty'}`}
                data-test="input-email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="u-margin-top-28">
              <div className={'sr-forms-input-container'}>
                <label className="u-margin-top-8">{text.en.password}*</label>
                <input
                  type={passwordShown ? 'text' : 'password'}
                  className={`input ${password.length ? '' : 'srm-empty'}`}
                  data-test="input-password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <div className="toggle-password" onClick={togglePassword}>
                  {passwordShown ? (
                    <PdsEyePrivacyViewIcon />
                  ) : (
                    <PdsEyePublicViewIcon />
                  )}
                </div>
              </div>
            </div>
            <div className="u-right-text u-margin-top-32">
              <button
                className="sr-btn sr-btn__lg u-btn-right btn-dark-blue"
                data-test="login-button"
                onClick={handleLogin}
              >
                {text.en.login}
              </button>
            </div>
          </div>
        </div>
        <div className={'sr-forms-links'}>
          <Link to="/register">{text.en.register}</Link>
          <Link to="/password/forgot">{text.en['lost-pass']}?</Link>
          <Link to="/sso-login">{text.en['sso-login']}</Link>
        </div>
      </div>
      <div className="layout__footer">
        <Footer />
      </div>
    </main>
  );
};
