import React, { useCallback, useState } from 'react';
import text from '../../shared/translations/en';
import { addDays, format } from 'date-fns';
import {
  PdsCalendarIcon,
  PdsClose2Icon,
} from '@podium-design-system/react-icons';
import { DayPicker } from 'react-day-picker';

import 'react-day-picker/dist/style.css';
import './DateInput.scss';

const DATEPICKER_FORMAT = 'dd/MM/yyyy';

interface IProps {
  selectedDate: Date;
  onDateChange: (date: Date) => void;
  selectedPlan: string;
}

export function DateInput({
  selectedDate,
  onDateChange,
  selectedPlan,
}: IProps) {
  const [showCalendar, setShowCalendar] = useState<boolean>(false);

  const handleChangeDate = (date: Date) => {
    onDateChange(date);
    setShowCalendar(false);
  };

  const handleShowCalendar = useCallback(
    (show: boolean) => {
      if (selectedPlan !== 'Trial') {
        setShowCalendar(show);
      }
    },
    [selectedPlan]
  );

  return (
    <div className="input-row__wrapper">
      <label className="input-row__label">{text.en['expiry-date']}</label>
      <div className="input-row__input-contanier date-group">
        <input
          value={format(selectedDate, DATEPICKER_FORMAT)}
          className="input-row__input date_input"
          onClick={() => setShowCalendar(true)}
          disabled={selectedPlan === 'Trial'}
        />
        {!showCalendar ? (
          <PdsCalendarIcon
            fixedSize="24px"
            onClick={() => handleShowCalendar(true)}
          />
        ) : (
          <PdsClose2Icon
            fixedSize="24px"
            onClick={() => handleShowCalendar(false)}
          />
        )}
      </div>
      {showCalendar && (
        <div className="day-picker-wrapper">
          <DayPicker
            mode="single"
            fromDate={addDays(new Date(), 30)}
            selected={selectedDate}
            onSelect={handleChangeDate}
            disabled={selectedPlan === 'Trial'}
          />
        </div>
      )}
    </div>
  );
}
