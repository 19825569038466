export const DialogStyle = {
  label: { fontSize: '1.2rem' },
  textField: {
    fontSize: '1.4rem',
    marginBottom: 8,
    '& label': {
      fontSize: '1.2rem',
    },
  },
  title: {
    fontSize: '2rem',
    letterSpacing: '.05rem',
  },
  content: { fontSize: '1.6rem' },
  button: {
    fontSize: '1.2rem',
    marginRight: 10,
  },
  formHelperText: { fontSize: '1.2rem' },
};
