import React from 'react';
import text from '../../shared/translations/en';
import { Device } from '../../shared/types/device';

interface IProps {
  confirmed: boolean;
  handleRemoveDevice: (device: Device) => void;
  handleConfirmDevice: (device: Device) => void;
  device: Device;
}

export function DeviceActions({
  confirmed,
  handleConfirmDevice,
  handleRemoveDevice,
  device,
}: IProps): JSX.Element {
  return (
    <span>
      {confirmed ? (
        <span
          className="srm-link"
          title={text.en['remove-device']}
          onClick={() => handleRemoveDevice(device)}
        >
          <i className="icon icon-16-079-trash" />
        </span>
      ) : (
        <span className="srm-link" onClick={() => handleConfirmDevice(device)}>
          <i className="icon icon-16-376-success-outline2" />
          {text.en['confirm-device']}
        </span>
      )}
    </span>
  );
}
