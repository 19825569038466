import React from 'react';
import text from '../../shared/translations/en';
import { PlatformEnum } from '../../shared/types/application';
import { PlatformItem } from '../../shared/types/platform';

interface IProps {
  items: PlatformItem[];
  activeItem: PlatformEnum;
  hadnleOnClick: (name: PlatformEnum) => void;
}

export function ContentTabs({ items, activeItem, hadnleOnClick }: IProps) {
  return (
    <div className={`mdp-nav srm-sub-with-border`}>
      <div className="mdp-nav-wrap">
        {items.map((item: PlatformItem) => {
          const isActive = activeItem === item.name;
          const activeCn = isActive ? ' srm-active' : '';

          return (
            <div
              className={`mdp-nav-item ${activeCn} is-${item.name}`}
              key={item.name}
              onClick={() => hadnleOnClick(item.name)}
            >
              <div className="mdp-nav-item-title">
                {text.en[item.translationKey] || item.name}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
