import { isMobile, isOS } from './Utils';

let textArea;

function copy(text: string) {
  createTextArea(text);
  selectText();
  copyToClipboard(text);
}

function createTextArea(text: string) {
  textArea = document.createElement('textArea');
  textArea.value = text;
  document.body.appendChild(textArea);
}

function selectText() {
  let range: Range, selection: Selection;

  if (isOS() || isMobile()) {
    range = document.createRange();
    range.selectNodeContents(textArea);
    selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    textArea.setSelectionRange(0, 999999);
  } else {
    textArea.select();
  }
}

function copyToClipboard(text: string) {
  function listener(e) {
    e.clipboardData.setData('text/html', text);
    e.clipboardData.setData('text/plain', text);
    e.preventDefault();
  }

  document.addEventListener('copy', listener);
  document.removeEventListener('copy', listener);

  document.body.removeChild(textArea);
}

export { copy };
