import { get } from 'lodash';
import React, { FormEvent } from 'react';
import { Cell, Column } from '../../shared/types/table';

interface IProps {
  cell: Cell;
  column: Column;
  data: Column[];
  index: number;
  onCellClick: (column: Column, index: number) => void;
  onCellInputChange: (
    column: Column,
    cell: Cell,
    index: number,
    value: unknown
  ) => void;
}

export function TableCell({
  cell,
  column,
  data,
  index,
  onCellClick,
  onCellInputChange,
}: IProps): JSX.Element {
  const placeholderCn = cell.placeholder ? ' srm-placeholder' : '';
  const showInput = cell.activeInput;
  const showInputCn = showInput ? ' srm-show-input' : '';
  const isLastCell = index === column.values.length - 1;
  const prevCellKey = data[0].values[index - 1]
    ? data[0].values[index - 1].value
    : '';
  const prevCellDefaultValue = data[1].values[index - 1]
    ? data[1].values[index - 1].value
    : '';
  const currentRowKeyValue = data[0].values[index].value;
  const noHoverCn =
    (column.id !== 'key' && cell.value === '' && currentRowKeyValue === '') ||
    (column.id === 'key' &&
      isLastCell &&
      column.values.length > 1 &&
      (prevCellDefaultValue === '' || prevCellKey === ''))
      ? ' srm-no-hover'
      : '';

  return (
    <div
      key={`cell-iy-${index}`}
      className={`settings-table-cell ${showInputCn} ${noHoverCn}`} /*emptyDefaultValueCn*/
      onClick={() => onCellClick(column, index)}
    >
      {showInput ? (
        <input
          type={'text'}
          className={'settings-table-cell-input'}
          value={get(cell, 'value', '')}
          onChange={(e: FormEvent<HTMLInputElement>) =>
            onCellInputChange(column, cell, index, e.currentTarget.value)
          }
          autoFocus
        />
      ) : (
        <span className={`settings-table-cell-text ${placeholderCn}`}>
          {get(cell, 'value', get(cell, 'placeholder', ''))}
        </span>
      )}
    </div>
  );
}
