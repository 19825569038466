import React, { useEffect, useState } from 'react';
import { CustomInput } from '../../../components/UI/Input/CustomInput';
import { CheckboxDialog } from '../../UI/CheckboxDialog/CheckboxDialog';
import { CONFIG_TWO } from '../config';
import text from '../../../shared/translations/en';
import {
  ConfigOne,
  ConfigTwo,
  EventType,
} from '../../../shared/types/pushSettings';
import { useSelector } from 'react-redux';
import { selectCreatePushModifierSuccess } from '../../../store/selectors/pushModifiers';

interface IProps {
  configType: string;
  onFormSubmit: (config: ConfigOne | ConfigTwo) => void;
}

const initialObj: ConfigOne = {
  categoryIds: '',
  tournamentIds: '',
  matchIds: '',
  homeTeamIds: '',
  awayTeamIds: '',
  eventTypes: [],
};

export function FormOne({ configType, onFormSubmit }: IProps) {
  const [configObj, setConfigObj] = useState<ConfigOne>(initialObj);
  const [mapping, setMapping] = useState<string>('');

  const isRequestSucceded = useSelector(selectCreatePushModifierSuccess);

  const onFormOneInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputIdentifier = e.target.name;
    const inputValue = e.target.value;
    if (inputIdentifier === 'mapping') {
      setMapping(inputValue);
    } else {
      setConfigObj({ ...configObj, [inputIdentifier]: inputValue });
    }
  };

  const onAddEventType = (eventType: EventType) => {
    let eventTypes = [...configObj.eventTypes];

    if (!eventType.status && eventTypes.includes(eventType.name)) {
      eventTypes = eventTypes.filter((item) => item !== eventType.name);
    } else if (eventType.status && !eventTypes.includes(eventType.name)) {
      eventTypes.push(eventType.name);
    }

    setConfigObj({ ...configObj, eventTypes });
  };

  const handleOnFormSubmit = (type: string) => {
    Object.keys(configObj).forEach((key) => {
      let value = null;
      if (key === 'eventTypes') {
        value = configObj[key];
      } else if (configObj[key]) {
        if (!configObj[key].length) {
          value = configObj[key].split(',');
        }
      }
      configObj[key] = value;
    });
    //  second cofig have additional properties
    if (type === text.en[CONFIG_TWO]) {
      const secondCofig = {
        mappings: [{ condition: { ...configObj }, mapping }],
      };
      onFormSubmit(secondCofig);
    } else {
      onFormSubmit(configObj);
    }
  };

  useEffect(() => {
    if (isRequestSucceded) {
      setConfigObj(initialObj);
    }
  }, [isRequestSucceded]);

  return (
    <div className="input-row__wrapper">
      {configObj &&
        Object.keys(configObj).map((config) => {
          if (config === 'eventTypes') {
            return (
              <div key={config}>
                <label className="input-row__label">{config}</label>
                <p style={{ fontSize: '1.2rem', marginTop: 8 }}>
                  {configObj[config].join(', ')}
                </p>
              </div>
            );
          }
          return (
            <CustomInput
              key={config}
              label={config}
              changeHandler={onFormOneInputChange}
              identifier={config}
              inputValue={configObj[config]}
            />
          );
        })}
      {configObj && (
        <CheckboxDialog
          checkboxHandler={onAddEventType}
          onCancelCheckboxDialog={() =>
            setConfigObj({ ...configObj, eventTypes: [] })
          }
          checkedTypes={configObj.eventTypes}
        />
      )}
      {configType === text.en[CONFIG_TWO] ? (
        <CustomInput
          key={text.en.mapping}
          label={text.en.mapping}
          changeHandler={onFormOneInputChange}
          identifier={text.en.mapping}
          inputValue={mapping}
        />
      ) : null}
      <button
        className="sr-btn sr-btn__md btn-dark-blue u-margin-top-18 u-btn-right"
        onClick={() => handleOnFormSubmit(configType)}
      >
        {text.en.submit}
      </button>
    </div>
  );
}
