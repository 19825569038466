import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextInput } from '../../../components/UI/Input/TextInput';
import text from '../../../shared/translations/en';
import { Platform, PlatformEnum } from '../../../shared/types/application';
import { selectUser } from '../../../store/selectors/user';
import { updatePlatformCredentials } from '../../../store/actions/applications.js';

interface IProps {
  environment: string;
  data: Platform;
}

export function Credentials({ data, environment }: IProps): JSX.Element {
  const dispatch = useDispatch();

  const [value, setValue] = useState<string>(data.value ?? '');
  const [extra, setExtra] = useState<string>(data.extra ?? '');
  const [showButtons, setShowButtons] = useState<boolean>(false);

  const user = useSelector(selectUser);

  useEffect(() => {
    setValue(data.value);
    setExtra(data.extra);
  }, [data]);

  useEffect(() => {
    setShowButtons(
      (value !== data.value && value !== '') ||
        (extra !== data.extra && extra !== '')
    );
  }, [value, data, extra]);

  const resetFields = useCallback(() => {
    setValue(data.value);
    setExtra(data.extra);
  }, [data]);

  const handleUpdateCredentials = useCallback(() => {
    const body = {
      platform: PlatformEnum.SAFARI,
      mode: environment,
      credentials: value,
      extra: extra,
    };
    dispatch(updatePlatformCredentials(body, user));
    setShowButtons(false);
  }, [user, value, extra]);

  return (
    <div className="col-1-of-2" key={`safari-credentials-${environment}`}>
      {/* Bundle id */}
      <TextInput
        label={text.en['app-bundle-id']}
        onInputChange={(e: ChangeEvent<HTMLInputElement>) =>
          setValue(e.target.value)
        }
        inputValue={value ?? ''}
        inputName={text.en.value.toLowerCase()}
        inputPlaceholder={text.en['app-bundle-placeholder']}
        requiredField
      />
      {/* Allowed domains */}
      <TextInput
        label={text.en['allowed-domains']}
        onInputChange={(e: ChangeEvent<HTMLInputElement>) =>
          setExtra(e.target.value)
        }
        inputValue={extra ?? ''}
        inputName={text.en.extra}
        inputPlaceholder={text.en['allowed-domains-placeholder']}
        requiredField
      />
      {/*  buttons */}
      {showButtons ? (
        <div className="btn-wrapper">
          <button
            className="sr-btn sr-btn__sm btn-cancel"
            title={text.en.cancel}
            onClick={() => resetFields}
          >
            {text.en.cancel}
          </button>
          <button
            className="sr-btn sr-btn__sm btn-default"
            title={text.en.save}
            onClick={handleUpdateCredentials}
          >
            {text.en.save}
          </button>
        </div>
      ) : null}
    </div>
  );
}
