import React from 'react';
import ReactPaginate from 'react-paginate';

interface IProps {
  pages: number;
  changePage: (pageNumber: { selected: number }) => void;
  pageNumber: number;
  marginPagesDisplayed?: number;
  pageRangeDisplayed?: number;
}

export function Pagination({
  pages,
  changePage,
  pageNumber,
  marginPagesDisplayed = 5,
  pageRangeDisplayed = 5,
}: IProps) {
  return (
    <div id="react-paginate">
      <ReactPaginate
        previousLabel={<i className={'icon-16-008-arrow-l'} />}
        nextLabel={<i className={'icon-16-007-arrow-r'} />}
        breakLabel={<a href="">...</a>}
        breakClassName={'break-me'}
        pageCount={pages}
        marginPagesDisplayed={marginPagesDisplayed}
        pageRangeDisplayed={pageRangeDisplayed}
        onPageChange={changePage}
        containerClassName={'pagination'}
        activeClassName={'active'}
        forcePage={pageNumber}
      />
    </div>
  );
}
