import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';
import { DevicesState } from '../../shared/types/store';

const initialState: DevicesState = {
  loading: false,
  availableDevicesRequestsCount: 0,
  availableDevices: [],
  registeredDevices: [],
  selectedPlatforms: ['All'],
  selectedEnvironments: ['All'],
  qrCode: undefined,
  error: undefined,
  pushNotificationSent: false,
  sending: false,
};

const handleLoading = (state) => {
  return updateObject(state, {
    loading: true,
  });
};

const fetchAvailableDevicesStart = (state) => {
  return handleLoading(state);
};

const fetchAvailableDevicesSuccess = (state, action) => {
  return updateObject(state, {
    availableDevices: action.data,
    availableDevicesRequestsCount: action.data.length,
    loading: false,
  });
};

const fetchAvailableDevicesFail = (state) => {
  return updateObject(state, {
    loading: false,
  });
};

const fetchRegisteredDevicesStart = (state) => {
  return handleLoading(state);
};

const fetchRegisteredDevicesSuccess = (state, action) => {
  return updateObject(state, {
    registeredDevices: action.data,
    loading: false,
  });
};

const fetchRegisteredDevicesFail = (state) => {
  return updateObject(state, {
    loading: false,
    registeredDevices: [],
  });
};

const fetchDeviceDeleteSuccess = (state, action) => {
  return updateObject(state, {
    availableDevices: state.availableDevices.map((d) => {
      return d.id === action.data.deviceId ? { ...d, confirmed: true } : d;
    }),
    registeredDevices: state.registeredDevices.filter((d) => {
      return d.id !== action.data.deviceId;
    }),
  });
};

const fetchDeviceUpdateSuccess = (state, action) => {
  return updateObject(state, {
    registeredDevices: state.registeredDevices.map((d) => {
      return d.id === action.data.device.id
        ? { ...d, ...action.data.device, name: action.data.displayName }
        : d;
    }),
  });
};

const fetchDeviceRegisterSuccess = (state, action) => {
  const currentDevice = action.data.device;
  return updateObject(state, {
    loading: false,
    availableDevices: [
      ...state.availableDevices.filter((device) => {
        return device.id !== currentDevice.id;
      }),
    ],
    registeredDevices: state.registeredDevices.concat([currentDevice]),
  });
};

const clearDevices = (state) => {
  return updateObject(state, {
    loading: false,
    availableDevices: [],
    registeredDevices: [],
  });
};

const fetchDevicesStateSuccess = (state, action) => {
  const filterDevice = state.registeredDevices.filter(
    (device) => device.id === action.deviceId
  );
  const currentDevice = filterDevice ? filterDevice[0] : {};
  const updatedDevice = {
    ...currentDevice,
    state: action.data,
  };

  return updateObject(state, {
    registeredDevices: [
      ...state.registeredDevices.filter(
        (device) => device.id !== action.deviceId
      ),
      updatedDevice,
    ],
  });
};

const setSelectedPlatforms = (state, action) => {
  if (action.platform === 'All') {
    return updateObject(state, {
      selectedPlatforms: ['All'],
    });
  }

  if (state.selectedPlatforms[0] === 'All') {
    return updateObject(state, {
      selectedPlatforms: [action.platform],
    });
  }

  if (state.selectedPlatforms.includes(action.platform)) {
    if (state.selectedPlatforms.length === 1) {
      return updateObject(state, {
        selectedPlatforms: ['All'],
      });
    }
    return updateObject(state, {
      selectedPlatforms: state.selectedPlatforms.filter(
        (platform) => platform !== action.platform
      ),
    });
  }

  return updateObject(state, {
    selectedPlatforms: state.selectedPlatforms.concat([action.platform]),
  });
};

const setSelectedEnvironments = (state, action) => {
  if (action.environment === 'All') {
    return updateObject(state, {
      selectedEnvironments: ['All'],
    });
  }

  if (state.selectedEnvironments[0] === 'All') {
    return updateObject(state, {
      selectedEnvironments: [action.environment],
    });
  }

  if (state.selectedEnvironments.includes(action.environment)) {
    if (state.selectedEnvironments.length === 1) {
      return updateObject(state, {
        selectedEnvironments: ['All'],
      });
    }
    return updateObject(state, {
      selectedEnvironments: state.selectedEnvironments.filter(
        (environment) => environment !== action.environment
      ),
    });
  }

  return updateObject(state, {
    selectedEnvironments: state.selectedEnvironments.concat([
      action.environment,
    ]),
  });
};

const setQrCode = (state, action) => {
  return updateObject(state, {
    qrCode: action.qrCode,
  });
};

const sendPushNotificationStart = (state) => {
  return updateObject(state, {
    sending: true,
    error: undefined,
  });
};

const sendPushNotificationSuccess = (state) => {
  return updateObject(state, {
    pushNotificationSent: true,
    error: undefined,
    sending: false,
  });
};

const sendPushNotificationFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    sending: false,
  });
};

const resetPushNotificationSent = (state) => {
  return updateObject(state, {
    pushNotificationSent: false,
  });
};

const devices = (state, action) => {
  if (typeof state === 'undefined') {
    return initialState;
  }

  switch (action.type) {
    case actionTypes.FETCH_DEVICES_START:
      return fetchAvailableDevicesStart(state);
    case actionTypes.FETCH_DEVICES_SUCCESS:
      return fetchAvailableDevicesSuccess(state, action);
    case actionTypes.FETCH_DEVICES_FAIL:
      return fetchAvailableDevicesFail(state);
    case actionTypes.FETCH_REGISTERED_DEVICES_START:
      return fetchRegisteredDevicesStart(state);
    case actionTypes.FETCH_REGISTERED_DEVICES_SUCCESS:
      return fetchRegisteredDevicesSuccess(state, action);
    case actionTypes.FETCH_REGISTERED_DEVICES_FAIL:
      return fetchRegisteredDevicesFail(state);
    case actionTypes.FETCH_DEVICE_DELETE_SUCCESS:
      return fetchDeviceDeleteSuccess(state, action);
    case actionTypes.FETCH_DEVICE_UPDATE_SUCCESS:
      return fetchDeviceUpdateSuccess(state, action);
    case actionTypes.FETCH_DEVICE_REGISTER_SUCCESS:
      return fetchDeviceRegisterSuccess(state, action);
    case actionTypes.DEVICES_CLEAR:
      return clearDevices(state);
    case actionTypes.FETCH_DEVICES_STATE_SUCCESS:
      return fetchDevicesStateSuccess(state, action);
    case actionTypes.SET_SELECTED_PLATFORMS:
      return setSelectedPlatforms(state, action);
    case actionTypes.SET_SELECTED_ENVIRONMENTS:
      return setSelectedEnvironments(state, action);
    case actionTypes.SET_QR_CODE:
      return setQrCode(state, action);
    case actionTypes.SEND_PUSH_NOTIFICATION_START:
      return sendPushNotificationStart(state);
    case actionTypes.SEND_PUSH_NOTIFICATION_SUCCESS:
      return sendPushNotificationSuccess(state);
    case actionTypes.SEND_PUSH_NOTIFICATION_FAIL:
      return sendPushNotificationFail(state, action);
    case actionTypes.RESET_PUSH_NOTIFICATION_SENT:
      return resetPushNotificationSent(state);
    default:
      return state;
  }
};

export default devices;
