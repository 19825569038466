import {
  ASYNC_ACTION_START,
  ASYNC_ACTION_FINISH,
  ASYNC_ACTION_ERROR,
  ASYNC_ACTION_SUCCESS,
  ASYNC_ACTION_CLEAR,
  ASYNC_ACTION_REMOVE_ERROR,
} from './actionTypes';

export const asyncActionStart = () => ({ type: ASYNC_ACTION_START });
export const asyncActionFinish = () => ({ type: ASYNC_ACTION_FINISH });
export const asyncActionError = (errors) => ({
  type: ASYNC_ACTION_ERROR,
  errors,
});
export const asyncActionSuccess = () => ({ type: ASYNC_ACTION_SUCCESS });
export const asyncActionClear = () => ({ type: ASYNC_ACTION_CLEAR });
export const asyncActionRemoveError = (index) => ({
  type: ASYNC_ACTION_REMOVE_ERROR,
  errorIndex: index,
});
