import React, { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IntlProvider } from 'react-intl';
import Layout from './hoc/Layout/Layout';
import { authCheckState, applyConfig } from './store/actions/index';
import messages from './shared/translations/messages';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { ManualLogin } from './containers/Auth/ManualLogin/ManualLogin';

// components
import { RootState } from './shared/types/store';
import { SetPassword } from './containers/Auth/SetPassword/SetPassword';
import { Logout } from './containers/Auth/Logout/Logout';
import { ForgotPassword } from './containers/Auth/ForgotPassword/ForgotPassword';
import { PasswordReset } from './containers/Auth/PasswordReset/PasswordReset';
import { ProtectedRoute } from 'components/ProtectedRoute/ProtectedRoute';
import { selectConfig } from './store/selectors/config';
import { Auth } from './containers/Auth/Auth';
import MdpUsers from './containers/MdpUsers/MdpUsers';
import MdpUser from './containers/MdpUsers/MdpUser';
import Application from './containers/Application/Application';
import Dashboard from './containers/Dashboard/Dashboard';
import { ApplicationInvitation } from './containers/ApplicationInvitation/ApplicationInvitation';
import { Register } from './containers/Auth/Register/Register';
import { Terms } from './components/Terms/Terms';
import { NotAllowed } from './components/NotAllowed/NotAllowed';
import { Devices } from './components/Devices/Devices';
import { CostReport } from './containers/CostReport/CostReport';
import Applications from './containers/Applications/Applications';

export function App(): JSX.Element {
  const dispatch = useDispatch();
  const config = useSelector(selectConfig);

  const lang = useSelector((state: RootState) => state.locale.lang);

  useEffect(() => {
    dispatch(authCheckState());
    dispatch(applyConfig());
  }, []);

  if (!config) {
    return <></>;
  }

  const routes = (
    <Routes>
      <Route path="/login" element={<Auth />} />
      <Route path="/sso-login" element={<ManualLogin />} />
      <Route path="/register" element={<Register />} />

      <Route path="/password/set" element={<SetPassword />} />
      <Route path="/password/forgot" element={<ForgotPassword />} />
      <Route path="/password/reset/:email" element={<PasswordReset />} />

      <Route
        path="/invitations/:appId/accept"
        element={<ApplicationInvitation />}
      />
      <Route element={<ProtectedRoute />}>
        <Route path="/users" element={<MdpUsers />} />
        <Route path="/users/:userEmail" element={<MdpUser />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/applications/:type" element={<Applications />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/cost-report" element={<CostReport />} />
        <Route path="/application/:id" element={<Application />} />
        <Route
          path="/application/:id/devices/:deviceId"
          element={<Devices />}
        />
        <Route path="/not-allowed" element={<NotAllowed />} />
        <Route path="/logout" element={<Logout />} />
      </Route>
      <Route path="*" element={<Navigate replace to="/applications/my" />} />
    </Routes>
  );

  return (
    <IntlProvider locale={lang} messages={messages[lang]}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Layout>{routes}</Layout>
      </LocalizationProvider>
    </IntlProvider>
  );
}
