import { Button, styled } from '@mui/material';
import React from 'react';

const CommonButton = styled(Button)({
  fontSize: 12,
  padding: '6px 16px !important',
  marginRight: 16,
  '&:hover': {
    backgroundColor: '#ccc',
  },
});

const ColoredButton = styled(CommonButton)({
  color: '#fff',
  backgroundColor: '#00003C',
});

const TransparentButton = styled(CommonButton)({
  backgroundColor: 'transparent',
  color: '#404040',
  boxShadow: 'none',
});

interface IProps {
  classes?: { [key: string]: string };
  isDisabled?: boolean;
  type: string;
  btnText: string;
  actionHandler: () => void;
}

export function ModalButton({
  isDisabled = false,
  type,
  btnText,
  actionHandler,
}: IProps) {
  const MButton = type === 'colored' ? ColoredButton : TransparentButton;
  return (
    <MButton disabled={isDisabled} variant="contained" onClick={actionHandler}>
      {btnText}
    </MButton>
  );
}
