import axios from 'axios';

const hostName = window.location.hostname;
const protocol = window.location.protocol;
// split the hostname
const splitHostName = hostName.split('.');
// split the fist item from hostname
const splitItem = hostName.split('.')[0].split('-');
// add "service" as second item in array
splitItem.splice(1, 0, 'service');
// build an url
const url = splitItem.join('-');
// replace the fist item with new one
splitHostName[0] = url;
// build endpoint
const endpoint = splitHostName.join('.');
const baseApi = `${protocol}//${endpoint}/api/v3/`;

const axiosPNInstance = axios.create({ baseURL: baseApi });
export default axiosPNInstance;
