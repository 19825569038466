import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SearchInput, { createFilter } from 'react-search-input';
import { changeUsersPage } from '../../store/actions/pagination';
import * as actions from '../../store/actions/index';
import {
  selectIsRegisterSuccess,
  selectUsers,
} from '../../store/selectors/users';
import text from '../../shared/translations/en';
import { selectUsersPage } from '../../store/selectors/pagination';
import { Pagination } from '../../components/UI/Pagination/Pagination';
import Modal from '../../components/Modal/Modal';
import { Footer } from '../../components/Footer/Footer';
import { AddUserModal } from './AddUserModal';
import _ from 'lodash';
import { MdpUser } from '../../shared/types/user';
import { Navigate } from 'react-router-dom';
import { clearRegistrationSuccess } from '../../store/actions/auth';

const KEYS_TO_FILTERS = ['userEmail'];
const MAX_ITEMS = 10;

export default function MdpUsers(): JSX.Element {
  const dispatch = useDispatch();

  const users = useSelector(selectUsers);
  const selectedPage = useSelector(selectUsersPage) ?? 0;
  const isRegisterSuccess = useSelector(selectIsRegisterSuccess);

  const [usersSearchTerm, setUsersSearchTerm] = useState<string>('');
  const [dialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [shownUsers, setShownUsers] = useState<MdpUser[]>(users);
  const [redirect, setRedirect] = useState<string>();

  useEffect(() => {
    dispatch(actions.getAllUsers());
    dispatch(clearRegistrationSuccess());
  }, []);

  const handleSearchUsers = useCallback(
    (value: string) => {
      setUsersSearchTerm(value);
      dispatch(changeUsersPage(0));
      setShownUsers(users.filter(createFilter(value, KEYS_TO_FILTERS)));
    },
    [users]
  );

  const handleChangeUsersPage = (pageNumber: { selected: number }) => {
    dispatch(changeUsersPage(pageNumber.selected));
  };

  useEffect(() => {
    setShownUsers(users);
  }, [users]);

  useEffect(() => {
    if (isRegisterSuccess) {
      setIsDialogOpen(false);
    }
  }, [isRegisterSuccess]);

  const isUserOnCurrentPage = (page: number, ix: number) => {
    const min = page * MAX_ITEMS;
    const max = page * MAX_ITEMS + MAX_ITEMS;

    if (ix < min || ix >= max) {
      return false;
    }

    return true;
  };

  if (redirect) {
    return <Navigate to={redirect} />;
  }

  return (
    <main className="layout__main">
      <div className="content admin-users">
        <div className="row">
          <div className="col-1-of-2">
            <h1>{text.en.users}</h1>
          </div>
          <div className="col-1-of-2 admin-users-controls">
            <SearchInput
              value={usersSearchTerm}
              className="search-input"
              onChange={(value: string) => handleSearchUsers(value)}
            />
            <button
              type="submit"
              className="admin-users-add-new sr-btn sr-btn__md btn-default u-btn-right"
              onClick={() => setIsDialogOpen(true)}
            >
              <i className="icon-16-031-user-follow" />
              {text.en['add-new-user']}
            </button>
          </div>
        </div>
        <div className="row">
          <div className="table__header">
            <div className="table__header-row">
              <span className="table__header-email">User</span>
              <span className="table__header-actions">Role</span>
              <span className="table__header-plan">Plan</span>
            </div>
          </div>
          <div className="table__body">
            {shownUsers?.map((user, ix) => {
              if (!isUserOnCurrentPage(selectedPage, ix)) {
                return null;
              }

              return (
                <div
                  key={ix}
                  className="table__body-row"
                  onClick={() => setRedirect(`/users/${user.userEmail}`)}
                >
                  <div className="table__body-email">
                    <p className="body-4">{user.userEmail}</p>
                  </div>
                  <div
                    className={`table__body-actions ${
                      user.isAdmin ? 'srm-admin' : ''
                    }`}
                  >
                    {user.isLocked ? (
                      <i className="icon-16-076-lock-privacy" />
                    ) : (
                      <i className="icon-16-029-user" />
                    )}
                    {user.isAdmin ? 'Admin' : 'User'}
                  </div>
                  <div className="table__body-plan">
                    {user.userPlan ? (
                      <p className="body-4">{user.userPlan.expiry}</p>
                    ) : (
                      <p className="body-4">{text.en['no-plan']}</p>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="admin-users-pagination">
            <span className={'admin-users-pagination-results'}>
              {shownUsers.length} {text.en.results}
            </span>
            {shownUsers && shownUsers.length > MAX_ITEMS ? (
              <>
                <Pagination
                  pages={users.length / MAX_ITEMS}
                  changePage={handleChangeUsersPage}
                  pageNumber={selectedPage}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={2}
                />
                <span>
                  <label htmlFor={'pages'}>{text.en.show}:</label>
                  <select
                    id="pages"
                    className="select"
                    onChange={(e) =>
                      handleChangeUsersPage({
                        selected: parseInt(e.target.value, 10),
                      })
                    }
                    value={selectedPage}
                  >
                    {_.range(0, users.length / MAX_ITEMS, 1).map(
                      (i: number) => {
                        return (
                          <option value={i} key={`user-page-${i}`}>
                            {i + 1}
                          </option>
                        );
                      }
                    )}
                  </select>
                </span>
              </>
            ) : null}
          </div>
        </div>
        {dialogOpen ? (
          <Modal
            title={
              <div className="mdp-admin-add-user-title">
                {text.en['add-new-user']}
                <span
                  onClick={() => setIsDialogOpen(false)}
                  className={'mdp-admin-add-user-btn-close icon-16-003-close-x'}
                />
              </div>
            }
            onDismiss={() => setIsDialogOpen(false)}
            content={
              <React.Fragment>
                <AddUserModal />
              </React.Fragment>
            }
            actions={() => <></>}
          />
        ) : null}
      </div>
      <div className="layout__footer">
        <Footer />
      </div>
    </main>
  );
}
