import text from '../../shared/translations/en';

export const CONFIG_ONE = 'config-one';
export const CONFIG_TWO = 'config-two';
export const CONFIG_THREE = 'config-three';

export const formConfig = {
  EventFilteringPayloadModifier: { formView: text.en[CONFIG_ONE] },
  EventSoundPayloadModifier: { formView: text.en[CONFIG_TWO] },
  EventIOSContentAvailablePayloadModifier: {
    formView: text.en[CONFIG_ONE],
  },
  EventIOSCategoryPayloadModifier: { formView: text.en[CONFIG_TWO] },
  EventIOSMutableContentPayloadModifier: { formView: text.en[CONFIG_ONE] },
  EventDecoratingPayloadModifier: { formView: text.en[CONFIG_THREE] },
};
