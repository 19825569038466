import React from 'react';

export function Exclusion(): JSX.Element {
  return (
    <>
      <h2>12. Exclusion of liability</h2>
      <p>
        To the fullest extent permitted by law, neither Sportradar, nor any of
        its Affiliates, nor the Sportradar’s software or data providers, agents,
        subcontractors or auxiliaries shall be liable to the Customer or to any
        clients of the Customer for any direct damages or loss or any loss of
        profit as well as a loss of turnover, data, business or goodwill or for
        any indirect or consequential damages or loss or special damages arising
        in connection with the MDP and the Mobile SDK (in each case whether
        arising from negligence, breach of contract, equity, statute, tort or
        otherwise) even if Sportradar has been notified of the possibility of
        such damage or loss, including (but not limited to):
      </p>
      <p>
        Any loss or damage which the Customer may incur as a result of the MDP
        or the Mobile SDK failing to be wholly accurate, complete, reliable,
        accessible or otherwise as a result of any breach or non-performance of
        this Agreement; or any loss or damage resulting from claims brought by
        any client of the Customer.
      </p>
    </>
  );
}
