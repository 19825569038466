import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';
import { UserState } from '../../shared/types/store';

const initialState: UserState = {
  loading: false,
  users: [],
  success: false,
};

const fetchAllUsersStart = (state) => {
  return updateObject(state, {
    loading: true,
  });
};
const fetchAllUsersFail = (state) => {
  return updateObject(state, {
    loading: false,
  });
};
const fetchAllUsersSuccess = (state, action) => {
  return updateObject(state, {
    users: action.data,
    loading: false,
  });
};

const fetchUserPlanStart = (state) => {
  return updateObject(state, { loading: true, error: false });
};

const fetchUserPlanSuccess = (state, action) => {
  const filteredUsers = [...state.users].map((user) => {
    if (user.email === action.plan.email) {
      return {
        ...user,
        plan: action.plan.plan,
      };
    } else {
      return user;
    }
  });

  return updateObject(state, {
    users: filteredUsers,
    loading: false,
    error: false,
  });
};

const fetchUserPlanFail = (state) => {
  return updateObject(state, { loading: false, error: true });
};

const changeUserRoleSuccess = (state, action) => {
  const oldUsers = [...state.users];
  const userEmail = action.payload.userId;
  const isAdmin = action.payload.role;
  const filteredUsers = oldUsers.map((user) => {
    if (user.userEmail === userEmail) {
      return {
        ...user,
        isAdmin,
      };
    } else {
      return user;
    }
  });

  return updateObject(state, {
    users: filteredUsers,
  });
};

const changeUserLockSuccess = (state, action) => {
  const oldUsers = [...state.users];
  const userEmail = action.payload.userEmail;
  const isLocked = action.payload.isLocked;
  const filteredUsers = oldUsers.map((user) => {
    if (user.userEmail === userEmail) {
      return {
        ...user,
        isLocked,
      };
    } else {
      return user;
    }
  });

  return updateObject(state, {
    users: filteredUsers,
  });
};

const changeUserActivationSuccess = (state, action) => {
  const oldUsers = [...state.users];
  const userId = action.payload.userId;
  const filteredUsers = oldUsers.map((user) => {
    if (user.id === parseInt(userId, 10)) {
      return {
        ...user,
        isActivated: true,
      };
    } else {
      return user;
    }
  });

  return updateObject(state, {
    users: filteredUsers,
  });
};

const addUserToUsers = (state, action) => {
  return {
    ...state,
    users: [...state.users, action.payload],
    success: true,
  };
};

const clearRegistrationSuccess = (state) => {
  return updateObject(state, {
    success: false,
  });
};

const users = (state, action) => {
  if (typeof state === 'undefined') {
    return initialState;
  }

  switch (action.type) {
    case actionTypes.FETCH_ALL_USERS_START:
      return fetchAllUsersStart(state);
    case actionTypes.FETCH_ALL_USERS_SUCCESS:
      return fetchAllUsersSuccess(state, action);
    case actionTypes.FETCH_ALL_USERS_FAIL:
      return fetchAllUsersFail(state);
    case actionTypes.FETCH_USER_PLAN_START:
      return fetchUserPlanStart(state);
    case actionTypes.FETCH_USER_PLAN_SUCCESS:
      return fetchUserPlanSuccess(state, action);
    case actionTypes.FETCH_USER_PLAN_FAIL:
      return fetchUserPlanFail(state);
    case actionTypes.CHANGE_USER_ROLE_SUCCESS:
      return changeUserRoleSuccess(state, action);
    case actionTypes.CHANGE_USER_LOCK_SUCCESS:
      return changeUserLockSuccess(state, action);
    case actionTypes.CHANGE_USER_ACTIVATION_SUCCESS:
      return changeUserActivationSuccess(state, action);
    case actionTypes.USER_REGISTRATION_SUCCESS:
      return addUserToUsers(state, action);
    case actionTypes.CLEAR_USER_REGISTRATION:
      return clearRegistrationSuccess(state);
    default:
      return state;
  }
};

export default users;
