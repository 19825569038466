import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Bar } from './Bar';
import { getUserPlan } from '../../store/actions/admin';
import { selectUser } from '../../store/selectors/user';
import { selectUserPlan } from '../../store/selectors/plan';
import {
  NOTIFICATION_PLAN_WILL_EXPIRE,
  Notification as INotification,
  NOTIFICATION_PLAN_EXPIRED,
} from '../../utils/Notifications';
import { DAYS_30_IN_MS } from '../../utils/Date';

export default function Notification(): JSX.Element {
  const dispatch = useDispatch();

  const [notification, setNotification] = useState<INotification>();

  const user = useSelector(selectUser);
  const userPlan = useSelector(selectUserPlan);

  useEffect(() => {
    user && dispatch(getUserPlan(user.attributes.email));
  }, [user]);

  useEffect(() => {
    if (userPlan) {
      const currentDate = new Date();
      const currentMilliseconds = currentDate.getTime();
      const expirationDateMs = new Date(userPlan.expiry).getTime();
      const diff = expirationDateMs - currentMilliseconds;

      if (diff > 0 && diff < DAYS_30_IN_MS) {
        setNotification(NOTIFICATION_PLAN_WILL_EXPIRE);
      } else if (diff <= 0) {
        setNotification(NOTIFICATION_PLAN_EXPIRED);
      }
    }
  }, [userPlan]);

  if (!notification) {
    return <></>;
  }

  return (
    <Bar
      type={notification.type}
      text={notification.text}
      visible={notification !== undefined}
      onClose={() => setNotification(undefined)}
    />
  );
}
