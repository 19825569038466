import * as actionTypes from './actionTypes';

export const localeSet = (lang) => {
  return {
    type: actionTypes.LOCALE_SET,
    lang,
  };
};

export const setLocale = (lang) => {
  return (dispatch) => {
    // save to ls
    localStorage.alhubLang = lang;
    dispatch(localeSet(lang));
  };
};
