import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import text from '../../shared/translations/en';
import { Device } from '../../shared/types/device';
import {
  sendPushNotifications,
  resetPushNotificationSent,
} from '../../store/actions/devices';
import {
  selectError,
  selectIsPushNotificationSent,
  selectIsSending,
} from '../../store/selectors/devices';
import { toastr } from 'react-redux-toastr';

interface IProps {
  devices: Device[];
}

export function PushNotificationControl({ devices }: IProps): JSX.Element {
  const dispatch = useDispatch();

  const [selectedDevice, setSelectedDevice] = useState<Device>();
  const [devicesWithPNTokens, setDevicesWithPNTokens] = useState<Device[]>();

  const sending = useSelector(selectIsSending);
  const error = useSelector(selectError);
  const pushNotificationSent = useSelector(selectIsPushNotificationSent);

  const handleSendPushNotification = useCallback(() => {
    if (selectedDevice) {
      dispatch(sendPushNotifications(selectedDevice));
    }
  }, [selectedDevice]);

  useEffect(() => {
    if (error) {
      toastr.error(error.message);
    }
  }, [error]);

  useEffect(() => {
    const devicesWithTokens = devices.filter(
      (device: Device) => device.pushToken !== null
    );
    setSelectedDevice(devicesWithTokens[0]);
    setDevicesWithPNTokens(devicesWithTokens);
  }, [devices]);

  useEffect(() => {
    if (pushNotificationSent) {
      toastr.success(text.en['push-notification-success']);
      dispatch(resetPushNotificationSent());
    }
  }, [pushNotificationSent]);

  return (
    <div className="push-notification-control">
      {devicesWithPNTokens?.length ? (
        <>
          <div className="push-notification-control-title">
            {text.en['send-push-notifications']}
          </div>
          {text.en['devices-with-push-tokens']}
          <div className="push-notification-control-body">
            <select
              className="select"
              onChange={(event: React.FormEvent<HTMLSelectElement>) =>
                setSelectedDevice(
                  devicesWithPNTokens.find(
                    (device: Device) => event.currentTarget.value === device.id
                  )
                )
              }
            >
              {devicesWithPNTokens.map((device: Device) => (
                <option key={device.id} value={device.id}>
                  {device.id} {device.name && `(${device.name})`}
                </option>
              ))}
            </select>
            <button
              className="push-notification-control-button"
              onClick={handleSendPushNotification}
              disabled={sending}
            >
              <span className="playback-control-button-text">
                <i className="icon icon-16-080-paper-plane-send" />
                {text.en['send-push-notifications']}
              </span>
            </button>
          </div>
        </>
      ) : (
        <em>{text.en['missing-registered-devices']}</em>
      )}
    </div>
  );
}
