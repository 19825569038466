import { Typography } from '@mui/material';
import { has } from 'lodash';
import React from 'react';

interface IProps {
  configuration: string;
}

export function ModifierExpansionDetails({ configuration }: IProps) {
  const configObject = (config: string) => {
    const parsedConfig = JSON.parse(config);
    // check if config have key mappings
    const haveMappings = has(parsedConfig, 'mappings');
    if (haveMappings) {
      // modifiy config
      const mappings = parsedConfig.mappings;
      const { condition, mapping } = mappings[0];
      return { ...condition, mapping };
    } else {
      return parsedConfig;
    }
  };

  const getDetails = () => {
    const contentDetail = configObject(configuration);
    const fields: JSX.Element[] = [];
    Object.keys(contentDetail).forEach((key) => {
      let value = contentDetail[key];
      if (
        contentDetail[key] &&
        contentDetail[key].length > 1 &&
        typeof contentDetail[key] !== 'string'
      ) {
        value = contentDetail[key].join(', ');
      }
      if (typeof contentDetail[key] === 'boolean') {
        value = JSON.stringify(contentDetail[key]);
      }
      fields.push(
        <Typography key={key} className="body-5">
          {key}: &nbsp;&nbsp;{value}
        </Typography>
      );
      return fields;
    });
    return fields;
  };

  return <>{getDetails().map((field) => field)}</>;
}
