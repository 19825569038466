import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';
import { ConfigState } from '../../shared/types/store';

const initialState: ConfigState = {
  config: undefined,
};

const setConfig = (state, action) => {
  return updateObject(state, {
    config: action.config,
  });
};

const config = (state, action) => {
  if (typeof state === 'undefined') {
    return initialState;
  }

  if (action.type === actionTypes.SET_CONFIG) {
    return setConfig(state, action);
  } else {
    return state;
  }
};

export default config;
