import React, { useCallback, useState } from 'react';
import { Footer } from '../../../components/Footer/Footer';
import { Link, Navigate } from 'react-router-dom';
import srLogo from '../../../assets/images/SR_logo_basic.png';
import { useSelector } from 'react-redux';
import { selectConfig } from 'store/selectors/config';
import { Amplify, Auth } from 'aws-amplify';
import { toastr } from 'react-redux-toastr';
import text from '../../../shared/translations/en';

export function ForgotPassword(): JSX.Element {
  const config = useSelector(selectConfig);

  const [email, setEmail] = useState<string>('');
  const [redirect, setRedirect] = useState<string>();

  const handleForgotPassword = useCallback(async () => {
    Amplify.configure({
      Auth: config?.Auth.customer,
      API: config?.API,
    });
    try {
      await Auth.forgotPassword(email);
      setRedirect(`/password/reset/${email}`);
    } catch (error: unknown) {
      toastr.error((error as Error).message);
    }
  }, [email, config]);

  if (redirect) {
    return <Navigate to={redirect} />;
  }

  return (
    <main className="layout__main sr-forms">
      <div className="password--reset sr-forms-content">
        <div className="password--reset-form sr-forms-component">
          <div className="sr-forms-title">
            <span className="sr-logo">
              <Link to="/">
                <img src={srLogo} alt="Sportradar" />
              </Link>
            </span>
            <span className="sr-forms-mdp-brand">MDP</span>
          </div>
          <div className="password--reset-form-box sr-forms-form">
            <p className="input-row__label">Email*</p>
            <input
              type="email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              className={`input-row__input ${email.length ? '' : 'srm-empty'}`}
            />
            <button
              className="sr-btn sr-btn__lg u-btn-right btn-dark-blue u-margin-top-32"
              disabled={!email.length}
              onClick={handleForgotPassword}
            >
              {text.en['reset-password']}
            </button>
          </div>
        </div>
      </div>
      <div className="layout__footer">
        <Footer />
      </div>
    </main>
  );
}
