import React, { useState } from 'react';
import { getDocumentationUrl } from '../../../utils/Documentation';
import { MobileMenu } from './MobileMenu';

interface IProps {
  isAuthenticated: boolean;
}

export function MobileNav({ isAuthenticated }: IProps): JSX.Element {
  const [mainMenu, setMainMenu] = useState<boolean>(false);

  return (
    <div className="mobile-nav">
      <input
        id="mobile-nav-cbx"
        type="checkbox"
        className="main__checkbox"
        checked={mainMenu}
        onChange={() => setMainMenu(!mainMenu)}
      />
      <label htmlFor="mobile-nav-cbx" className="main__button">
        <span className="main__icon"></span>
      </label>
      <div className="main__menu">
        <ul className="menu__list">
          <li>
            <a href={getDocumentationUrl()} target={'_blank'}>
              <label className="list__link">
                <i className="icon-16-144-menu-square" />
                Documentation
              </label>
            </a>
          </li>
          <MobileMenu
            isAuthenticated={isAuthenticated}
            setMainMenu={setMainMenu}
          />
        </ul>
      </div>
    </div>
  );
}
