import React from 'react';
import { Footer } from '../../../components/Footer/Footer';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { selectUser } from '../../../store/selectors/user';
import useAmplifyAuth from '../../../utils/useAmplifyAuth';
import { Login } from '../Login/Login';

export const ManualLogin: React.FC = () => {
  useAmplifyAuth();

  const user = useSelector(selectUser);

  if (user) {
    return <Navigate to="/applications" />;
  }

  return (
    <main className="layout__main sr-forms">
      <div className="login-box">
        <Login />
      </div>
      <div className="layout__footer">
        <Footer />
      </div>
    </main>
  );
};
