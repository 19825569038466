import React from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';

interface IProps {
  inverted?: boolean;
}

export function LoadingComponent({ inverted = true }: IProps) {
  return (
    <Dimmer inverted={inverted} active={true}>
      <Loader content="Loading..." size="large" />
    </Dimmer>
  );
}
