const en = {
  en: {
    accept: 'Accept',
    add: 'Add',
    'add-event-types': 'Add event types',
    'add-owner': 'Add owner',
    'add-sdk-settings': 'Add sdk settings',
    'add-platform': 'Add platform',
    'add-recording': 'Add recording',
    admin: 'Admin',
    'admin-disable': 'Disable admin',
    'admin-enable': 'Enable admin',
    'admin-settings': 'Admin settings',
    'all-apps': 'All applications',
    'allowed-domains': 'Allowed domains',
    'allowed-domains-placeholder': 'https://example.com',
    'allowed-hosts': 'Allowed hosts',
    android: 'Android',
    'android-extra-lead': `More about signing application on
                                More about that on
                                <a href="https://developer.android.com/studio/publish/app-signing"
                                target="_blank"
                                rel="noopener noreferrer">Android developers
                                </a> page.`,
    'android-lead':
      'Enter your Firebase Sender ID and Server key. Check the Firebase guide on how to obtain these.',
    'api-access-key': 'Api access key',
    'api-access-key-lead':
      'Api access key allows you to use some of the tools below or access MDP with Rest API.',
    'api-key': 'Api key',
    'api-key-create': 'Create api key',
    'api-key-recreate': 'Re-create api key',
    'api-key-missing':
      'Custom push notifications requires api access key, please create it',
    'api-key-warning': 'The previous key will stop working.',
    'app-modifiers': 'Enabled push modifiers',
    'apns-cert': 'APNS certificate',
    'apns-cert-add': 'Add certificate',
    'app-bundle-id': 'Application bundle id',
    'app-bundle-placeholder': 'app.example.app',
    'app-key': 'Application key',
    'app-namespace': 'Application namespace',
    'app-usage-title': 'Application usage',
    'app-usage-login': 'User login count',
    'app-usage-pn': 'Push notifications count',
    application: 'Application',
    'app-usage': 'Application usage',
    applications: 'Applications',
    cancel: 'Cancel',
    certificate: 'certificate',
    'cert-exp-time': 'expiration time',
    'cert-exp-date': 'expiration date',
    'cert-fingerprint': 'Certificate fingerprint',
    'cert-fingerprint-placeholder':
      '00:00:00:00:00:00:00:00:00:00:00:00:00:00:00:00:00:00:00:00',
    channel: 'Channel',
    change: 'Change',
    close: 'Close',
    clear: 'Clear',
    code: 'Code',
    'code-placeholder': 'Insert code',
    confirm: 'Confirm',
    'config-one': 'configOne',
    'config-two': 'configTwo',
    'config-three': 'configThree',
    content: 'Content',
    'content-id': 'Content ID',
    count: 'Count',
    'comma-separated': 'Multiple values must be separated by a comma.',
    create: 'Create',
    credentials: 'credentials',
    'create-new-app': 'Create new application',
    'create-new': 'Create new',
    created: 'Created',
    'custom-no-subscriptions':
      'No channel subscriptions found. You can subscribe to channels using the SDK.' +
      ' Channel names can be anything you define. See NotificationsController for more info.',
    'custom-payload-key': 'Custom payload key',
    'custom-payload-value': 'Custom payload value',
    'custom-pn': 'Custom push notifications',
    'custom-pn-lead':
      'Custom push notifications allows you to send direct notifications to subscribed users.',
    'custom-pn-send': 'Send push notification',
    dashboard: 'Dashboard',
    'demo-pn': 'Demo push notifications',
    'demo-pn-lead':
      'Demo push notifications will affect only devices in development mode.',
    delay: 'Delay',
    delete: 'Delete',
    'delete-apns-cert': 'Delete APNS certificate?',
    'delete-user': 'Delete user',
    'delete-check-text': 'Are you sure you want to delete',
    'delete-event-text': 'Delete the event?',
    description: 'Description',
    disabled: 'Disabled',
    'disabled-apps': 'Disabled/expired apps',
    docs: 'Documentation',
    duration: 'Duration',
    edit: 'Edit',
    details: 'Details',
    email: 'E-mail',
    'email-error': 'Please insert email',
    endpoint: 'Endpoint',
    end: 'End',
    environment: 'Environment',
    mode: 'Environment',
    'env-dev': 'Development',
    'env-dev-lead':
      'Development environment enables you additional development features like match playback and test push notifications.',
    'env-prod': 'Production',
    'env-select': 'Please select the environment.',
    'event-subscribe': 'Subscribe to event',
    'event-select': 'Select event',
    'event-types': 'Event types',
    expired: 'Expired',
    'expiry-date': 'Expiry date',
    'expired-on': 'Expired on',
    'expires-on': 'Expires on',
    extra: 'extra',
    'extra-field-required': 'Extra field is required.',
    'existing-token': 'existing token',
    error: 'error',
    'error-platform-same-values':
      'Production and development values should not be identical.',
    false: 'False',
    finished: 'Finished',
    'file-select': 'Choose file',
    forbidden: 'Forbidden',
    'formula-one': 'Formula one',
    'forgot-password': 'Forgot password',
    get: 'get',
    'history-notifications': 'Notification history',
    'history-show': 'Show history',
    'invitation-from': 'Invitation from',
    'invitation-sent': 'Invitation sent',
    imprint: 'Imprint',
    ios: 'iOS',
    'ios-lead':
      'Upload your APNS certificates. You can create and download the certificates from Apple’s developer pages.',
    key: 'Key',
    language: 'Language',
    login: 'Login',
    'login-sso': 'Login with SSO',
    'use-manual-login': 'Use email and password instead',
    loding: 'loading',
    'lost-pass': 'Lost password',
    match: 'Match',
    mapping: 'mapping',
    'match-id': 'Match ID',
    'my-apps': 'My applications',
    name: 'Name',
    no: 'no',
    'no-data': 'No data.',
    'no-data-found': 'No notification history found.',
    none: 'None.',
    'new-token': 'new token',
    'new-application': 'New application',
    'not-allowed-main': 'Sorry, you are not allowed to visit this resource.',
    ok: 'Ok',
    overview: 'Overview',
    'overview-lead': `The SDK only works with an application key, which is provided below.
                            To start quickly, see the <a href="/documentation">Quick start guide</a>
                            for your chosen platform. Do not pass your application key around.`,
    'owner-set': 'Set owner',
    owner: 'Owner',
    passphrase: 'Passphrase',
    password: 'Password',
    'payload-setting': 'Payload setting',
    'privacy-policy': 'Privacy policy',
    private: 'Private',
    plan: 'Plan',
    'plan-settings': 'Plan settings',
    'plan-lead': `Plan type <b>Trial</b> will automatically set expiration date to 1 month.`,
    platform: 'Platform',
    'platform-created': 'Platform created',
    'platform-select': 'Please select the platform.',
    platforms: 'Platforms',
    'platforms-and-environments': 'Platforms and environments',
    play: 'Play',
    'playback-select': 'Select playback',
    'playback-offset': 'Playback offset',
    'pn-token': 'Push notification token (p8)',
    'pn-certificate': 'Push notification certificate (p12)',
    'push-notifications': 'Push notifications',
    'push-notifications-report-title': 'Push notifications report',
    'push-modifiers': 'Push modifiers',
    'push-notifications-lead':
      'Set the push notification delay for a specific application. The delay depends on the service' +
      ' level purchased by the customer. The delay is set in seconds. Default setting is 15 seconds (free trial).',
    'push-settings': 'Push settings',
    'push-settings-lead':
      'Push modifiers allows you to modify the push notifications, just before the are sent to the user.',
    'push-settings-placeholder': 'https://app.example.app',
    reject: 'Reject',
    recordings: 'Recordings',
    recording: 'Recording',
    redirecting: 'Redirecting',
    register: 'Register',
    'required-field': 'This field is required.',
    'required-fields-all': 'All fields are required.',
    'restricted-area': 'Restricted area',
    'remove-user-check-text': 'Are you sure you want to remove the user',
    role: 'Role',
    'role-developer': 'Developer',
    'role-owner': 'Owner',
    'role-sales': 'Sales',
    'role-viewer': 'Viewer',
    'role-viewer-info': 'Viewer credentials can not edit content.',
    save: 'Save',
    'sdk-playback': 'SDK playback',
    'sdk-playback-lead': `For easier development process, SDK playback allows you to playback past recorded matches.
                            Playback is supported only in the SDK development mode.
                            <strong>Application with the SDK must be restarted to apply any changes made in the reply section.</strong>`,
    id: 'ID',
    'sdk-settings': 'SDK settings',
    'sdk-settings-lead':
      'SDK settings for a specific application (application key). This can only be managed by Sportradar administrators.',
    'show-report': 'Show report',
    schedule: 'Schedule',
    'schedule-new-recording': 'Schedule new recording',
    search: 'Search',
    'season-id': 'Season id',
    send: 'Send',
    set: 'Set',
    'select-modifier': 'Select modifier',
    'select-plan': 'Select plan',
    'change-plan-settings': 'Change plan settings',
    'set-plan': 'Set plan',
    'set-password': 'Set new password',
    'user-plan': 'User plan',
    'set-app-owner': 'Set application owner',
    'set-owner-lead':
      'Select an owner from the list of existing management users. If the user is not on' +
      ' the list, a new user account will be created. No email invitations will be sent.',
    'set-owner-plan-lead':
      'Select an owner from the list of existing management users. If the user is' +
      ' not on the list, a new user account will be created. No email invitations will be sent.',
    'set-owner-warning': 'You will create new MDP user ',
    server: 'Server',
    'server-allowed-hosts-placheloder': '1.1.1.0/24',
    'server-allowed-hosts-helper-text': `IP address or CIDR IP range. Use
                                            <a href="http://www.subnet-calculator.com/cidr.php"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            >this online tool
                                            </a> for help with CIDR range calculation.`,
    'server-key': 'Server key',
    'server-key-placeholder': 'Server key',
    'server-lead': 'Server leading text',
    settings: 'Settings',
    'application-settings': 'Application settings',
    'settings-lead':
      'Use server-side key settings to control the behavior of your application.' +
      ' You can always add, edit or remove keys at a later time. Each application has its own keys.',
    'sign-out': 'Sign out',
    'simulated-time': 'Simulated time',
    'sport-id': 'Sport ID',
    'sport-name': 'Sport name',
    start: 'Start',
    'stage-id': 'Stage id',
    started: 'Started',
    status: 'Status',
    stop: 'Stop',
    success: 'Success!',
    submit: 'Submit',
    subscribe: 'Subscribe',
    subscriptions: 'Subscriptions',
    'sure-text': 'Are you sure you want to',
    title: 'Title',
    true: 'True',
    tools: 'Tools',
    token: 'token',
    'update-success': 'Update successful',
    upload: 'Upload',
    'update-error': 'Update unsuccessful',
    'url-format': 'URL format',
    'url-format-lead': 'APNS certificate',
    'url-format-placeholder': 'https://example%@.com',
    users: 'Users',
    'users-invite': 'Invite new users',
    'users-invite-lead': 'Invite users to this application.',
    'users-lead':
      'A list of all users who have access to your application – owners, developers and viewers. You can edit or remove users.',
    'vat-text': 'VAT identification number',
    value: 'Value',
    warning: 'Warning',
    web: 'Web',
    'web-lead': 'Web leading text',
    websafari: 'WebSafari',
    'websafari-extra-lead':
      `The url format should contain % @as a placeholder for arguments.
                                More about that on
                                <a href="https://developer.apple.com/library/archive/documentation/NetworkingInternet/Conceptual/` +
      `NotificationProgrammingGuideForWebsites/PushNotifications/PushNotifications.html#//apple_ref/doc/uid/TP40013225-CH3-SW18"
                                target="_blank"
                                rel="noopener noreferrer">Apple
                                </a> site.`,
    'websafari-lead':
      'Upload your APNS certificates. You can create and download the certificates from Apple’s developer pages.',
    yes: 'yes',
    'your-plan-will-expire-soon':
      'Your plan will expire soon. Please contact our sales at sales@sportradar.com to renew it.',
    'your-plan-is-expired':
      'Your plan is expired. Please contact our sales at sales@sportradar.com to renew it.',
    'set-your-plan':
      'Your plan is currently not defined. Please contact our sales sales@sportradar.com to create it.',
    'no-plan': 'No plan',
    'json-preview': 'Json preview',
    'view-source': 'View source',
    'record-information': 'Record information',
    'set-offset': 'Set offset',
    'set-collapsed': 'Set collapsed',
    'make-recording-public': 'Make recording public',
    'make-recording-private': 'Make recording private',
    paths: 'Paths',
    public: 'Public',
    'password-placeholder': 'Min 8 characters',
    remove: 'Remove',
    roles: 'Roles',
    languages: 'Languages',
    'user-role': 'User role',
    'no-documentation-title': 'No documentation',
    'no-documentation-description': `We are sorry but we cannot display requested documentation ATM,
                            please be patient we are currently working on improvements to make documentation available.
                            Until further notice you can still contact our support
                            <a mailto="support@sportradar.com">support@sportradar.com</a>
                            in case of urgency or any ambiguities.`,
    'create-account': 'Create account',
    'become-a-client': 'Become a client',
    'become-a-client-description':
      'Send us your information and we will contact you shortly',
    'already-have-an-account': 'I already have an account',
    'lock-user': 'Lock user',
    'sales-representatives': 'Sales representatives',
    'sales-representative': 'Sales representative',
    'account-settings': 'Account settings',
    'add-new-user': 'Add new user',
    'add-user': 'Add user',
    user: 'User',
    'reset-password': 'Reset password',
    'repeat-password': 'Repeat password',
    'resend-activation': 'Resend activation',
    show: 'Show',
    results: 'Results',
    'click-to-agree-terms': `Click here to indicate that you have read and agree to the terms of the <a href="/terms" target="_blank">Terms and Conditions.</a>`,
    'user-email': 'User email',
    'sales-representative-email': 'Sales representative email',
    'add-environment': 'Add environment',
    'add-new-environments': 'Add new environments',
    'development-environment': 'Development environment',
    'production-environment': 'Production environment',
    'custom-environment': 'Custom environment',
    'no-env-added-yet': 'No environments added yet.',
    environments: 'Environments',
    'are-you-sure-delete-key': 'Are you sure you want to delete key?',
    devices: 'Devices',
    'list-of-devices': 'List of devices',
    'device-name': 'Custom device label',
    'device-id': 'Device ID',
    actions: 'Actions',
    'confirm-device': 'Confirm device',
    'remove-device': 'Remove device',
    'edit-device': 'Edit device',
    confirmed: 'Confirmed',
    unconfirmed: 'unconfirmed',
    'playback-name': 'Playback name',
    playback: 'Playback',
    'active-playbacks': 'Active playbacks',
    'discard-playback': 'Discard playback',
    'are-you-sure-confirm-device':
      'Are you sure you want to confirm this device ?',
    'are-you-sure-remove-device':
      'Are you sure you want to remove this device ?',
    'send-push-notifications': 'Send push notification',
    'start-playback': 'Start playback',
    'stop-playback': 'Stop playback',
    'push-token': 'Push token',
    'select-devices': 'Select devices',
    'there-are-no-platforms-and-enviroments': `Please add <a href="#platforms-and-environments">platforms and environments</a> first.`,
    'activate-user': 'Activate user',
    'available-devices': 'Available devices',
    'available-devices-desc':
      'To identify a test device, make 5 subscription/un-subscription or 5 login requests trough SDK in a 2 minute timeframe.',
    'available-devices-current-timeframe': 'Current timeframe:',
    'integration-testing-tools': 'Integration testing tools',
    'integration-testing-tools-desc':
      'The integration tools allows you to test SDK integration. When you use a tool,' +
      ' only registered test devices will be affected. Start by selecting platform and environment.',
    'registered-devices': 'Registered devices',
    'registered-devices-desc': 'List of all registered test devices',
    'select-platform-and-environment': 'Select platform and environment',
    'select-playback': 'Select playback',
    'no-active-playbacks': 'No active playbacks.',
    'no-devices': 'No devices detected.',
    'test-devices': 'Test devices',
    'demo-notifications': 'Demo notifications',
    'playback-desc':
      'By starting a playback, all test devices for selected platform and environment will receive recorded data for selected match.',
    'demo-notifications-desc':
      'Here you can send demo notifications. Select the device you want to send push notification to. If everything is set correctly' +
      ' push notification should arrive.',
    'sso-login': 'SSO login',
    back: 'Go back',
    'connect-devices':
      'Scan the QR code with your mobile phone and click Scanned button. Then wait for about a minute and open you application' +
      ' with integrated SDK. Your device will appear in the list bellow.',
    scanned: 'Scanned',
    'password-error': 'Passwords do not match!',
    'missing-registered-devices':
      'Your application is missing testing devices. \nPlease see sections above and add testing devices.',
    'push-notification-success': 'Push notification was successfully sent.',
    'devices-with-push-tokens': 'Devices with push tokens',
    'search-placeholder': 'Search apps...',
    'cost-report': 'Cost report',
    months: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
    'no-cost-report': 'Please select month and year to generate cost report.',
    'cost-per-service': 'Cost per service',
    service: 'Service',
    cost: 'Cost',
    percentage: 'Percentage',
    app: 'App',
    total: 'Total',
    'core-usage-per-app': 'Core usage per apps',
    'notifications-per-app': 'Sent push notifications per apps',
    'feeds-per-app': 'MDP feeds usage per app',
    'core-label': '* Total column represents number of logins',
    'notifications-label':
      '* Total column represents number of sent push notifications',
    'feeds-label': '* Total column represents number of called endpoints',
  },
};

export default en;
