import React, {
  ChangeEvent,
  FC,
  FormEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Footer } from '../../components/Footer/Footer';
import { UserCard } from '../../components/UserCard/UserCard';
import { useDispatch, useSelector } from 'react-redux';
import text from '../../shared/translations/en';
import { selectUsers } from '../../store/selectors/users';
import { MdpUser as IMdpUser, UserRoles } from '../../shared/types/user';
import { Navigate, useParams } from 'react-router-dom';
import { LoadingComponent } from '../../components/UI/LoadingComponent';
import * as actions from '../../store/actions/index';
import { addDays } from '../../utils/Date';
import { getPlans } from '../../store/actions/plan.js';
import { selectUserApplications } from '../../store/selectors/application';
import { getSingleUser } from '../../store/actions/users.js';
import { UserDetails } from './UserDetails';
import { UserApplications } from './UserApplications';
import { OwnerPlan } from '../../components/OwnerPlan/OwnerPlan';

const MdpUser: FC = () => {
  const { userEmail } = useParams();
  const dispatch = useDispatch();

  const [currentUser, setCurrentUser] = useState<IMdpUser>();
  const [userRole, setRole] = useState<string>(UserRoles.USER);
  const [redirect, setRedirect] = useState<string>();
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
  const [plan, setPlan] = useState<string>(
    currentUser?.userPlan?.plan ?? 'Trial'
  );
  const [date, setDate] = useState<Date>(
    currentUser?.userPlan
      ? new Date(currentUser?.userPlan?.expiry)
      : addDays(new Date(), 30)
  );
  const [plans, setPlans] = useState<string[]>([]);
  const [isUserLocked, setIsUserLocked] = useState<boolean>(false);

  const users = useSelector(selectUsers);
  const userApps = useSelector(selectUserApplications);

  useEffect(() => {
    getPlans().then((response: string[]) => setPlans(response));

    if (!users.length) {
      dispatch(actions.getAllUsers());
    }
  }, []);

  useEffect(() => {
    const existingUser = users.find((user: IMdpUser) => {
      setPlan(user.userPlan?.plan);
      setDate(
        user.userPlan ? new Date(user.userPlan.expiry) : addDays(new Date(), 30)
      );
      setIsUserLocked(user.isLocked);
      return user.userEmail === userEmail;
    });

    if (!existingUser) {
      getSingleUser(userEmail).then((singleUser: IMdpUser) => {
        setCurrentUser({ ...singleUser, userExists: false });
        setDate(new Date(singleUser.userPlan.expiry));
        setPlan(singleUser.userPlan.plan);
      });
    } else {
      setCurrentUser({ ...existingUser, userExists: true });
    }
  }, [users]);

  useEffect(() => {
    if (currentUser) {
      setRole(currentUser.isAdmin ? UserRoles.ADMIN : UserRoles.USER);
      dispatch(actions.getUserApplications(currentUser.userEmail));
    }
  }, [currentUser]);

  const handleUserAccess = useCallback(() => {
    dispatch(actions.changeUserLock(currentUser));
    setIsUserLocked(!isUserLocked);
    setCurrentUser({ ...currentUser, isLocked: !isUserLocked });
  }, [currentUser, isUserLocked]);

  const onChangePlan = useCallback(
    (selectedPlan: string) => {
      setPlan(selectedPlan);
      setButtonDisabled(false);
      if (selectedPlan === 'Trial') {
        setDate(addDays(new Date(), 30));
      }
    },
    [plan, buttonDisabled]
  );

  const onChangeDate = useCallback(
    (selectedDate: Date) => {
      setDate(selectedDate);
      setButtonDisabled(false);
    },
    [date]
  );

  const submitOwnerPlan = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      // Add 1 day because the input value is 1 day less than the actual selected day.
      const selectedDateTimestamp = addDays(date, 1);
      const obj = {
        userEmail: currentUser.userEmail,
        plan: plan,
        expiryDate: selectedDateTimestamp.getTime(),
      };

      dispatch(actions.setOwnerPlan(obj));
      setButtonDisabled(true);
    },
    [date, currentUser, plan]
  );

  const handleChangeRole = useCallback(
    (selectedRole: string) => {
      setRole(selectedRole);

      dispatch(
        actions.changeUserRole(currentUser.userEmail, currentUser.isAdmin)
      );
    },
    [currentUser]
  );

  if (!currentUser) {
    return <LoadingComponent />;
  }

  if (redirect) {
    return <Navigate to={redirect} />;
  }

  return (
    <main className="layout__main">
      <div className="content admin-user">
        <div className="row u-margin-bottom-42">
          <div className="srm-back" onClick={() => setRedirect('/users')}>
            <i className={'icon icon-16-331-back1'} />
            {text.en.back}
          </div>
          <UserCard
            user={currentUser}
            accessEnabled={isUserLocked}
            onEnableAccessClick={handleUserAccess}
          />
        </div>
        <div className="row">
          <div className={'col-1-of-2'}>
            <div className={'srm-highlight'}>
              <div className="app-detail__row">
                <h3>{text.en['account-settings']}</h3>
              </div>
              <OwnerPlan
                owner={currentUser.userEmail}
                salesRepresentatives={currentUser.userPlan?.salesEmails ?? []}
                plans={plans}
                selectedDate={date}
                selectedPlan={plan}
                btnDisable={!buttonDisabled}
                onFormSubmit={submitOwnerPlan}
                onPlanChangeHandler={(e: ChangeEvent<HTMLSelectElement>) =>
                  onChangePlan(e.target.value)
                }
                onDateChange={onChangeDate}
              />
            </div>
            {currentUser.isSportradar && currentUser.userExists && (
              <UserDetails
                role={userRole}
                handleChangeRole={handleChangeRole}
              />
            )}
          </div>
          <div className={'col-1-of-2'}>
            {userApps && userApps.length > 0 && (
              <UserApplications userApps={userApps} />
            )}
          </div>
        </div>
      </div>
      <div className="layout__footer">
        <Footer />
      </div>
    </main>
  );
};

export default MdpUser;
