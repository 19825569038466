import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Platform, PlatformEnum } from '../../../shared/types/application';
import { selectUser } from '../../../store/selectors/user';
import { updatePlatformCredentials } from '../../../store/actions/applications';
import Aux from '../../../hoc/Aux/Aux';
import text from '../../../shared/translations/en';
import Modal from '../../../components/Modal/Modal';
import { ModalButton } from '../../../components/UI/ModalButton/ModalButton';

interface IProps {
  platform: PlatformEnum;
  environment: string;
  handleRemoveCredentials: (platform: string, environment: string) => void;
  data: Platform;
}

export function UniversalPlatform({
  platform,
  environment,
  handleRemoveCredentials,
  data,
}: IProps): JSX.Element {
  const dispatch = useDispatch();

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>(data.value);
  const [showButtons, setShowButtons] = useState<boolean>(false);

  const user = useSelector(selectUser);

  useEffect(() => {
    setInputValue(data.value);
  }, [data]);

  useEffect(() => {
    setShowButtons(inputValue !== data.value && inputValue !== '');
  }, [inputValue, data]);

  const handleUpdateCredentials = useCallback(() => {
    const credentials = {
      platform: platform,
      mode: environment,
      credentials: inputValue,
    };
    dispatch(updatePlatformCredentials(credentials, user));
    setShowButtons(false);
  }, [user, inputValue]);

  return (
    <div className="platform__box">
      <Aux key={`server-${environment}`}>
        <div className="app-detail__row custom-row">
          <div className="platform__box-header">
            {environment}
            {/* delete button */}
            <button
              title="Remove"
              className="platform__btn-remove sr-btn sr-btn__sm btn-default sr-btn-white u-btn-right"
              onClick={() => setIsDialogOpen(true)}
            >
              <i className="icon-16-079-trash" />
              {text.en.remove}
            </button>
          </div>
          <div className="platform__box-content">
            <div className="col-1-of-2">
              <div className="input-row__wrapper">
                <label className="input-row__label">
                  {/*Required asterisk*/}
                  {platform === PlatformEnum.SERVER
                    ? text.en['allowed-hosts']
                    : text.en['allowed-domains']}
                  {!data.value ? '*' : null}
                </label>
                <div className="input-row__input-contanier">
                  <span className="input-icon">
                    <i className="icon-16-006-edit" />
                  </span>
                  <input
                    value={inputValue}
                    className="input-row__input"
                    onChange={(e) => setInputValue(e.target.value)}
                    placeholder={
                      platform === PlatformEnum.SERVER
                        ? text.en['server-allowed-hosts-placheloder']
                        : text.en['allowed-domains-placeholder']
                    }
                  />
                </div>
                {platform === PlatformEnum.SERVER && (
                  <div className="input-row__support-msg">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: text.en['server-allowed-hosts-helper-text'],
                      }}
                    />
                  </div>
                )}
              </div>
              {/*Buttons*/}
              {showButtons ? (
                <div className="btn-wrapper">
                  <button
                    className="sr-btn sr-btn__sm btn-cancel"
                    title={text.en.cancel}
                    onClick={() => setInputValue(data.value)}
                  >
                    {text.en.cancel}
                  </button>
                  <button
                    className="sr-btn sr-btn__sm btn-default"
                    title={text.en.save}
                    onClick={handleUpdateCredentials}
                  >
                    {text.en.save}
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </Aux>
      {isDialogOpen ? (
        <Modal
          title={`Delete "${environment}" environment?`}
          contentText={`This action will affect all devices in ${environment} environment.`}
          actions={() => (
            <Fragment>
              <ModalButton
                btnText={text.en.no}
                actionHandler={() => setIsDialogOpen(false)}
                type="transparent"
              />
              <ModalButton
                btnText={text.en.yes}
                actionHandler={() =>
                  handleRemoveCredentials(PlatformEnum.SERVER, environment)
                }
                type="colored"
              />
            </Fragment>
          )}
          onDismiss={() => setIsDialogOpen(false)}
        />
      ) : null}
    </div>
  );
}
