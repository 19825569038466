import { Amplify, API } from 'aws-amplify';
import { currentUserToken } from '../store/actions/config';

export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties,
  };
};
export const checkValidity = (value, rules) => {
  let isValid = true;
  if (!rules) {
    return true;
  }

  if (rules.required) {
    isValid = value.trim() !== '' && isValid;
  }

  if (rules.minLength) {
    isValid = value.length >= rules.minLength && isValid;
  }

  if (rules.maxLength) {
    isValid = value.length <= rules.maxLength && isValid;
  }

  if (rules.isEmail) {
    const pattern =
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    isValid = pattern.test(value) && isValid;
  }

  if (rules.isNumeric) {
    const pattern = /^\d+$/;
    isValid = pattern.test(value) && isValid;
  }

  if (rules.isFingerprint) {
    const pattern = /^[0-9A-F]{2}(:[0-9A-F]{2}){19}$/;
    isValid = pattern.test(value) && isValid;
  }

  return isValid;
};

export const getToken: () => Promise<string> = () =>
  currentUserToken().then((response: string) => response);

export const makeURLQuery = (param) => {
  const query = [];
  for (const key in param) {
    query.push(`${key}=${param[key]}`);
  }
  return `?${query.join('&')}`;
};

export const availableEventTypes = [
  'MATCH_ABOUT_TO_START',
  'SCORE_CHANGED_POSSIBLE',
  'SCORE_CHANGED',
  'SCORE_CHANGED_CANCELLED',
  'YELLOW_CARD_POSSIBLE',
  'YELLOW_CARD',
  'YELLOW_CARD_CANCELLED',
  'YELLOWRED_CARD',
  'RED_CARD_POSSIBLE',
  'RED_CARD',
  'RED_CARD_CANCELLED',
  'MATCH_STARTED',
  'MATCH_ENDED',
  'PERIOD_START',
  'PERIOD_SCORE',
  'PLAYER_SUBSTITUTION',
  'SET_SCORE',
  'SETTLEMENT',
];

export const getLocalDateTime = (timestamp) => {
  const date = new Date(timestamp);
  const localeDate = date.toLocaleDateString(navigator.language);
  const localeTime = date.toLocaleTimeString(navigator.language);
  return `${localeDate}, ${localeTime}`;
};

export const payloadSettings = [
  'Title',
  'Subtitle',
  'Body',
  'Launch-Image',
  'Title-Loc-Key',
  'Subtitle-Loc-Key',
  'Loc-Key',
  'Badge',
  'Sound',
  'Thread-Id',
  'Category',
  'Content-Available',
  'Mutable-Content',
  'Title-Loc-Args',
  'Subtitle-Loc-Args',
  'Loc-Args',
  'Url-Args',
];

export const setConfig = (loginType, config) => {
  localStorage.setItem('login', loginType);
  Amplify.configure({ ...config, Auth: config.Auth[loginType] });
  API.configure({});
};

export const isUserAdmin = (user) => {
  return user?.signInUserSession?.accessToken.payload[
    'cognito:groups'
  ]?.includes('Admin');
};
