import axios from 'axios';
import * as actionTypes from './actionTypes';
import { text } from '../../shared/translations/en-v1';
import { getToken } from '../../shared/utility';
import { toastr } from 'react-redux-toastr';
import { API } from 'aws-amplify';
import { redirect } from 'react-router';

export const fetchAllUsersStart = () => {
  return { type: actionTypes.FETCH_ALL_USERS_START };
};

export const fetchAllUsersFail = () => {
  return { type: actionTypes.FETCH_ALL_USERS_FAIL };
};

export const fetchAllUserSuccess = (users) => {
  return {
    type: actionTypes.FETCH_ALL_USERS_SUCCESS,
    data: users,
  };
};

export const getAllUsers = () => {
  return (dispatch) => {
    dispatch(fetchAllUsersStart());
    API.get('mdp', '/users', {})
      .then((response) => {
        dispatch(fetchAllUserSuccess(response));
      })
      .catch((error) => {
        dispatch(fetchAllUsersFail());
        if (error && error.response && error.response.status === 403) {
          redirect('/not-allowed');
        }
      });
  };
};

export const getSingleUser = async (email) => {
  return API.get('mdp', `/users/${email}`, {})
    .then((response) => response)
    .catch((error) => {
      if (error && error.response && error.response.status === 403) {
        redirect('/not-allowed');
      }
    });
};

export const deleteUser = (userId) => {
  return (dispatch) => {
    axios
      .delete(`/api/user/${userId}`, {
        headers: { authorization: `Bearer ${getToken()}` },
      })
      .then((response) => {
        toastr.success(text.success, text.userDeleted);
        dispatch(getAllUsers());
      })
      .catch((err) => {
        const errorMessage = err.response.data.message || text.genericError;
        toastr.error(text.error, errorMessage);
      });
  };
};
