import React from 'react';

export const ONE_HOUR_IN_MS = 1000 * 60 * 60;
export const ONE_DAY_IN_MS = ONE_HOUR_IN_MS * 24;
export const ONE_WEEK_IN_MS = ONE_DAY_IN_MS * 7;
export const DAYS_30_IN_MS = ONE_DAY_IN_MS * 30;

export const addDays = (date: Date, days: number) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export function convertUnixExpiryTime(timestamp: Date): JSX.Element {
  const d = new Date(timestamp);
  const yyyy = d.getFullYear();
  const mm = `0${d.getMonth() + 1}`.slice(-2);
  const dd = `0${d.getDate()}`.slice(-2);
  const time = `${yyyy}-${mm}-${dd}`;
  const monthFromNow = new Date().getTime() + 30 * 24 * 60 * 60 * 1000;
  const todaysTimestamp = new Date().getTime();

  if (monthFromNow > timestamp.getTime()) {
    // The selected time is less than 30 days from now
    if (timestamp.getTime() < todaysTimestamp) {
      // color red
      return <span className="u-color-red">&nbsp; {time}</span>;
    } else {
      // yellow
      return <span className="u-color-orange">&nbsp; {time}</span>;
    }
  } else {
    // The selected time is more than 30 days from now => active
    return <span className="u-color-green">&nbsp; {time}</span>;
  }
}
