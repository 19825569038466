import React from 'react';
import text from '../../shared/translations/en';
import { Cost } from '../../shared/types/costReport';

interface IProps {
  reports: Cost[];
  title: string;
  label: string;
}
export function UsagePerApps({ reports, title, label }: IProps) {
  return (
    <div>
      <h3>{title}</h3>
      <small>{label}</small>
      <table>
        <thead>
          <tr>
            <td>{text.en['app']}</td>
            <td>{text.en['total']}</td>
            <td>{text.en['percentage']}</td>
            <td>{text.en['cost']} (USD)</td>
          </tr>
        </thead>
        <tbody>
          {[...reports]
            .sort((a: Cost, b: Cost) => (a.totalCount < b.totalCount ? 1 : -1))
            .map((report: Cost) => (
              <tr key={report.appName ?? 'Unknown'}>
                <td>{report.appName ?? 'Unknown'}</td>
                <td>{report.totalCount}</td>
                <td>{report.percentage}%</td>
                <td>{report.cost}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}
