import { RootState } from '../../shared/types/store';

export const selectAllPushModifiers = (state: RootState) =>
  state.pushSettings.allModifiers;

export const selectAppPushModifiers = (state: RootState) =>
  state.pushSettings.appModifiers;

export const selectCreatePushModifierSuccess = (state: RootState) =>
  state.pushSettings.success;
