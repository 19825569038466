import { Dialog } from '@mui/material';
import React from 'react';

interface IProps {
  open: boolean;
  handleClose: () => void;
  children: JSX.Element[];
}

export function FormDialog({ open, handleClose, children }: IProps) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      {children}
    </Dialog>
  );
}
