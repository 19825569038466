import React from 'react';
import { useSelector } from 'react-redux';
import text from '../../shared/translations/en';
import { selectApplication } from '../../store/selectors/application';
import { Loader } from '../Loader/Loader';
import { AvailableDevices } from './AvailableDevices';
import { RegisteredDevices } from './RegisteredDevices';

export function Devices(): JSX.Element {
  const app = useSelector(selectApplication);

  if (!app) {
    return <Loader loading={true} />;
  }

  return (
    <div className="app-devices">
      <h1>
        <span className="icon-header">
          <i className="icon-16-279-devices" />
        </span>
        {text.en['test-devices']}
      </h1>
      <AvailableDevices app={app} />
      <h3>{text.en['integration-testing-tools']}</h3>
      <p className="app-devices-description">
        {text.en['integration-testing-tools-desc']}
      </p>
      <RegisteredDevices app={app} />
    </div>
  );
}
