import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../store/selectors/user';
import { isUserAdmin } from '../../../shared/utility';
import text from '../../../shared/translations/en';
import uuid from 'uuid';
import { MobileNavItem, mobileNavItems } from '../../../shared/types/sidebar';
import { useNavigate } from 'react-router';

interface IProps {
  isAuthenticated: boolean;
  setMainMenu: (mainMenu: boolean) => void;
}

export function MobileMenu({
  isAuthenticated,
  setMainMenu,
}: IProps): JSX.Element {
  const navigate = useNavigate();
  const user = useSelector(selectUser);

  const getNavigationItems = useCallback(() => {
    const adminMenuItem: MobileNavItem = {
      name: 'admin',
      icon: 'icon-16-253-Admin',
      requireAuth: true,
      mainSubMenu: [
        { name: 'users', urlPath: '/users', icon: 'icon-16-030-people' },
        {
          name: 'dashboard',
          urlPath: '/dashboard',
          icon: 'icon-16-369-widgets',
        },
      ],
    };
    return user && isUserAdmin(user)
      ? [adminMenuItem, ...mobileNavItems]
      : mobileNavItems;
  }, [user]);

  const navigationHandler = useCallback((path: string) => {
    setMainMenu(false);
    navigate(path);
  }, []);

  return (
    <>
      {getNavigationItems()
        .filter(
          (item) =>
            item.requireAuth === isAuthenticated || item.requireAuth === null
        )
        .map((navItem) => {
          if (navItem.hasOwnProperty('mainSubMenu')) {
            const itemText = navItem.translation
              ? text.en[navItem.translation]
              : navItem.name;
            return (
              <li key={uuid.v4()}>
                <input
                  type="checkbox"
                  id={navItem.name}
                  className="sublist__checkbox"
                />
                <label htmlFor={navItem.name} className="sublist__button">
                  <i className={navItem.icon} />
                  {itemText}
                </label>
                <ul className="menu__sublist">
                  {navItem.mainSubMenu.map((item) => {
                    return (
                      <li
                        key={uuid.v4()}
                        className="list__link"
                        onClick={() => navigationHandler(item.urlPath)}
                      >
                        <i className={item.icon} />
                        {item.translation
                          ? text.en[item.translation]
                          : item.name}
                      </li>
                    );
                  })}
                </ul>
              </li>
            );
          }
          return (
            <li
              key={uuid.v4()}
              className="list__link"
              onClick={() => navigationHandler(`/${navItem.name}`)}
            >
              <i className={navItem.icon} />
              {navItem.translation
                ? text.en[navItem.translation]
                : navItem.name}
            </li>
          );
        })}
    </>
  );
}
