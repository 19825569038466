import React from 'react';
import { Link } from 'react-router-dom';
import { Application } from '../../shared/types/application';
import text from '../../shared/translations/en';

interface IProps {
  userApps: Application[];
}

export function UserApplications({ userApps }: IProps): JSX.Element {
  return (
    <React.Fragment>
      <div className="app-detail__row">
        <h3 className="u-margin-top-8">{text.en['applications']}</h3>
      </div>
      <div className="users__box u-margin-top-18">
        <div className="users__box-heading">
          <span className="users__box-heading-primary">Applications</span>
          <span className="users__box-heading-secondary">Role</span>
        </div>
        {userApps &&
          userApps.map((app) => {
            return (
              <div className="users__box-row" key={app.appId}>
                <div className="users__box-col-email">
                  <Link
                    to={{
                      pathname: `/application/${app.appId}`,
                      hash: '#overview',
                    }}
                  >
                    {app.name}
                  </Link>
                </div>
                <div className="users__box-col-role">
                  {app.users &&
                    app.users.map((appUser, ix) => (
                      <p key={`${appUser.email}_${ix}`}>
                        {appUser.role.toLowerCase()}&nbsp;
                      </p>
                    ))}
                </div>
              </div>
            );
          })}
      </div>
    </React.Fragment>
  );
}
