import React, { useState } from 'react';
import { AppSetting } from '../../../shared/types/pushSettings';
import text from '../../../shared/translations/en';
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Button,
  Typography,
} from '@mui/material';
import { ModifierExpansionDetails } from './ModifierExpansionDetails';

interface IProps {
  appModifiers: AppSetting[];
  styles: (tag: string) => {
    expansionPanel: unknown;
    heading: unknown;
    panelDetails: unknown;
    button: unknown;
  };
  onDeleteBtnClick: (modifierName: string) => void;
}

export function ModifierExpansionData({
  appModifiers,
  styles,
  onDeleteBtnClick,
}: IProps) {
  const classes = styles('unknown');

  const [expanded, setExpanded] = useState<string>();
  return (
    <>
      {appModifiers.map((modifier, index) => (
        <Accordion
          expanded={expanded === modifier.className}
          onChange={() =>
            setExpanded(
              expanded === modifier.className ? '' : modifier.className
            )
          }
          key={`${modifier.className}-${index}`}
          className="accordion"
        >
          <AccordionSummary
            expandIcon={
              expanded === modifier.className ? (
                <i className="icon-16-153-minus" />
              ) : (
                <i className="icon-16-152-plus" />
              )
            }
            style={classes.expansionPanel}
          >
            <Typography style={classes.heading}>
              {modifier.className}
            </Typography>
          </AccordionSummary>
          <AccordionDetails style={classes.panelDetails}>
            <ModifierExpansionDetails
              configuration={modifier.configurationJson}
            />
          </AccordionDetails>
          <AccordionActions>
            <Button
              style={classes.button}
              onClick={() => onDeleteBtnClick(modifier.className)}
            >
              {text.en.delete}
            </Button>
          </AccordionActions>
        </Accordion>
      ))}
    </>
  );
}
