import React, { Fragment, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { MdpRoles, UserWithRole } from '../../shared/types/user';
import { selectUser } from '../../store/selectors/user';
import { isUserAdmin } from '../../shared/utility';
import text from '../../shared/translations/en';
import { changeRole, deleteAppUser } from '../../store/actions/applications';
import Modal from '../../components/Modal/Modal';
import { ModalButton } from '../../components/UI/ModalButton/ModalButton';

interface IProps {
  user: UserWithRole;
  appId: string;
}

export function AppUser({ user, appId }: IProps): JSX.Element {
  const dispatch = useDispatch();

  const [showButton, setShowButton] = useState<boolean>(false);
  const [userRole, setUserRole] = useState<string>();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const currentUser = useSelector(selectUser);

  const allowRoleChange = () =>
    (!isUserAdmin(currentUser) && user.role === text.en[MdpRoles.SALES]) ||
    user.role === text.en[MdpRoles.OWNER];

  const handleUpdateUserRole = useCallback(() => {
    dispatch(changeRole(appId, [{ email: user.email, role: userRole }]));
  }, [userRole]);

  const handleRoleChange = useCallback(
    (role: string) => {
      setShowButton(role !== user.role);
      setUserRole(role);
    },
    [user]
  );

  const handleDeleteUser = useCallback(() => {
    dispatch(deleteAppUser(appId, user.email));
  }, [user]);

  return (
    <div className="users__box-row" key={user.email}>
      <div className="users__box-col-email">
        <NavLink to={`/users/${user.email}`}>{user.email}</NavLink>
      </div>
      <div className="users__box-col-role">
        <div className="control-wrapper">
          {allowRoleChange() ? (
            user.role
          ) : (
            <div className="users__role-select">
              <select
                value={userRole ?? user.role}
                onChange={(e) => handleRoleChange(e.target.value)}
                className="select"
              >
                <option value={text.en[MdpRoles.DEVELOPER]}>
                  {text.en[MdpRoles.DEVELOPER]}
                </option>
                <option value={text.en[MdpRoles.VIEWER]}>
                  {text.en[MdpRoles.VIEWER]}
                </option>
                {isUserAdmin(currentUser) && (
                  <option value={text.en[MdpRoles.SALES]}>
                    {text.en[MdpRoles.SALES]}
                  </option>
                )}
              </select>
            </div>
          )}
          {
            <div className="users__role-controls">
              <div>
                {showButton && (
                  <button
                    className="sr-btn sr-btn__md btn-default"
                    onClick={handleUpdateUserRole}
                  >
                    {text.en.confirm}
                  </button>
                )}
              </div>
              <div>
                <button className="sr-btn">
                  {showButton ? (
                    <i
                      className="icon-16-003-close-x"
                      onClick={() => handleRoleChange(user.role)}
                    />
                  ) : (
                    <i
                      className="icon-16-079-trash"
                      onClick={() => setIsDialogOpen(true)}
                      hidden={user.role === text.en[MdpRoles.OWNER]}
                    />
                  )}
                </button>
              </div>
            </div>
          }
        </div>
      </div>
      {isDialogOpen && (
        <Modal
          title="Delete user"
          contentText={`Are you sure you want to delete "${user.email}"?`}
          actions={() => (
            <Fragment>
              <ModalButton
                btnText={text.en.cancel}
                actionHandler={() => setIsDialogOpen(false)}
                type="transparent"
              />
              <ModalButton
                btnText={text.en.delete}
                actionHandler={handleDeleteUser}
                type="colored"
              />
            </Fragment>
          )}
          onDismiss={() => setIsDialogOpen(false)}
        />
      )}
    </div>
  );
}
