import React from 'react';
import { Loader } from '../../components/Loader/Loader';
import { CostReport } from '../../shared/types/costReport';
import { CostReportPerService } from './CostReportPerService';
import { PieChart } from './PieChart';
import { UsagePerApps } from './UsagePerApps';
import text from '../../shared/translations/en';

interface IProps {
  costReport: CostReport;
  isLoading: boolean;
}

export function CostReportView({ costReport, isLoading }: IProps) {
  if (isLoading || !costReport) {
    return <Loader loading={isLoading} />;
  }

  const dataFeed = costReport && costReport['data-feed'];
  const pushNotifications = costReport && costReport['push-notification'];

  return (
    <>
      {costReport.service && costReport.service.length > 0 && (
        <div className="row">
          <div className="col-1-of-2">
            <CostReportPerService reports={costReport.service} />
          </div>
          <div className="col-1-of-2">
            <PieChart reports={costReport.service} />
          </div>
        </div>
      )}
      {costReport.core && costReport.core.length > 0 && (
        <div className="row">
          <div className="col-1-of-2">
            <UsagePerApps
              reports={costReport.core}
              title={text.en['core-usage-per-app']}
              label={text.en['core-label']}
            />
          </div>
        </div>
      )}
      {pushNotifications && pushNotifications.length > 0 && (
        <div className="row">
          <div className="col-1-of-2">
            <UsagePerApps
              reports={pushNotifications}
              title={text.en['notifications-per-app']}
              label={text.en['notifications-label']}
            />
          </div>
        </div>
      )}
      {dataFeed && dataFeed.length > 0 && (
        <div className="row">
          <div className="col-1-of-2">
            <UsagePerApps
              reports={dataFeed}
              title={text.en['feeds-per-app']}
              label={text.en['feeds-label']}
            />
          </div>
        </div>
      )}
    </>
  );
}
