import * as actionTypes from './actionTypes';

export const fetchUserFail = () => {
  return { type: actionTypes.FETCH_USER_FAIL };
};

export const fetchUserSuccess = (user) => {
  return {
    type: actionTypes.FETCH_USER_SUCCESS,
    user,
  };
};

export const resetUser = () => {
  return { type: actionTypes.RESET_USER };
};

export const setTemporaryUser = (user) => {
  return { type: actionTypes.SET_TEMPORARY_USER, user };
};

export const acceptInviteSuccess = () => {
  return { type: actionTypes.ACCEPT_INVITE_SUCCESS };
};
