import {
  CHANGE_APPLICATIONS_PAGE,
  CHANGE_USERS_PAGE,
} from '../actions/actionTypes';
import { PaginationState } from '../../shared/types/store';

const initialState: PaginationState = {
  applicationsPage: 0,
  usersPage: 0,
};

const pagination = (state, { type, payload }) => {
  if (typeof state === 'undefined') {
    return initialState;
  }

  switch (type) {
    case CHANGE_APPLICATIONS_PAGE: {
      return {
        ...state,
        applicationsPage: payload,
      };
    }
    case CHANGE_USERS_PAGE: {
      return {
        ...state,
        usersPage: payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default pagination;
