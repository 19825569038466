import React from 'react';

export function Usage(): JSX.Element {
  return (
    <>
      <h2>5. How the MDP May be Used</h2>
      <h3>5.1 Application Development</h3>
      <p>
        You agree to use the MDP and write applications with the SDK included
        only for purposes that are permitted by (a) this Agreement and (b) any
        applicable law, regulation or generally accepted practices or guidelines
        in the relevant jurisdictions (including any laws regarding the export
        of data or software to and from Switzerland or other relevant
        countries).
      </p>
      <h3>5.2 Personal Information</h3>
      <p>
        You agree that if you use the MDP or SDK to develop applications for
        general public users, you will protect the privacy and legal rights of
        those users. If the users provide you with user names, passwords, or
        other login information or personal information, you must make the users
        aware that the information will be available to your application, and
        you must provide legally adequate privacy notice and protection for
        those users. If your application stores personal or sensitive
        information provided by users, it must do so securely.
      </p>
      <h3>5.3 Damaging Activities</h3>
      <p>
        You agree that you will not engage in any activity with the MDP and SDK,
        including the development or distribution of an application that
        interferes with, disrupts, damages, or accesses in an unauthorized
        manner the servers, networks, or other properties or services of any
        third party including, but not limited to, Sportradar or any mobile
        communications carrier.
      </p>
      <h3>5.4 Data Responsibility</h3>
      <p>
        You agree that you are solely responsible for (and that Sportradar has
        no responsibility to you or to any third party for) any data, content,
        or resources that you create, transmit or display through Sportradar’s
        software or SDK, and/or applications for Sportradar’s software and for
        the consequences of your actions (including any loss or damage which
        Sportradar may suffer) by doing so.
      </p>
      <h3>5.5 Portal Scraping and Probing</h3>
      <p>
        You may not use any “deep-link”, “page-scrape”, “robot”, “spider” or
        other automatic device, program, algorithm or methodology, or any
        similar or equivalent manual process, to access, acquire, copy or
        monitor any portion of the Portal or any Content, or in any way
        reproduce or circumvent the navigational structure or presentation of
        the Portal or any Content, to obtain or attempt to obtain any materials,
        documents or information through any means not purposely made available
        through the Portal or Service. Sportradar reserves the right to bar any
        such activity.
      </p>
      <p>
        You may not attempt to gain unauthorized access to any portion or
        feature of the Portal or to any of the services offered on or through
        the Portal, by hacking, cracking, password “mining” or any other
        illegitimate means.
      </p>
      <p>
        You may not probe, scan or test the vulnerability of the Portal or any
        network connected to the Portal, nor breach the security or
        authentication measures on the Portal or any network connected to the
        Portal. You may not reverse look-up, trace or seek to trace any
        information on any other user of or visitor to the Portal, or any other
        customer of Sportradar to its source, or exploit the Portal or any
        service or information made available or offered by or through the
        Portal, in any way where the purpose is to reveal any information,
        including but not limited to personal identification or information,
        other than your own information, as provided for by the Portal.
      </p>
      <p>
        You agree that you will not take any action that imposes an unreasonable
        or disproportionately large load on the infrastructure of the Portal or
        Sportradar’s systems.
      </p>
      <p>
        You agree not to use any device, software or routine to interfere or
        attempt to interfere with the proper working of the Portal or any
        transaction being conducted on the Portal, or with any other person’s
        use of the Portal.
      </p>
      <p>
        You may not use the Portal or any Content or Service provided by
        Sportradar for any purpose that is unlawful or prohibited by these Terms
        of Use, or to solicit the performance of any illegal activity or other
        activity which infringes the rights of Sportradar or others.
      </p>
    </>
  );
}
