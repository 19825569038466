import * as actionTypes from './actionTypes';
import { hideModal, modalError, modalUploadSucces } from './modals';
import { fetchApp } from './applications';
import text from '../../shared/translations/en';
import { toastr } from 'react-redux-toastr';
import { API } from 'aws-amplify';

const UPDATE_SUCCESS = 'update-success';

let errorObj = {
  field: null,
  message: null,
};

export const fetchApnTokensFail = () => {
  return { type: actionTypes.FETCH_APN_TOKENS_FAIL };
};

export const fetchApnTokensSucces = (apnTokens) => {
  return {
    type: actionTypes.FETCH_APN_TOKENS_SUCCESS,
    apnTokens,
  };
};

export const getApnTokens = (appId) => {
  return (dispatch) => {
    API.get('mdp', `/apnToken/${appId}`, {})
      .then((response) => {
        dispatch(fetchApnTokensSucces(response));
      })
      .catch(() => {
        dispatch(fetchApnTokensFail());
      });
  };
};

export const uploadApnToken = (id, obj, file) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('modeType', obj.modeType);
  formData.append('platform', obj.platform);
  formData.append('keyId', obj.keyId);
  formData.append('teamId', obj.teamId);
  formData.append('appId', id);

  return (dispatch) => {
    API.post('mdp', `/apnToken`, {
      body: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(() => {
        dispatch(handleSuccess(id));
      })
      .catch((error) => {
        if (
          error.response.data.errors &&
          error.response.data.errors.length > 0
        ) {
          errorObj = {
            field: error.response.data.errors[0].field,
            message: error.response.data.errors[0].message,
          };
          toastr.error(error.response.data.errors[0].message);
        } else {
          errorObj = {
            field: null,
            message: error.response.data.message,
          };
          toastr.error(error.response.data.message);
        }
        dispatch(modalError(errorObj));
      });
  };
};

const handleSuccess = (id) => {
  return (dispatch) => {
    dispatch(modalUploadSucces());
    dispatch(fetchApp(id, undefined));
    toastr.success(text.en.success, text.en[UPDATE_SUCCESS]);
  };
};

export const setApnToken = (id, obj) => {
  return (dispatch) => {
    API.put('mdp', `/apnToken/${id}`, { body: obj })
      .then(() => {
        dispatch(handleSuccess(id));
      })
      .catch((error) => {
        // display message from errors array
        errorObj = {
          field: null,
          message: error.response.data.message,
        };
        dispatch(modalError(errorObj));
      });
  };
};

export const removeTokenFromApp = (id, obj) => {
  return (dispatch) => {
    API.del('mdp', `/apnToken/${id}`, { body: obj })
      .then(() => {
        dispatch(handleSuccess(id));
      })
      .catch((error) => {
        dispatch(hideModal());
        toastr.error('Error!', error.response.data.message);
      });
  };
};
