import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectInviteSuccess, selectUser } from '../../store/selectors/user';
import { acceptInvite } from '../../store/actions/applications';
import { Navigate, useParams } from 'react-router-dom';

export const ApplicationInvitation: FC = () => {
  const { appId } = useParams();

  const dispatch = useDispatch();

  const user = useSelector(selectUser);
  const acceptInviteStatus = useSelector(selectInviteSuccess);

  const [redirect, setRedirect] = useState<string>();
  const [hasBeenCalled, setHasBeenCalled] = useState<boolean>(false);

  useEffect(() => {
    if (appId && user && !hasBeenCalled) {
      setHasBeenCalled(true);
      dispatch(acceptInvite(appId));
    }
  }, [user, hasBeenCalled]);

  useEffect(() => {
    acceptInviteStatus && setRedirect(`/application/${appId}`);
  }, [acceptInviteStatus]);

  if (redirect) {
    return <Navigate to={redirect} />;
  }
  return <></>;
};
