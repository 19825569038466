import * as actionTypes from './actionTypes';

export const changeApplicationsPage = (page) => ({
  type: actionTypes.CHANGE_APPLICATIONS_PAGE,
  payload: page,
});

export const changeUsersPage = (page) => ({
  type: actionTypes.CHANGE_USERS_PAGE,
  payload: page,
});
