import React from 'react';

export function Termination(): JSX.Element {
  return (
    <>
      <h2>11. Termination</h2>
      <p>
        Sportradar may terminate this agreement immediately if you breach any of
        its terms. Sections 7, 9, 10, 12, 13, 16 will survive any termination of
        this agreement. Upon termination of this agreement you will cease all
        use of MDP, Application Keys and distribution of the SDK, destroy (with
        written confirmation) or return to Sportradar any access credentials
        (ID, password), and copies of the Mobile SDK in your possession, and
        stop distributing applications with the Mobile SDK built in, and remove
        your applications from application stores where they might be
        distributed.
      </p>
    </>
  );
}
