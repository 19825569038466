import { get, isEqual } from 'lodash';
import { Platform, PlatformSettings } from '../shared/types/application';
import { Cell, Column } from '../shared/types/table';

export function createTable(
  tableData: Column[],
  selectedPlatformItems: string[],
  selectedEnvironmentItems: string[],
  environments: string[],
  platforms: string[],
  platformSettings: Platform[]
) {
  // Note: keep only default titles, others generate once again
  const data = tableData.filter(
    (dataItem) => ['key', 'default'].indexOf(dataItem.id) > -1
  );

  const loopPlatforms = getItems(selectedPlatformItems, platforms);
  const loopEnvironments = getItems(selectedEnvironmentItems, environments);

  loopPlatforms.forEach((platform) => {
    loopEnvironments.forEach((environment) => {
      const title = `${platform} ${environment}`;
      const id = `${platform.split(' ').join('+').toLowerCase()}_${environment
        .split(' ')
        .join('+')
        .toLowerCase()}`;

      if (
        platformSettings &&
        platformSettings[platform] &&
        platformSettings[platform][environment]
      ) {
        data.push({
          id,
          text: title,
          values: [],
          platform,
          modeType: environment,
        });
      }
    });
  });

  return data;
}

function getItems(selectedItems: string[], items: string[]) {
  return selectedItems.indexOf('All') > -1
    ? items.filter((item) => item !== 'All')
    : selectedItems;
}

export function isDefaultValueColumn(
  item: Column,
  itemIndex: number,
  setting: PlatformSettings
) {
  return (
    item.id === 'default' &&
    itemIndex > -1 &&
    !setting.platform &&
    !setting.modeType
  );
}

export function addTableKeys(item: Column, setting: PlatformSettings) {
  let alreadyAdded = false;

  item.values.forEach((val) => {
    if (val.value === setting.key) {
      alreadyAdded = true;
    }
  });

  if (!alreadyAdded) {
    item.values.push({
      value: setting.key,
    });
  }
}

export function getKeyIndex(data: Column[], setting: PlatformSettings) {
  let itemIndex = -1;

  data[0].values.forEach((value: Cell, ix: number) => {
    if (value.value === setting.key) {
      itemIndex = ix;
    }
  });

  return itemIndex;
}

export function isModeOrPlatform(
  setting: PlatformSettings,
  item: Column,
  itemIndex: number
) {
  return (
    itemIndex > -1 &&
    setting.platform &&
    setting.platform === item.platform &&
    setting.modeType &&
    setting.modeType === item.modeType
  );
}

export function addPlaceholders(
  data: Column[],
  item: Column,
  itemIndex: number,
  isPlaceholder: boolean
) {
  // If there is no real value we will put placeholder with default value if exists otherwise we leave it empty
  if (isPlaceholder && item.values[itemIndex] === undefined) {
    const defaultIndex = 1;
    const defaultValue = data[defaultIndex].values[itemIndex]
      ? data[defaultIndex].values[itemIndex].value
      : '';
    item.values[itemIndex] = {
      placeholder: defaultValue,
    };
  }
}

export function handleLastRow(data: Column[], item: Column) {
  // Always make empty row at the end if it doesn't already exists
  if (
    !item.values[item.values.length - 1] ||
    item.values[item.values.length - 1].value !== ''
  ) {
    item.values.push({
      value: '',
    });
  }
}

export function fillMissingCells(data: Column[]) {
  const keysCount = data[0].values.length;
  data.forEach((item) => {
    if (item.values.length < keysCount) {
      const addCount = keysCount - item.values.length;
      for (let i = 0; i < addCount; i++) {
        // In case it is last row we always fill it with empty placeholders
        if (addCount - 1 === i) {
          item.values.push({
            value: '',
          });
        } else {
          // In case it is not we fill it with undefined so later can be displayed as
          // placeholders with default value
          item.values.push({
            value: undefined,
          });
        }
      }
    }
  });
}

export function updateKeys(
  val: Cell,
  updateSettings: (settings: PlatformSettings[]) => void,
  settings: PlatformSettings[],
  tableData: Column[],
  setTableData: (data: Column[]) => void
) {
  // Note if key was updated
  const removeKeys = [];
  let updatedSettings = [...settings];
  settings.forEach((setting, settingId) => {
    if (val.feedKey === setting.key) {
      if (val.value === '') {
        // Note here we fill remove keys from feed which have current key empty
        removeKeys.push(val.feedKey);

        // Remove the whole row from table if key was deleted
        removeRowFromTableData(settingId, tableData, setTableData);
      } else {
        const newSetting = {
          ...setting,
        };

        newSetting.key = val.value;

        updatedSettings[settingId] = newSetting;
      }
    }
  });

  // Note here we filter out all keys which are currently without key-value
  updatedSettings = updatedSettings.filter(
    (setting) => removeKeys.indexOf(setting.key) === -1
  );

  // Note comparison is made because we dont wanna update settings if there is only key without any values
  if (!isEqual(updatedSettings, settings)) {
    updateSettings(updatedSettings);
  }
}

const removeRowFromTableData = (
  valueIndex: number,
  tableData: Column[],
  setTableData: (data: Column[]) => void
) => {
  // Remove the whole row from table if key was deleted
  tableData.forEach((item) => {
    item.values.splice(valueIndex, 1);
  });

  setTableData(tableData);
};

export function updateValues(
  column: Column,
  key: string,
  val: Cell,
  updateSettings: (settings: PlatformSettings[]) => void,
  settings: PlatformSettings[]
) {
  const data = {
    key: key,
    value: val.value,
    platform: get(val, 'platform', null),
    modeType: get(val, 'modeType', null),
  };

  if (!val.value || !val.value.length) {
    // Note if value is empty remove everything with same key mode and platform from settings
    // only in case of default value we filter out and add new value even if it is empty string

    const removeSettings = [];
    settings.forEach((setting, ix) => {
      if (
        setting.key === key &&
        setting.modeType === data.modeType &&
        setting.platform === data.platform
      ) {
        removeSettings.push(ix);
      }
    });

    const filteredSettings = settings.filter(
      (_, ix) => removeSettings.indexOf(ix) === -1
    );

    updateSettings(
      column.id !== 'default' ? filteredSettings : [...filteredSettings, data]
    );
  } else {
    // Note we check if there is the same key, mode and platform and if it is we update current value
    // if there is no existing record we attach new data obj
    let alreadyExists = false;
    const newSettings: PlatformSettings[] = JSON.parse(
      JSON.stringify(settings)
    );
    newSettings.forEach((setting) => {
      if (
        setting.key === data.key &&
        setting.modeType === data.modeType &&
        setting.platform === data.platform
      ) {
        setting.value = data.value;
        alreadyExists = true;
      }
    });

    updateSettings(alreadyExists ? newSettings : [...newSettings, data]);
  }
}
