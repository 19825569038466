import * as actionTypes from '../actions/actionTypes';
import { ApnToken } from '../../shared/types/store';

const initialState: ApnToken[] = [];

const fetchApnTokensFail = (state, action) => {
  return action.type;
};

const fetchApnTokensSucces = (state, action) => {
  return action.apnTokens;
};

const apnTokens = (state, action) => {
  if (typeof state === 'undefined') {
    return initialState;
  }

  switch (action.type) {
    case actionTypes.FETCH_APN_TOKENS_SUCCESS:
      return fetchApnTokensSucces(state, action);
    case actionTypes.FETCH_APN_TOKENS_FAIL:
      return fetchApnTokensFail(state, action);
    default:
      return state;
  }
};

export default apnTokens;
