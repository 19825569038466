import { IconButton, InputAdornment, OutlinedInput } from '@mui/material';
import {
  PdsClose2Icon,
  PdsSearchIcon,
} from '@podium-design-system/react-icons';
import React, { ChangeEvent, useState } from 'react';
import text from '../../../shared/translations/en';

const styles = {
  boxShadow: 'none',
  borderRadius: 'none',
  border: '1px solid #D4D4D4',
  width: '100%',
  backgroundColor: '#fff',
};

interface IProps {
  search: (value: string) => void;
  term: string;
}

export function SearchBarComponent({ search, term }: IProps) {
  const [searchQuery, setSearchQuery] = useState(term ?? '');

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
    search(event.target.value);
  };

  const clearSearch = () => {
    setSearchQuery('');
    search('');
  };

  return (
    <div style={{ flex: 1 }}>
      <OutlinedInput
        value={searchQuery}
        onChange={handleSearch}
        placeholder={text.en['search-placeholder']}
        style={styles}
        endAdornment={
          <InputAdornment position="end">
            <IconButton onClick={clearSearch}>
              {searchQuery !== '' ? (
                <PdsClose2Icon size="lg" />
              ) : (
                <PdsSearchIcon size="lg" />
              )}
            </IconButton>
          </InputAdornment>
        }
      />
    </div>
  );
}
