import React, { ChangeEventHandler } from 'react';

interface IProps {
  label: string;
  identifier: string;
  changeHandler: ChangeEventHandler<HTMLInputElement>;
  inputValue: string;
}

export function CustomInput({
  label,
  identifier,
  changeHandler,
  inputValue,
}: IProps): JSX.Element {
  return (
    <div className="input-row__wrapper">
      <label className="input-row__label" htmlFor="">
        {label}
      </label>
      <input
        name={identifier}
        type="text"
        className="input-row__input u-margin-bottom-8 "
        onChange={changeHandler}
        value={inputValue}
      />
    </div>
  );
}
