import React from 'react';

export function MDPUsage(): JSX.Element {
  return (
    <>
      <h2>10. Using the MDP</h2>
      Sportradar Mobile SDK
      <h3>10.1 Derivative Works</h3>
      <p>
        If you use any Sportradar SDK to retrieve data from Sportradar, you
        acknowledge that the data may be protected by intellectual property
        rights which are owned by Sportradar or those parties that provide the
        data (or by other persons or companies on their behalf). Your use of any
        such SDK may be subject to additional Terms of Service. You may not
        modify, rent, lease, loan, sell, distribute or create derivative works
        based on this data (either in whole or in part) unless allowed by the
        relevant Terms of Service.
      </p>
      <h3>10.2 Explicit User Consent</h3>
      <p>
        If you use any Sportradar SDK to retrieve a user's data from Sportradar,
        you acknowledge and agree that you shall retrieve data only with the
        user's explicit consent and only when, and for the limited purposes for
        which, the user has given you permission to do so.
      </p>
    </>
  );
}
