import React from 'react';
import { LoadingComponent } from '../UI/LoadingComponent';
import { useSelector } from 'react-redux';
import { selectApplication } from '../../store/selectors/application';
import { selectUser } from '../../store/selectors/user';
import { AppSidebarTabs } from './AppSidebarTabs';
import { SidebarAppInfo } from './SidebarAppInfo';

export function AppSidebar(): JSX.Element {
  const app = useSelector(selectApplication);
  const user = useSelector(selectUser);

  return Object.keys(app).length > 0 ? (
    <aside className="app-sidebar">
      <div className="app-sidebar--box">
        <SidebarAppInfo app={app} user={user} />
      </div>
      <ul className="app-sidebar--list">
        <AppSidebarTabs user={user} />
      </ul>
    </aside>
  ) : (
    <LoadingComponent />
  );
}
