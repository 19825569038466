import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectUsageImage } from '../../store/selectors/usage';
import { Footer } from '../../components/Footer/Footer';
import text from '../../shared/translations/en';
import { getImageFromURL } from '../../store/actions/admin';
import { USAGE_IMAGES_ENUM } from '../../shared/types/admin';

export default function Dashboard(): JSX.Element {
  const dispatch = useDispatch();

  const usageImage = useSelector(selectUsageImage);

  useEffect(() => {
    dispatch(getImageFromURL('notifications', USAGE_IMAGES_ENUM.PUSH));
    dispatch(getImageFromURL('logins', USAGE_IMAGES_ENUM.LOGIN));
  }, []);

  return (
    <main className="layout__main">
      <div className="dashboard">
        <div className="content__wrapper">
          <h1>{text.en['app-usage']}</h1>
          {usageImage[USAGE_IMAGES_ENUM.LOGIN] && (
            <div className="chart-box">
              <div className="chart-box__title">
                {text.en['app-usage-login']}
              </div>
              <div className="chart-box__content">
                <img
                  alt="loginCount"
                  src={usageImage[USAGE_IMAGES_ENUM.LOGIN]}
                  width="100%"
                />
              </div>
            </div>
          )}
          {usageImage[USAGE_IMAGES_ENUM.PUSH] && (
            <div className="chart-box">
              <div className="chart-box__title">{text.en['app-usage-pn']}</div>
              <div className="chart-box__content">
                <img
                  alt="pushCount"
                  src={usageImage[USAGE_IMAGES_ENUM.PUSH]}
                  width="100%"
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="layout__footer">
        <Footer />
      </div>
    </main>
  );
}
