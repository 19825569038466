import React from 'react';

export function Privacy(): JSX.Element {
  return (
    <>
      <h2>8. Privacy Notice</h2>
      <h3>8.1 Introduction</h3>
      Sportradar MDP respects your privacy and personal data. This Privacy
      Notice (hereinafter referred to as "Notice") explains how we process your
      personal data when you browse our webisite. Personal data means any
      information which allows Sportradar MDP to directly or indirectly identify
      you. The personal data collected and processed by Sportradar MDP during
      your browsing our webisite will be processed in accordance with the EU
      General Data Protection Regulation (hereinafter referred to as the "GDPR")
      and with any other applicable privacy and data protection laws. The
      Sportradar MDP Webiste may contain links to websites of third-parties
      which are not covered by this privacy Notice. Sportradar MDP does not
      accept any responsibilities or liabilities for third-party websites.
      <h3>8.2 What Personal Data Do We Collect?</h3>
      When registering for our services, we may collect any information in which
      you can be identified. You can find below the personal data which we
      collect automatically, the personal data which is provided to us and the
      personal data which is collected from other sources. When providing us
      with your personal data, Sportradar MDP kindly requests you to refrain
      from supplying us with any additional unsolicited personal data. In
      particular, Sportradar MDP requests you to refrain from supplying us with
      personal data relating to:
      <ul>
        <li>Your racial and ethnic origin, and/or;</li>
        <li>Your political opinions, and/or;</li>
        <li>Your religious and philosophical beliefs, and/or;</li>
        <li>Your trade union membership, and/or;</li>
        <li>Data concerning your health, and/or;</li>
        <li>Data concerning your sex life or sexual orientation.</li>
      </ul>
      <h3>8.3 How do we secure your data?</h3>
      Sportradar MDP uses all available technical and organizational security
      measures in order to protect your personal data against a breach of
      security leading to the accidental or unlawful destruction, loss,
      alteration, unauthorized disclosure of, or access to, your personal data
      transmitted, stored or otherwise processed. Sportradar MDP employees are
      required to keep personal data confidential.
      <h3>8.4 What are my rights?</h3>
      Sportradar MDP reserves the right to refuse to give effect to a
      petitioner’s request in the event that we are unable to ascertain your
      identity.
      <ul>
        <li>
          <b>Right to Access</b>
          <br />
          You have the right to obtain from Sportradar MDP a confirmation of
          whether Sportradar MDP is processing your data. Should Sportradar MDP
          process your data, you have the right to access your personal data and
          to obtain further information regarding the processing of your
          personal data.
        </li>
        <li>
          <b>Right to Rectification</b>
          <br />
          You have the right to have your personal data rectified by Sportradar
          MDP in the event that your personal data is inaccurate. You may also
          rectify the information by reviewing and changing the personal
          information on your account settings.
        </li>
        <li>
          <b>Right to Erasure</b>
          <br />
          Under certain conditions, you have the right to have your personal
          data erased without delay. Those conditions are listed at article 17
          of the GDPR.
        </li>
        <li>
          <b>Right to Restriction of Processing</b>
          <br />
          Under certain conditions, you have the right to restrict the
          processing of your personal data. Those conditions are listed at
          article 18 of the GDPR.
        </li>
        <li>
          <b>Right to Object to Processing</b>
          <br />
          Under certain conditions, you have the right to object to the
          processing of your personal data by Sportradar MDP. Those conditions
          are listed at article 21 of the GDPR.
        </li>
        <li>
          <b>Right to Data Portability</b>
          <br />
          You have the right to obtain your personal data in a structured,
          commonly used machine-readable format and to have your personal data
          transferred to another controller upon your request, and where
          technically feasible insofar as the processing of your personal data
          relies on your consent or it is necessary for the performance of a
          contract between you and Sportradar MDP.
        </li>
        <li>
          <b>Right to Complain To a Supervisory Authority</b>
          <br />
          Should you believe that Sportradar MDP has infringed the applicable
          data protection laws, you have the right to file a complaint before
          the concerned Data Protection Authority.
        </li>
        <li>
          <b>Right to Withdraw Consent</b>
          <br />
          You may withdraw your consent to the processing of your personal data
          insofar as the processing relies on your consent. This does not apply
          if the processing is required for the performance of a contract
          between Sportradar MDP and you or if the processing is based on
          Sportradar MDP legitimate interests.
        </li>
      </ul>
      <h3>8.4 Policy Changes</h3>
      Sportradar MDP reserves the right, at its discretion, to update, change,
      modify, add or amend this Policy by publishing a new version on our
      Website.
      <h3>8.5 Contact</h3>
      Should you have any questions regarding the processing of your personal
      data or should you wish to exercise a right as provided in Section of this
      Policy, please contact our Data Protection Officer at mdp@sportradar.com.
    </>
  );
}
