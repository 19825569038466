import _ from 'lodash';

function isOS() {
  return navigator.userAgent.match(/ipad|iphone/i);
}

function isMobile() {
  return navigator.userAgent.match(
    /Android|webOS|BlackBerry|IEMobile|Opera Mini/i
  );
}

/**
 * This function constructs object from URL
 **/

function getSearchQuery(query) {
  const obj = {};
  if (!query.length) {
    return query;
  }

  query = query.split('?');
  query.shift();

  query = query[0].split('&');
  query.forEach((q) => {
    const queryArray = q.split('=');
    obj[queryArray[0]] = queryArray[1];
  });
  return obj;
}

/**
 * This function constructs URL search query
 **/

function constructSearchQuery(obj) {
  if (_.isEmpty(obj)) {
    return '';
  }

  let search = '?';
  Object.keys(obj).forEach((k) => {
    if (search.length > 2) {
      search += '&';
    }
    search += `${k}=${obj[k]}`;
  });

  return search;
}

export { isOS, isMobile, getSearchQuery, constructSearchQuery };
