import text from '../shared/translations/en';

export const NOTIFICATION_PLAN_WILL_EXPIRE: Notification = {
  id: 'your-plan-will-expire',
  text: text.en['your-plan-will-expire-soon'],
  type: 'warning',
};
export const NOTIFICATION_PLAN_EXPIRED: Notification = {
  id: 'your-plan-is-expired',
  text: text.en['your-plan-is-expired'],
  type: 'error',
};
export const NOTIFICATION_SET_PLAN: Notification = {
  id: 'set-your-plan',
  text: text.en['set-your-plan'],
  type: 'warning',
};

export interface Notification {
  id: string;
  text: string;
  type: string;
}
