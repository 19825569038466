import { FormControl, TextField } from '@mui/material';
import React, { ChangeEvent, useCallback, useState } from 'react';
import { FormGroup } from 'semantic-ui-react';

interface IProps {
  keyId: string;
  setKeyId: (key: string) => void;
  teamId: string;
  setTeamId: (team: string) => void;
  setFile: (file: File) => void;
}

export function NewToken({
  keyId,
  setKeyId,
  teamId,
  setTeamId,
  setFile,
}: IProps): JSX.Element {
  const [fileName, setFileName] = useState<string>();

  const handleFileChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const uploadedFile = event.target.files[0];
      setFile(uploadedFile);
      setFileName(uploadedFile.name);
    },
    []
  );

  return (
    <FormGroup row>
      <FormControl style={{ width: '100%' }}>
        <div style={{ marginBottom: 16 }}>
          <input
            type="file"
            id="apnToken"
            className="formDialog--input"
            onChange={(event) => handleFileChange(event)}
          />
          <label htmlFor="apnToken" className="formDialog--label">
            {!fileName ? 'Choose file' : fileName ?? 'Choose file'}
          </label>
        </div>
        <TextField
          variant="outlined"
          label="Key id"
          onChange={(e) => setKeyId(e.target.value)}
          value={keyId}
        />
        <br />
        <TextField
          variant="outlined"
          label="Team id"
          onChange={(e) => setTeamId(e.target.value)}
          value={teamId}
        />
      </FormControl>
    </FormGroup>
  );
}
