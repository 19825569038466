import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';
import { ProfileState } from '../../shared/types/store';

const initialState: ProfileState = {
  user: undefined,
  error: false,
  loading: false,
  temporaryUser: undefined,
  acceptInvite: false,
  success: false,
};

const fetchUserSuccess = (state, action) => {
  return updateObject(state, {
    user: action.user,
    loading: false,
    error: false,
    success: false,
  });
};

const fetchUserFail = (state) => {
  return updateObject(state, {
    loading: false,
    error: true,
    user: null,
  });
};

const resetUser = (state) => {
  return updateObject(state, {
    user: undefined,
  });
};

const setTemporaryUser = (state, action) => {
  return updateObject(state, {
    temporaryUser: action.user,
  });
};

const acceptInviteSuccess = (state) => {
  return updateObject(state, {
    acceptInvite: true,
  });
};

const user = (state, action) => {
  if (typeof state === 'undefined') {
    return initialState;
  }

  switch (action.type) {
    case actionTypes.FETCH_USER_SUCCESS:
      return fetchUserSuccess(state, action);
    case actionTypes.FETCH_USER_FAIL:
      return fetchUserFail(state);
    case actionTypes.RESET_USER:
      return resetUser(state);
    case actionTypes.SET_TEMPORARY_USER:
      return setTemporaryUser(state, action);
    case actionTypes.ACCEPT_INVITE_SUCCESS:
      return acceptInviteSuccess(state);
    default:
      return state;
  }
};

export default user;
