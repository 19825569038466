import React, { FC, useCallback, useState } from 'react';
import { Footer } from '../../../components/Footer/Footer';
import { Link, Navigate, useParams } from 'react-router-dom';
import srLogo from '../../../assets/images/SR_logo_basic.png';
import text from '../../../shared/translations/en';
import {
  PdsEyePrivacyViewIcon,
  PdsEyePublicViewIcon,
} from '@podium-design-system/react-icons';
import { Amplify, Auth } from 'aws-amplify';
import { useSelector } from 'react-redux';
import { selectConfig } from 'store/selectors/config';
import { toastr } from 'react-redux-toastr';

interface MatchProps {
  params: {
    email: string;
  };
}
export const PasswordReset: FC = () => {
  const [passwordShown, setPasswordShown] = useState<boolean>(false);
  const [repeatPasswordShown, setRepeatPasswordShown] =
    useState<boolean>(false);
  const [password, setPassword] = useState<string>('');
  const [repeatPassword, setRepeatPassword] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const [redirect, setRedirect] = useState<string>();

  const { email } = useParams();

  const config = useSelector(selectConfig);

  const togglePassword = useCallback(
    (index) =>
      index
        ? setRepeatPasswordShown(!repeatPasswordShown)
        : setPasswordShown(!passwordShown),
    [passwordShown, repeatPasswordShown]
  );

  const handleResetPassword = useCallback(async () => {
    try {
      Amplify.configure({
        Auth: config?.Auth.customer,
        API: config?.API,
      });

      if (password === repeatPassword) {
        await Auth.forgotPasswordSubmit(email, code, password);
        setRedirect('/manual-login');
      } else {
        toastr.error('Passwords must match!');
      }
    } catch (e) {
      toastr.error((e as Error).message);
    }
  }, [password, repeatPassword]);

  if (redirect) {
    return <Navigate to={redirect} />;
  }

  return (
    <main className="layout__main sr-forms">
      <div className="content password--reset sr-forms-content">
        <div className="password--reset-form sr-forms-component">
          <div className="sr-forms-title">
            <span className="sr-logo">
              <Link to="/">
                <img src={srLogo} alt="Sportradar" />
              </Link>
            </span>
            <span className="sr-forms-mdp-brand">MDP</span>
          </div>
          <div className="password--reset-form-box sr-forms-form">
            <h3>{text.en['set-password']}</h3>
            <div className="u-margin-top-8">
              <div className="sr-forms-input-container">
                <label className="u-margin-top-8">{text.en.code}*</label>
                <input
                  type="text"
                  className={`input ${code.length ? '' : 'srm-empty'}`}
                  data-test="input-code"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  placeholder={text.en['code-placeholder']}
                />
              </div>
            </div>
            <div className="u-margin-top-8">
              <div className="sr-forms-input-container">
                <label className="u-margin-top-8">{text.en.password}*</label>
                <input
                  type={passwordShown ? 'text' : 'password'}
                  className={`input ${password.length ? '' : 'srm-empty'}`}
                  data-test="input-password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder={text.en['password-placeholder']}
                />
                <div
                  className="toggle-password"
                  onClick={() => togglePassword(0)}
                >
                  {passwordShown ? (
                    <PdsEyePrivacyViewIcon />
                  ) : (
                    <PdsEyePublicViewIcon />
                  )}
                </div>
              </div>
            </div>
            <div className="u-margin-top-8">
              <div className="sr-forms-input-container">
                <label className="u-margin-top-8">
                  {text.en['repeat-password']}*
                </label>
                <input
                  type={repeatPasswordShown ? 'text' : 'password'}
                  className={`input ${
                    repeatPassword.length ? '' : 'srm-empty'
                  }`}
                  data-test="input-password"
                  value={repeatPassword}
                  onChange={(e) => setRepeatPassword(e.target.value)}
                  placeholder={text.en['password-placeholder']}
                />
                <div
                  className="toggle-password"
                  onClick={() => togglePassword(1)}
                >
                  {repeatPasswordShown ? (
                    <PdsEyePrivacyViewIcon />
                  ) : (
                    <PdsEyePublicViewIcon />
                  )}
                </div>
              </div>
            </div>
            <button
              className="sr-btn sr-btn__lg u-btn-right btn-dark-blue u-margin-top-32"
              onClick={handleResetPassword}
            >
              {text.en['reset-password']}
            </button>
          </div>
        </div>
      </div>
      <div className="layout__footer">
        <Footer />
      </div>
    </main>
  );
};
