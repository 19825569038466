import React, { useEffect, useState } from 'react';
import { PlatformEnum, PlatformOptions } from '../../shared/types/application';
import text from '../../shared/translations/en';
import { addPlatforms } from '../../store/actions/applications';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../../store/selectors/user';

const defaultModalOptions: PlatformOptions = {
  'development-environment': {
    type: 'checkbox',
    checked: false,
    value: text.en['env-dev'],
  },
  'production-environment': {
    type: 'checkbox',
    checked: false,
    value: text.en['env-prod'],
  },
  'custom-environment': {
    type: 'text',
    checked: false,
    value: '',
  },
};

interface IProps {
  selectedPlatform: PlatformEnum;
  handleCloseDialog: (
    ev: React.MouseEvent<HTMLElement>,
    forceClose: boolean
  ) => void;
}

export function PlatformModal({
  selectedPlatform,
  handleCloseDialog,
}: IProps): JSX.Element {
  const dispatch = useDispatch();

  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [modalOptions, setModalOptions] =
    useState<PlatformOptions>(defaultModalOptions);
  const [inputValue, setInputValue] = useState<string>('');

  const user = useSelector(selectUser);
  const customEnvironmentString = 'custom-environment';

  const checkDisabled = () => {
    let count = 0;
    Object.keys(modalOptions).forEach((optionKey) => {
      const option = modalOptions[optionKey];
      const isCustom = optionKey === customEnvironmentString;
      if (option.checked && !(isCustom && option.value === '')) {
        count++;
      }
    });
    setIsDisabled(count <= 0);
  };

  useEffect(() => {
    checkDisabled();
  }, [modalOptions, inputValue]);

  useEffect(() => {
    const optionKey = Object.keys(modalOptions).find(
      (key) => key === customEnvironmentString
    );
    const copyModalOptions = modalOptions;
    if (optionKey) {
      copyModalOptions[optionKey].value = inputValue;
    }
    setModalOptions(copyModalOptions);
  }, [inputValue, modalOptions]);

  const createPlatforms = () => {
    const newPlatforms = [];

    Object.keys(modalOptions).forEach((optionKey) => {
      const option = modalOptions[optionKey];

      if (!option.checked) {
        return;
      }

      const newPlatform = {
        platform: selectedPlatform,
        mode: option.value,
      };

      newPlatforms.push(newPlatform);

      // Reset values in case platform's are created
      modalOptions[optionKey].checked = false;
      if (optionKey === customEnvironmentString) {
        modalOptions[optionKey].value = '';
      }
    });

    dispatch(addPlatforms(newPlatforms, user));
    handleCloseDialog(null, true);
  };

  const selectMode = (
    e: React.ChangeEvent<HTMLInputElement>,
    optionKey: string
  ) => {
    setModalOptions({
      ...modalOptions,
      [optionKey]: {
        ...modalOptions[optionKey],
        checked: !modalOptions[optionKey].checked,
      },
    });
    return () => {
      checkDisabled();
    };
  };

  return (
    <div className="platform__add-dialog">
      <div className="platform__add-dialog--header">
        {text.en['add-new-environments']}
        <div
          className={'platform__add-dialog--close'}
          onClick={() => handleCloseDialog(null, true)}
        >
          <i className={'icon-16-377-close2'} />
        </div>
      </div>
      <div className="platform__add-dialog--content">
        {Object.keys(modalOptions).map((optionKey) => {
          const option = modalOptions[optionKey];
          return (
            <div
              key={optionKey}
              className={'platform__add-dialog--content-row'}
            >
              <label htmlFor={option.id}>
                <input
                  name={option.id}
                  id={option.id}
                  type={'checkbox'}
                  checked={option.checked}
                  onChange={(e) => selectMode(e, optionKey)}
                />
                {option.type !== 'text' ? text.en[optionKey] : null}
              </label>
              {option.type === 'text' ? (
                <input
                  value={inputValue}
                  type={'text'}
                  placeholder={text.en[optionKey]}
                  onChange={(e) => setInputValue(e.target.value)}
                />
              ) : null}
            </div>
          );
        })}
      </div>
      <button
        className="platform__add-dialog-btn sr-btn sr-btn__md btn-default"
        disabled={isDisabled}
        title={text.en.add}
        onClick={createPlatforms}
      >
        {text.en.add}
      </button>
    </div>
  );
}
