import React from 'react';

export function Acceptance(): JSX.Element {
  return (
    <>
      <h2>3. Acceptance</h2>
      <p>
        In order to use the MDP, it is necessary that you agree to and accept
        these Terms and Conditions. You are not allowed to use the MDP if you do
        not accept these Terms and Conditions. By clicking to accept, you hereby
        agree to the terms of these Terms and Conditions. If you are agreeing to
        be bound by these Terms and Conditions on behalf of your employer or
        other entity, you represent and warrant that you have full legal
        authority to bind your employer or such entity to these Terms and
        Conditions.
      </p>
    </>
  );
}
