import * as actionTypes from '../actions/actionTypes';

export default function locale(
  state = { lang: 'en' },
  action: { type: string; lang: string }
) {
  if (action.type === actionTypes.LOCALE_SET) {
    return { lang: action.lang };
  } else {
    return state;
  }
}
