import React from 'react';

export function AppPlaceholderIcon(): JSX.Element {
  return (
    <svg viewBox="0 0 65 51" version="1.1">
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="MDP-My-apps" transform="translate(-876.000000, -216.000000)">
          <g id="Group-7" transform="translate(877.000000, 217.000000)">
            <polyline
              id="Path-2"
              stroke="#DA3332"
              points="0.5 40.3265306 18.7522124 19 34.5239258 38.0816327 45.472168 26.8571429 63 46.5"
            ></polyline>
            <circle id="Oval" fill="#DA3332" cx="51" cy="14" r="4"></circle>
            <rect
              id="Rectangle"
              stroke="#0C0F22"
              x="0"
              y="0"
              width="63"
              height="49"
              rx="2"
            ></rect>
          </g>
        </g>
      </g>
    </svg>
  );
}
