import { Button } from '@mui/material';
import React, { Fragment, useCallback, useState } from 'react';
import Modal from '../../../components/Modal/Modal';
import text from '../../../shared/translations/en';
import { ModalButton } from '../../../components/UI/ModalButton/ModalButton';
import { CheckboxLabels } from '../CheckboxLabels/CheckboxLabels';
import { EventType } from '../../../shared/types/pushSettings';

interface IProps {
  onCancelCheckboxDialog: () => void;
  checkboxHandler: (eventType: EventType) => void;
  checkedTypes: string[];
}

export function CheckboxDialog({
  onCancelCheckboxDialog,
  checkboxHandler,
  checkedTypes,
}: IProps) {
  const [isOpen, setIsOpen] = useState(false);

  const handleClickOpen = useCallback(() => setIsOpen(true), []);

  const handleClose = useCallback(() => setIsOpen(false), []);

  const handleCancel = useCallback(() => {
    handleClose();
    onCancelCheckboxDialog();
  }, [onCancelCheckboxDialog, handleClose]);

  return (
    <Fragment>
      <Button
        variant="outlined"
        color="primary"
        onClick={handleClickOpen}
        style={{ marginTop: 8, fontSize: 12 }}
      >
        {text.en['add-event-types']}
      </Button>
      {isOpen ? (
        <Modal
          title={`Available event types`}
          content={
            <CheckboxLabels
              onCheckboxClick={checkboxHandler}
              checkedTypes={checkedTypes}
            />
          }
          actions={() => (
            <Fragment>
              <ModalButton
                btnText={text.en.cancel}
                actionHandler={handleCancel}
                type="transparent"
              />
              <ModalButton
                btnText={text.en.add}
                actionHandler={handleClose}
                type="colored"
              />
            </Fragment>
          )}
          onDismiss={() => handleClose()}
        />
      ) : null}
    </Fragment>
  );
}
