import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Platform, PlatformEnum } from '../../../shared/types/application';
import text from '../../../shared/translations/en';
import Modal from '../../../components/Modal/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from 'store/selectors/user';
import { updatePlatformCredentials } from '../../../store/actions/applications.js';
import { ModalButton } from '../../../components/UI/ModalButton/ModalButton';

interface IProps {
  data: Platform;
  environment: string;
  handleRemoveCredentials: (platform: string, environment: string) => void;
}

const Android: React.FunctionComponent<IProps> = (props) => {
  const dispatch = useDispatch();

  const { data, environment, handleRemoveCredentials } = props;

  const { extra, value } = data;

  const [certificateFingerprint, setCertificateFingerprint] =
    useState<string>(value);
  const [namespace, setNamespace] = useState<string>(extra);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const user = useSelector(selectUser);

  const handleUpdatePlatformSettings = useCallback(() => {
    const body = {
      platform: PlatformEnum.ANDROID,
      mode: environment,
      credentials: certificateFingerprint,
      extra: namespace,
    };
    dispatch(updatePlatformCredentials(body, user));
  }, [certificateFingerprint, namespace, environment]);

  useEffect(() => setCertificateFingerprint(value), [value]);
  useEffect(() => setNamespace(extra), [extra]);

  return (
    <div className="platform__box">
      <div className="app-detail__row custom-row" key={environment}>
        <div className="platform__box-header">
          {environment}
          {/* delete button */}
          <button
            title="Remove"
            className="platform__btn-remove sr-btn sr-btn__sm btn-default sr-btn-white u-btn-right"
            onClick={() => setIsModalOpen(true)}
          >
            <i className="icon-16-079-trash" />
            {text.en.remove}
          </button>
        </div>
        <div className="platform__box-content">
          {/*Fingerprint*/}
          <div className="col-1-of-2">
            <div className="input-row__wrapper">
              <label className="input-row__label">
                {/*Required asterisk*/}
                {text.en['cert-fingerprint']}
                {value ? '*' : null}
              </label>
              <div className="input-row__input-contanier">
                <span className="input-icon">
                  <i className="icon-16-006-edit" />
                </span>
                <input
                  value={certificateFingerprint}
                  className="input-row__input"
                  onChange={(e) => setCertificateFingerprint(e.target.value)}
                  placeholder={text.en['cert-fingerprint-placeholder']}
                />
              </div>
              {/*Additional text*/}
              <div className="input-row__support-msg">
                <span
                  dangerouslySetInnerHTML={{
                    __html: text.en['android-extra-lead'],
                  }}
                />
              </div>
            </div>
          </div>
          {/*Namespace*/}
          <div className="col-1-of-2">
            <div className="input-row__wrapper">
              <label className="input-row__label">
                {/*Required asterisk*/}
                {text.en['app-namespace']}
              </label>
            </div>
            <div className="input-row__input-contanier">
              <span className="input-icon">
                <i className="icon-16-006-edit" />
              </span>
              <input
                value={namespace}
                className="input-row__input"
                onChange={(e) => setNamespace(e.target.value)}
                placeholder={text.en['app-bundle-placeholder']}
              />
            </div>
            {/*Additional text*/}
            <div className="input-row__support-msg"></div>
            {/*Save button*/}
            <div className="btn-wrapper">
              <button
                className="sr-btn sr-btn__sm btn-cancel"
                title={text.en.cancel}
              >
                {text.en.cancel}
              </button>
              <button
                className="sr-btn sr-btn__sm btn-default"
                title={text.en.save}
                onClick={handleUpdatePlatformSettings}
              >
                {text.en.save}
              </button>
            </div>
          </div>
        </div>
      </div>
      {isModalOpen ? (
        <Modal
          title={`Delete "${environment}" environment?`}
          contentText={`This action will affect all devices in ${environment} environment.`}
          onDismiss={() => setIsModalOpen(false)}
          actions={() => (
            <Fragment>
              <ModalButton
                btnText={text.en.no}
                actionHandler={() => setIsModalOpen(false)}
                type="transparent"
              />
              <ModalButton
                btnText={text.en.yes}
                actionHandler={() =>
                  handleRemoveCredentials(PlatformEnum.ANDROID, environment)
                }
                type="colored"
              />
            </Fragment>
          )}
        />
      ) : null}
    </div>
  );
};

export default Android;
