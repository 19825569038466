import React, { ChangeEventHandler } from 'react';

interface IProps {
  label: string;
  inputValue: string;
  onInputChange: ChangeEventHandler<HTMLInputElement>;
  inputName: string;
  inputPlaceholder: string;
  requiredField: boolean;
  inputDisabled?: boolean;
}

export function TextInput({
  label,
  inputValue,
  onInputChange,
  inputName,
  inputPlaceholder,
  requiredField,
  inputDisabled = false,
}: IProps): JSX.Element {
  return (
    <div className="input__container">
      <label className="input__label">
        {`${label} ${!inputValue && requiredField ? '*' : ''} `}
      </label>
      <input
        type="text"
        className="input"
        onChange={onInputChange}
        value={inputValue}
        name={inputName}
        placeholder={inputPlaceholder}
        disabled={inputDisabled}
      />
      <span className="input__icon">
        <i className="icon-16-006-edit" />
      </span>
    </div>
  );
}
