import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectApplication } from '../../../store/selectors/application';
import { selectAppPushModifiers } from '../../../store/selectors/pushModifiers';
import * as actions from '../../../store/actions/index';
import text from '../../../shared/translations/en';
import { ModifierExpansionData } from './ModifierExpansionData';

const styles = (variant) => {
  const { theme } = variant;
  return {
    root: {
      width: '100%',
      marginTop: '1.8rem',
    },
    heading: {
      fontSize: '1.4rem',
      flexBasis: '99%',
      flexShrink: 0,
      wordBreak: 'break-all',
      color: 'rgb(64, 64, 64)',
    },
    secondaryHeading: {
      fontSize: theme?.typography.pxToRem(14),
      color: theme?.palette.text.secondary,
    },
    panelDetails: {
      display: 'block',
    },
    expansionPanel: {
      padding: '0 24px 0 8px',
      minHeight: '40px',
    },
    button: {
      color: 'rgb(255, 0, 0)',
      fontSize: '1.2rem',
    },
  };
};

interface IProps {
  onDeleteBtnClick: (modifierName: string) => void;
}

export function ModifierExpansionPanel({ onDeleteBtnClick }: IProps) {
  const dispatch = useDispatch();

  const { appId } = useSelector(selectApplication);
  const appModifiers = useSelector(selectAppPushModifiers);

  useEffect(() => {
    if (appId) {
      dispatch(actions.getAppPushModifiers(appId));
    }
  }, [appId]);

  return (
    <div style={styles('unknown').root}>
      {appModifiers && appModifiers.length > 0 ? (
        <ModifierExpansionData
          styles={styles}
          appModifiers={appModifiers}
          onDeleteBtnClick={onDeleteBtnClick}
        />
      ) : (
        <p className="body-4">{text.en.none}</p>
      )}
    </div>
  );
}
