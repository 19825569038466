import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { IDialog } from '../../../shared/types/device';
import { updateDevice, deleteDevice } from '../../../store/actions/devices';
import { DialogActions } from './DialogActions';
import { DialogContent } from './DialogContent';
import { findParentBySelector } from '../../../utils/domHelpers';

interface IProps {
  dialog: IDialog;
  title: string;
  closeDialog: () => void;
  app: string;
}

export function Dialog({
  dialog,
  title,
  closeDialog,
  app,
}: IProps): JSX.Element {
  const dispatch = useDispatch();

  const [displayName, setDisplayName] = useState<string>('');

  const handleRemoveDevice = useCallback(() => {
    const data = {
      appId: app,
      deviceId: dialog.device.id,
      platform: dialog.device.platform,
      mode: dialog.device.modeType,
    };

    dispatch(deleteDevice(data));
    closeDialog();
  }, [dialog, app]);

  const handleEditDevice = useCallback(() => {
    const data = {
      appId: app,
      deviceId: dialog.device.id,
      platform: dialog.device.platform,
      mode: dialog.device.modeType,
      displayName,
    };

    dispatch(updateDevice(data));
    closeDialog();
  }, [dialog, displayName, app]);

  const closeOverlay = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    const clickOnModal = findParentBySelector(e.target, '.mdp-modal');

    if (!clickOnModal) {
      closeDialog();
    }
  }, []);

  return (
    <div className={'mdp-modal-overlay'} onClick={(e) => closeOverlay(e)}>
      <div className={'mdp-modal'}>
        <div className={'mdp-modal-header'}>
          <span>{title}</span>
          <span className={'mdp-modal-close-x'} onClick={closeDialog}>
            <i className={'icon icon-16-003-close-x'} />
          </span>
        </div>
        <div className={'mdp-modal-body'}>
          <DialogContent
            dialog={dialog}
            displayName={displayName}
            setDisplayName={setDisplayName}
          />
          <div className={'mdp-modal-button-container'}>
            <DialogActions
              dialog={dialog}
              handleEditDevice={handleEditDevice}
              handleRemoveDevice={handleRemoveDevice}
              displayName={displayName}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
