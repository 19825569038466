import React from 'react';
import text from '../../../shared/translations/en';
import { Device, IDialog } from '../../../shared/types/device';

interface IProps {
  dialog: IDialog;
  handleRemoveDevice: (device: Device) => void;
  handleEditDevice: (device: Device) => void;
  displayName: string;
}

export function DialogActions({
  dialog,
  handleRemoveDevice,
  displayName,
  handleEditDevice,
}): JSX.Element {
  return (
    <div className={'mdp-modal-button-container'}>
      {dialog.remove ? (
        <button
          className={'mdp-modal-button srm-active'}
          onClick={handleRemoveDevice}
        >
          {text.en['confirm']}
        </button>
      ) : (
        <button
          className={`mdp-modal-button ${displayName !== '' && 'srm-active'}`}
          onClick={handleEditDevice}
        >
          {dialog.edit ? text.en.save : text.en['confirm-device']}
        </button>
      )}
    </div>
  );
}
