import { API } from 'aws-amplify';
import { toastr } from 'react-redux-toastr';
import * as actionTypes from './actionTypes';

export const fetchCostReport = (month, year) => {
  return (dispatch) => {
    dispatch(fetchCostReportStart());
    API.get('mdp', `/costs/${+month + 1}/${year}`, {})
      .then((response) => dispatch(fetchCostReportSuccess(response)))
      .catch((error) =>
        dispatch(
          fetchCostReportFail(error.response?.data?.message ?? error.message)
        )
      );
  };
};

export const fetchCostReportStart = () => {
  return { type: actionTypes.FETCH_COST_REPORT_START };
};

export const fetchCostReportFail = (error) => {
  toastr.error(error);
  return { type: actionTypes.FETCH_COST_REPORT_FAIL, error };
};

export const fetchCostReportSuccess = (costReport) => {
  return { type: actionTypes.FETCH_COST_REPORT_SUCCESS, costReport };
};
