import React from 'react';

export function Account(): JSX.Element {
  return (
    <>
      <h2>6. Accounts, Passwords and Security</h2>
      <p>
        Certain features or services offered on or through the Portal may
        require you to open an account (including setting up an ID and
        password). You are entirely responsible for maintaining the
        confidentiality of the information you hold for your account, including
        your password, and for any and all activity that occurs under your
        account as a result of your failing to keep this information secure and
        confidential. You agree to notify Sportradar immediately of any
        unauthorized use of your account or password, or any other breach of
        security. You may be held liable for losses incurred by Sportradar or
        any other user of or visitor to the Portal due to someone else using
        your ID, password or account as a result of your failing to keep your
        account information secure and confidential.
      </p>
      <p>
        You may not use anyone else’s ID, password or account at any time
        without the express permission and consent of the holder of that ID,
        password or account. Sportradar cannot and will not be liable for any
        loss or damage arising from your failure to comply with these
        obligations.
      </p>
    </>
  );
}
