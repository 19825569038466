import React, {
  FormEvent,
  Fragment,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import text from '../../shared/translations/en';
import { selectUsers } from '../../store/selectors/users';
import { getAllUsers } from '../../store/actions/users';
import Modal from '../../components/Modal/Modal';
import { ModalButton } from '../../components/UI/ModalButton/ModalButton';
import { changeOwner } from '../../store/actions/admin';

export function AppOwner(): JSX.Element {
  const dispatch = useDispatch();

  const [newOwner, setNewOwner] = useState<string>('');
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const users = useSelector(selectUsers);

  useEffect(() => {
    dispatch(getAllUsers());
  }, []);

  const handleUpdate = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      const existingUser = users.find((user) => user.userEmail === newOwner);

      if (existingUser) {
        sendData();
      } else {
        setIsDialogOpen(true);
      }
    },
    [users, newOwner]
  );

  const sendData = useCallback(() => {
    dispatch(changeOwner({ userEmail: newOwner }));
    setNewOwner('');
    setIsDialogOpen(false);
  }, [newOwner]);

  return (
    <section>
      {/* Owner settings */}
      <div className="app-detail__row u-margin-top-42">
        <div className="col-1-of-2">
          <h5 className="u-margin-top-42">{text.en['set-app-owner']}</h5>
          <p className="body-3 u-margin-top-18">{text.en['set-owner-lead']}</p>
        </div>
        <div className="col-1-of-2"></div>
      </div>

      {/* Form */}
      <div className="app-detail__row u-margin-top-42">
        {/*Owner Form */}
        <div className="col-1-of-2">
          <form onSubmit={handleUpdate}>
            <div className="input-row__wrapper">
              <label className="input-row__label">{text.en.email}</label>
              <div className="input-row__input-contanier">
                <input
                  list="mdpUsers"
                  name="mdpUser"
                  className="input-row__input"
                  onChange={(event) => setNewOwner(event.target.value)}
                  placeholder={text.en['add-owner']}
                  value={newOwner}
                />
                <datalist id="mdpUsers">
                  {users.map((user) => (
                    <option value={user.userEmail} key={user.userEmail} />
                  ))}
                </datalist>
              </div>
              <div className="input-row__support-msg"></div>
              <button
                type="submit"
                className="sr-btn sr-btn__md btn-default u-btn-right"
                disabled={newOwner === ''}
              >
                {text.en.set}
              </button>
            </div>
          </form>
        </div>
      </div>
      {/* Owner Dialog */}
      {isDialogOpen ? (
        <Modal
          title={text.en.warning}
          contentText={`${text.en['set-owner-warning']} "${
            newOwner ? newOwner : ''
          } "`}
          actions={() => (
            <Fragment>
              <ModalButton
                btnText={text.en.cancel}
                actionHandler={() => setIsDialogOpen(false)}
                type="transparent"
              />
              <ModalButton
                btnText={text.en.ok}
                actionHandler={() => sendData()}
                type="colored"
              />
            </Fragment>
          )}
          onDismiss={() => setIsDialogOpen(false)}
        />
      ) : null}
    </section>
  );
}
