import React, { Fragment, useEffect } from 'react';
import { findParentBySelector } from '../../utils/domHelpers';
import { Cell, Column } from '../../shared/types/table';
import { TableCell } from './TableCell';

interface IProps {
  data: Column[];
  onCellClick: (column: Column, index: number) => void;
  onCellInputChange: (
    column: Column,
    cell: Cell,
    index: number,
    value: unknown
  ) => void;
  handleClickOutOfActiveCell: () => void;
}

export function Table({
  data,
  onCellClick,
  onCellInputChange,
  handleClickOutOfActiveCell,
}: IProps): JSX.Element {
  const handleClickAnywhere = (e: MouseEvent) => {
    const activeCell = findParentBySelector(e.target, '.srm-show-input');
    if (!activeCell) {
      handleClickOutOfActiveCell();
    }
  };

  const getExtraClass = (columnId: string) => {
    if (['key', 'default'].indexOf(columnId) > -1) {
      return `srm-${columnId}`;
    }

    return '';
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickAnywhere, false);
    return () => {
      document.removeEventListener('mousedown', handleClickAnywhere, false);
    };
  }, [handleClickAnywhere]);

  return (
    <div className={'settings-table'}>
      {data.map((column, ix) => {
        const extraClass = getExtraClass(column.id);

        return (
          <div
            key={`col-ix-${ix}`}
            className={'settings-table-column'}
            style={{
              width: `${100 / data.length}%`,
            }}
          >
            <div className={`settings-table-title ${extraClass}`}>
              {column.text}
            </div>
            {column.values.map((cell, index) => (
              <Fragment key={index}>
                <TableCell
                  cell={cell}
                  column={column}
                  onCellClick={onCellClick}
                  onCellInputChange={onCellInputChange}
                  index={index}
                  data={data}
                />
              </Fragment>
            ))}
          </div>
        );
      })}
    </div>
  );
}
