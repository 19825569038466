import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import text from '../../../shared/translations/en';
import { getDocumentationUrl } from '../../../utils/Documentation';
import { isUserAdmin } from '../../../shared/utility';
import { CognitoUser } from '../../../shared/types/user';

interface IProps {
  user: CognitoUser;
  handleSignout: () => void;
}

export function AuthNav({ user, handleSignout }: IProps): JSX.Element {
  return (
    <Fragment>
      <NavLink
        to="/applications"
        className={({ isActive }) =>
          `navigation--btn ${isActive ? 'nav-active' : ''}`
        }
      >
        <span>
          <i className="icon-16-221-apps" />
        </span>
        {text.en.applications}
      </NavLink>
      <a
        href={getDocumentationUrl()}
        target="_blank"
        className="navigation--btn"
      >
        <span>
          <i className="icon-16-144-menu-square" />
        </span>
        {text.en.docs}
      </a>
      {user && isUserAdmin(user) ? (
        <Fragment>
          <div className="navigation--btn nav-dropdown">
            <span>
              <i className="icon-16-253-Admin" />
            </span>
            {text.en.admin}
            <div className="nav-dropdown--content">
              <NavLink to="/users">
                <span>
                  <i className="icon-16-030-people"></i>
                </span>
                {text.en.users}
              </NavLink>
              <NavLink to="/dashboard">
                <span>
                  <i className="icon-16-369-widgets"></i>
                </span>
                {text.en.dashboard}
              </NavLink>
              <NavLink to="/cost-report">
                <span>
                  <i className="icon-16-111-coin-dollar"></i>
                </span>
                {text.en['cost-report']}
              </NavLink>
            </div>
          </div>
        </Fragment>
      ) : null}
      <div className="navigation--btn nav-dropdown">
        <span style={{ marginRight: 0 }}>
          <i className="icon-16-247-user2-avatar sr-icon-large" />
        </span>
        <div className="nav-dropdown--content">
          <a className="u-text-lowercase">
            {user ? user.attributes.email : ''}
          </a>
          <a onClick={handleSignout}>
            <span>
              <i className="icon-16-188-exit"></i>
            </span>
            {text.en['sign-out']}
          </a>
        </div>
      </div>
    </Fragment>
  );
}
