import React from 'react';

export function IconDisabled(): JSX.Element {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
    >
      <title>ico_disabled</title>
      <path
        className="cls-1"
        d="M8,1a7,7,0,1,0,7,7A7.008,7.008,0,0,0,8,1ZM3,8a5,5,0,0,1,7.8-4.137L3.863,10.8A4.967,4.967,0,0,1,3,8Zm5,5a4.954,4.954,0,0,1-2.7-.8l6.9-6.9A4.985,4.985,0,0,1,8,13Z"
      />
    </svg>
  );
}
