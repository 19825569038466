import React, { FC, Fragment, useCallback, useEffect, useState } from 'react';
import { Footer } from '../../components/Footer/Footer';
import { AppSettings } from '../AppSettings/AppSettings';
import { AdminSettings } from '../../components/AdminSettings/AdminSettings';
import { Tools } from '../../components/Tools/Tools';
import { useDispatch } from 'react-redux';
import { fetchApp } from '../../store/actions';
import { Platforms } from '../../containers/Platforms/Platforms';
import { Overview } from '../Overview/Overview';
import { Devices } from '../../components/Devices/Devices';
import { AppSidebar } from '../../components/AppSidebar/AppSidebar';
import { useLocation, useParams } from 'react-router';

export const Application: FC = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { id } = useParams();

  const [activeTab, setActiveTab] = useState<string>(
    location.hash ? location.hash.replace('#', '') : 'overview'
  );

  useEffect(() => {
    dispatch(fetchApp(id, undefined));
  }, [id]);

  useEffect(() => {
    setActiveTab(location.hash ? location.hash.replace('#', '') : 'overview');
  }, [location, activeTab]);

  const RenderContent = useCallback(() => {
    switch (activeTab) {
      case 'platforms-and-environments':
        return <Platforms />;
      case 'app-settings':
        return <AppSettings />;
      case 'admin-settings':
        return <AdminSettings />;
      case 'tools':
        return <Tools />;
      case 'devices':
        return <Devices />;
      default:
        return <Overview appId={id} />;
    }
  }, [activeTab]);

  return (
    <Fragment>
      <aside className="layout__sidebar">
        <AppSidebar />
      </aside>
      <main className="layout__main">
        <div className="app-content">
          <div className="row">
            <div data-test={activeTab} className="app-content--wrapper">
              <RenderContent />
            </div>
          </div>
        </div>
        <div className="layout__footer">
          <Footer />
        </div>
      </main>
    </Fragment>
  );
};

export default Application;
