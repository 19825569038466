import React, { useEffect, useState } from 'react';
import { isUserAdmin } from '../../shared/utility';
import { AppSidebarTab, sidebarTabs } from '../../shared/types/sidebar';
import { CognitoUser, MdpUser } from '../../shared/types/user';

interface IProps {
  user?: MdpUser | CognitoUser;
}

export function AppSidebarTabs({ user }: IProps): JSX.Element {
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [tabs, setTabs] = useState<AppSidebarTab[]>(sidebarTabs);

  const { hash } = location;

  useEffect(() => {
    if (user) {
      setIsAdmin(isUserAdmin(user));
    }
  }, [user]);

  useEffect(() => {
    if (isAdmin) {
      setTabs(sidebarTabs);
    } else {
      setTabs(
        sidebarTabs.filter(
          (tab: AppSidebarTab) => tab.name !== 'Admin settings'
        )
      );
    }
  }, [isAdmin]);

  return (
    <>
      {tabs.map((tab) => (
        <li
          key={tab.name}
          className={
            hash === tab.tabHash ? 'sidebar__link active' : 'sidebar__link'
          }
        >
          <a href={tab.tabHash}>
            <i className={`${tab.iconClass} sidebar__link--icon`}></i>
            <span className={'sidebar__link--text'}>{tab.name}</span>
          </a>
        </li>
      ))}
    </>
  );
}
