import './index.scss';
import React from 'react';

interface IProps {
  loading?: boolean;
  topZero?: boolean;
  inside?: boolean;
}

export function Loader({ loading, inside, topZero }: IProps): JSX.Element {
  const topZeroCn = topZero ? 'srm-top-zero' : '';
  const loadingCn = loading ? 'srm-loading' : '';
  const insideCn = inside ? 'srm-inside' : '';
  const loaderCn = `mdp-loader ${topZeroCn} ${loadingCn} ${insideCn}`;

  return (
    <div className={loaderCn}>
      <div className="mdp-loader-spinner">
        <div className="rect1" />
        <div className="rect2" />
        <div className="rect3" />
        <div className="rect4" />
        <div className="rect5" />
      </div>
    </div>
  );
}
