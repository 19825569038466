import React from 'react';

export function General(): JSX.Element {
  return (
    <>
      <h2>1. General</h2>
      <h3>1.1 Parties</h3>
      <p>
        Sportradar undertakes to deliver to the Customer the Service, as
        provided in a free trial, or as agreed upon in a separate SA (the
        “Services”). The Customer will get access to the Sportradar Mobile
        Development Platform (short: MDP), including the Sportradar Mobile
        Software Development Kit (short: Mobile SDK), Documentation and access
        to the Dashboard via the means of registering a customer account at the
        Sportradar Mobile Development Platform portal
        <a href="https://mdp.sportradar.com">https://mdp.sportradar.com</a>{' '}
        (short: Portal) and expressly accepting these Terms and Conditions
        (short: Agreement, or Terms), for the purpose of providing a free trial
        (ending 30 days after Application Key generation in the Dashboard), or
        for the purpose of fee-based commercial operation, based on an agreed
        upon and signed Service Agreement (short: SA).
      </p>
      <h3>1.2 Subject</h3>
      <p>
        These general Terms and Conditions deal with Sportradar´s Mobile
        Development Platform, which is a software development solution that
        enables clients to rapidly make their own iOS and Android apps for
        tracking sports results using Sportradar data through the Sportradar
        Mobile SDK. It includes the Sportradar Mobile SDK software license which
        gives access to company materials and access to the Sportradar MDP
        Dashboard portal. The Terms cover the terms and conditions of the
        provision of all software development kits made available by Sportradar
        to you through the MDP.
      </p>
      <h3>1.3 MDP</h3>
      <p>
        The MDP under this Agreement means access to the Portal and all software
        development tools accessible through the Portal that allow for the
        creation of applications for a certain software package, software
        framework, hardware platform, computer system, video game console,
        operating system, or similar development platform, or any product
        provided by Sportradar (all software development kits are hereinafter
        together referred to as “SDK”). The SDK is licensed to you and it is
        subject to these Terms or any other additional agreements, such as
        Service Agreement or License Agreement. These Terms form a legally
        binding contract between you and Sportradar in relation to your use of
        the MDP. Sportradar means Sportradar AG, Feldlistrasse 2, CH- 9000
        St.Gallen, Switzerland.
      </p>
      <h3>1.4 What is Included</h3>
      The MDP consists of the following integrated services:
      <ul>
        <li>
          Dashboard (means the control dashboard portal to which the Customer
          gains access by registering at and logging into the Mobile Development
          Platform portal at the website
          <a href="https://mdp.sportradar.com">https://mdp.sportradar.com</a>)
        </li>
        <li>
          Mobile SDK (means a software development kit, the Sportradar Mobile
          SDK. It is a set of software development tools that allows the
          development of applications for the reason, not only, but also to
          provide the display of Company Materials on digital devices, in
          accordance with these Terms, and which is available for download
          through the Sportradar MDP. The Mobile SDK includes the Mobile SDK
          software code to include in Customer's own applications, Documentation
          and other content and services, provided under these Terms).
        </li>
        <li>
          Application Key (means the application key that enables the Customer
          to use the Content provided by the Company).
        </li>
        <li>
          Free Trial (means non-commercial use of the services and content,
          provided by Sportradar to prospective customers for evaluation
          purposes pursuant to the free trial registration form found on the
          Mobile Development Platform portal at{' '}
          <a href="https://mdp.sportradar.com">https://mdp.sportradar.com</a>)
        </li>
      </ul>
    </>
  );
}
