import axios from 'axios';
import * as actionTypes from './actionTypes';
import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError,
  asyncActionSuccess,
} from './async';
import { toastr } from 'react-redux-toastr';
import { text } from '../../shared/translations/en-v1';
import { API } from 'aws-amplify';

export const authSuccess = (token) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    idToken: token,
  };
};

export const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('currentUser');
  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

export const auth = (email, password) => {
  return (dispatch) => {
    dispatch(asyncActionStart());
    const authData = {
      email,
      password,
    };
    axios
      .post('api/auth/login', authData)
      .then((response) => {
        localStorage.setItem('token', response.data.token);
        dispatch(authSuccess(response.data.token));
        dispatch(asyncActionFinish());
      })
      .catch((err) => {
        if (err.response.status >= 500) {
          toastr.error(text.error, text.genericError);
        } else if (err.response.status === 403) {
          toastr.error(text.error, err.response.data.message);
        } else if (err.response.status === 401) {
          toastr.error(text.error, err.response.data.message);
          if (err.response.data.message.indexOf('Username') > -1) {
            dispatch(
              asyncActionError([
                {
                  field: 'email',
                  rejected: null,
                  message: err.response.data.message,
                },
              ])
            );
          }
        } else if (
          err.response.data.status === 422 &&
          err.response.data.errors.length
        ) {
          toastr.error(text.error, err.response.data.errors[0].message);
          dispatch(asyncActionError(err.response.data.errors));
        } else {
          dispatch(asyncActionError(err.response.data.errors));
        }
      });
  };
};

export const authCheckState = () => {
  return (dispatch) => {
    const token = localStorage.getItem('token');
    if (!token) {
      dispatch(logout());
    } else {
      dispatch(authSuccess(token));
    }
  };
};

const handleError = (error, dispatch) => {
  if (error.response.status >= 500) {
    toastr.error(text.error, text.genericError);
    dispatch(asyncActionFinish());
  } else {
    toastr.error(error.response.data.message);
    dispatch(asyncActionError(error.response.data.errors));
  }
};

export const register = (user) => {
  return (dispatch) => {
    dispatch(asyncActionStart());
    API.post('mdp', '/user/register', { body: user })
      .then((response) => {
        toastr.success(text.success, text.userAdded);
        dispatch(asyncActionSuccess());
        dispatch({
          type: actionTypes.USER_REGISTRATION_SUCCESS,
          payload: {
            ...response.data,
            plan: user.plan,
          },
        });
      })
      .catch((err) => {
        handleError(err, dispatch);
      });
  };
};

export const clearRegistrationSuccess = () => {
  return { type: actionTypes.CLEAR_USER_REGISTRATION };
};

export const inquiry = (data) => {
  return (dispatch) => {
    dispatch(asyncActionStart());
    API.post('mdp-public', '/customer/inquiry', { body: data })
      .then(() => {
        dispatch(asyncActionSuccess());
      })
      .catch((err) => {
        handleError(err, dispatch);
      });
  };
};

export const authUploadStart = () => {
  return { type: actionTypes.AUTH_UPLOAD_START };
};
export const authUploadSuccess = () => {
  return { type: actionTypes.AUTH_UPLOAD_SUCCESS };
};
export const authUploadFail = () => {
  return { type: actionTypes.AUTH_UPLOAD_FAIL };
};

export const validatePasswords = (obj) => {
  return (dispatch) => {
    dispatch(asyncActionStart());
    axios
      .post('/api/auth/reset_password/validate', obj)
      .then((response) => {
        dispatch(asyncActionSuccess());
      })
      .catch((err) => {
        handleError(err);
      });
  };
};

export const setAuthRedirectPath = (path) => {
  return {
    type: actionTypes.SET_AUTH_REDIRECT_PATH,
    path: path,
  };
};
export const userActivationSuccess = () => {
  return { type: actionTypes.USER_ACTIVATION_SUCCESS };
};
export const userActivationFail = () => {
  return { type: actionTypes.USER_ACTIVATION_FAIL };
};

export const userActivate = (key) => {
  return (dispatch) => {
    dispatch(authUploadStart());
    axios
      .get(`/api/auth/activate/${key}`)
      .then((response) => {
        dispatch(userActivationSuccess());
      })
      .catch((err) => {
        dispatch(userActivationFail());
      });
  };
};
