import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React from 'react';
import { DialogStyle } from '../../shared/style';

type Width = 'sm' | 'xs' | 'md' | 'lg' | 'xl';

interface IProps {
  onDismiss: () => void;
  title: JSX.Element | string;
  content?: JSX.Element;
  contentText?: string;
  actions: () => JSX.Element;
  fullWidth?: boolean;
  maxWidth?: false | Width;
}
export default function Modal({
  onDismiss,
  title,
  content,
  contentText,
  actions,
  fullWidth = false,
  maxWidth = 'sm',
}: IProps): JSX.Element {
  const classes = DialogStyle;

  return (
    <Dialog
      open={true}
      onClose={onDismiss}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
    >
      <DialogTitle style={classes.title}>{title}</DialogTitle>
      <DialogContent style={classes.content}>
        <DialogContentText style={classes.content}>
          {contentText}
        </DialogContentText>
        {content}
      </DialogContent>
      <DialogActions>{actions()}</DialogActions>
    </Dialog>
  );
}
