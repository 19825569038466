import React from 'react';

export function SubjectMatter(): JSX.Element {
  return (
    <>
      <h2>2. Subject matter</h2>
      <h3>2.1 Free trial</h3>
      <p>
        Service Subject to all terms and conditions of this Agreement, the
        Customer may access and use the Service, solely through the MDP and
        Mobile SDK made available by Sportradar (in its sole discretion) and in
        accordance with all Documentation, solely for purposes of internally
        evaluating the Company Materials. The Customer is not authorized, and
        agrees not to use the Company Materials in connection with any
        commercial use or any use involving publication or display of the Data
        or Content in any form or media. The Customer agrees not to violate any
        applicable laws, rules, regulations or third party rights in connection
        with its use of the Service. The Customer is responsible for all
        activities occurring under a MDP Application Key issued to Customer.
      </p>
      <h3>2.2 Commercial Use</h3>
      <p>
        The commercial use of the service will be subject to a separate Service
        Agreement. These Terms and Conditions will be part of the service
        agreement.
      </p>
    </>
  );
}
