import '../../assets/sass/components/_notification-bar.scss';

import React from 'react';

interface IProps {
  text: string;
  type: string;
  visible: boolean;
  className?: string;
  onClose: () => void;
}

export function Bar({ text, type, visible, className, onClose }: IProps) {
  const cn = `srm-${type} ${className ? className : ''} ${
    visible ? 'srm-visible' : ''
  }`;

  return (
    <div className={`notification ${cn}`}>
      <div className={'notification-content'}>
        <span className={'notification-text'}>{text}</span>
        <span className={'notification-close'} onClick={onClose}>
          <i className={'la la-times-circle'}></i>
        </span>
      </div>
    </div>
  );
}
