import { fetchApp } from './applications';
import * as actionTypes from './actionTypes';
import { toastr } from 'react-redux-toastr';
import { text } from '../../shared/translations/en-v1';
import { API } from 'aws-amplify';
import { redirect } from 'react-router';

// OWNER
export const changeOwner = (ownerObj) => {
  return (dispatch, getState) => {
    API.put(
      'mdp',
      `/applications/${
        getState().apps.singleApp.id ?? getState().apps.singleApp.appId
      }/owner`,
      { body: ownerObj }
    )
      .then((response) => {
        dispatch({ type: actionTypes.CHANGE_OWNER_SUCCESS });
        toastr.success(text.success, `Owner changed to ${ownerObj.userEmail}.`);
        dispatch(
          fetchApp(
            getState().apps.singleApp.id ?? getState().apps.singleApp.appId
          )
        );
      })
      .catch((error) => {
        const errorMsg = error.response.data.message || text.genericError;
        toastr.error(text.error, errorMsg);
        dispatch({ type: actionTypes.CHANGE_OWNER_FAIL });
      });
  };
};

// PLANS
export const fetchPlansStart = () => {
  return { type: actionTypes.FETCH_PLANS_START };
};

export const fetchPlansSuccess = (plans) => {
  return {
    type: actionTypes.FETCH_PLANS_SUCCESS,
    plans,
  };
};

export const fetchPlansFail = () => {
  return { type: actionTypes.FETCH_PLANS_FAIL };
};

export const getPlans = () => {
  return (dispatch) => {
    dispatch(fetchPlansStart());
    API.get('mdp', `/plan`, {})
      .then((response) => {
        dispatch(fetchPlansSuccess(response.data));
      })
      .catch(() => {
        dispatch(fetchPlansFail());
      });
  };
};

export const fetchUserPlanSuccess = (plan) => {
  return {
    type: actionTypes.FETCH_USER_PLAN_SUCCESS,
    plan,
  };
};

export const fetchUserPlanFail = () => {
  return { type: actionTypes.FETCH_USER_PLAN_FAIL };
};

export const getUserPlan = (email) => {
  return (dispatch) => {
    API.get('mdp', `/users/${email}`)
      .then((response) => {
        dispatch(fetchUserPlanSuccess(response.userPlan));
      })
      .catch(() => {
        dispatch(fetchUserPlanFail());
      });
  };
};

export const setPlanStart = () => {
  return { type: actionTypes.SET_PLAN_START };
};

export const setPlanSuccess = () => {
  return { type: actionTypes.SET_PLAN_SUCCESS };
};

export const setPlanFail = () => {
  return { type: actionTypes.SET_PLAN_FAIL };
};

export const setOwnerPlan = (obj) => {
  return (dispatch) => {
    dispatch(setPlanStart());
    API.post('mdp', '/plan', { body: obj })
      .then(() => {
        dispatch(setPlanSuccess());
        toastr.success(text.success, text.genericSuccess);
        // Note: Before we were on single app page and Damir made another request to update
        // current app, but since we moved this functionality to separate page we do not need single app
        // update, because as soon as user will enter single app page data will already be requested again
      })
      .catch((error) => {
        const errorMsg = error.response.data.message || text.genericError;
        toastr.error(text.error, errorMsg);
        dispatch(setPlanFail());
      });
  };
};

// DISABLE / ENABLE APP
export const enableAppSuccess = () => {
  return { type: actionTypes.ENABLE_APP_SUCCESS };
};

export const enableAppFail = () => {
  return { type: actionTypes.ENABLE_APP_FAIL };
};

export const enableApp = (enabled) => {
  return (dispatch, getState) => {
    API.put(
      'mdp',
      `/applications/${
        getState().apps.singleApp.id ?? getState().apps.singleApp.appId
      }?enabled=${enabled}`,
      {}
    )
      .then((response) => {
        dispatch(enableAppSuccess());
        toastr.success(
          text.success,
          `App ${enabled ? 'enabled' : 'disabled'}.`
        );
        dispatch(
          fetchApp(
            getState().apps.singleApp.id ?? getState().apps.singleApp.appId,
            undefined
          )
        );
      })
      .catch((error) => {
        const errorMsg = error.response.data.message || 'Something went wrong';
        toastr.error(text.error, errorMsg);
        dispatch(enableAppFail());
      });
  };
};

// user applications
export const getUserApplications = (userEmail) => (dispatch) => {
  API.get('mdp', `/applications/user`, {
    queryStringParameters: { email: userEmail },
  })
    .then((response) => {
      dispatch({
        type: actionTypes.FETCH_USER_APPLICATIONS,
        payload: response.applications,
      });
    })
    .catch((error) => {
      if (error && error.response && error.response.status === 403) {
        redirect('/not-allowed');
      }
    });
};

export const getImageFromURL = (url, tag) => (dispatch) => {
  API.get('mdp', `/metrics/${url}`, {
    responseType: 'blob',
  })
    .then((response) => {
      const objectUrl = URL.createObjectURL(response);
      dispatch({
        type: actionTypes.FETCH_USAGE_IMAGE_SUCCESS,
        payload: { tag, objectUrl },
      });
    })
    .catch(() => {
      dispatch({
        type: actionTypes.FETCH_USAGE_IMAGE_FAIL,
        tag,
      });
    });
};
