import React, { Fragment, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import text from '../../shared/translations/en';
import { selectApplication } from '../../store/selectors/application';
import { SettingsTable } from '../SettingsTable/SettingsTable';
import { AppOwner } from '../../containers/AppOwner/AppOwner';
import { Slider } from '../UI/Slider/Slider';
import Modal from '../../components/Modal/Modal';
import { ModalButton } from '../UI/ModalButton/ModalButton';
import {
  udpatePushNotificationDelay,
  updateAdminSettings,
} from '../../store/actions/applications';
import { enableApp } from '../../store/actions/admin';
import { isEmpty } from 'lodash';

export function AdminSettings(): JSX.Element {
  const dispatch = useDispatch();

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const app = useSelector(selectApplication);

  const handleUpdateSettings = useCallback((data) => {
    dispatch(updateAdminSettings(data));
  }, []);

  const handleEnablingApp = useCallback(() => {
    dispatch(enableApp(!app.enabled));
    setIsDialogOpen(false);
  }, [app]);

  const handleSavingDelay = useCallback((value: string) => {
    dispatch(udpatePushNotificationDelay(Number(value)));
  }, []);

  if (isEmpty(app) && !app.adminSettings && !app.platformSettings) {
    return <></>;
  }

  return (
    <Fragment>
      <h1>
        <span className="icon-header">
          <i className="icon-16-254-Admin-settings" />
        </span>
        {text.en['admin-settings']}
      </h1>
      <div className="u-margin-top-42">
        <button
          onClick={() => setIsDialogOpen(true)}
          className={'sr-btn sr-btn__lg btn-default'}
        >
          {app.enabled ? 'Disable app' : 'Enable app'}
        </button>
      </div>
      <div className="app-detail__row">
        <h3 className="u-margin-top-42">{text.en['sdk-settings']}</h3>
        <div className="col-1-of-2 u-margin-top-18">
          <p className="body-3">{text.en['sdk-settings-lead']}</p>
        </div>
      </div>
      <div className="app-detail__row u-margin-top-42">
        <SettingsTable
          settings={app.adminSettings}
          platformSettings={app.platformSettings}
          updateSettings={handleUpdateSettings}
        />
      </div>
      {/* Link to owner user */}
      <div className="app-detail__row u-margin-top-80">
        <h3 className="u-margin-top-42">{text.en['plan-settings']}</h3>
        <div className="col-1-of-2 u-margin-top-18">
          <NavLink to={`/users/${app.ownerEmail}`}>
            {text.en['change-plan-settings']}
          </NavLink>
        </div>
      </div>
      {/* Set application owner */}
      <AppOwner />
      {/* Push notifications */}
      <div className="app-detail__row u-margin-top-42">
        <h3 className="u-margin-top-42">{text.en['push-notifications']}</h3>
        <div className="col-1-of-2">
          <p className="body-3 u-margin-top-18">
            {text.en['push-notifications-lead']}
          </p>
        </div>
        <div className="col-1-of-2"></div>
      </div>
      <div className="app-detail__row u-margin-bottom-extra-large">
        <div className="col-1-of-2">
          {(app.pushNotificationsDelay || app.pushNotificationsDelay === 0) && (
            <Slider
              delay={app.pushNotificationsDelay}
              saveHandler={handleSavingDelay}
            />
          )}
        </div>
      </div>
      {/* Disable Dialog */}
      {isDialogOpen ? (
        <Modal
          title={text.en.warning}
          contentText={`${text.en['sure-text']} ${
            app.enabled ? 'disable' : 'enable'
          } this application?`}
          actions={() => (
            <Fragment>
              <ModalButton
                btnText={text.en.cancel}
                actionHandler={() => setIsDialogOpen(false)}
                type="transparent"
              />
              <ModalButton
                btnText={app.enabled ? 'Disable' : 'Enable'}
                actionHandler={() => handleEnablingApp()}
                type="colored"
              />
            </Fragment>
          )}
          onDismiss={() => setIsDialogOpen(false)}
        />
      ) : null}
    </Fragment>
  );
}
