export interface CognitoUser {
  attributes: {
    email: string;
  };
}

export interface MdpUser {
  userEmail: string;
  isSportradar: boolean;
  isAdmin: boolean;
  userPlan: UserPlan;
  isLocked: boolean;
  userExists: boolean;
}

export interface UserWithRole {
  email: string;
  role: string;
}

interface UserPlan {
  expiry: string;
  plan: string;
  salesEmails: string[];
}

export enum UserRoles {
  ADMIN = 'ADMIN',
  USER = 'USER',
}

export enum MdpRoles {
  SALES = 'role-sales',
  DEVELOPER = 'role-developer',
  OWNER = 'role-owner',
  VIEWER = 'role-viewer',
}

export interface UserRegister {
  email: string;
  expiryDate: number;
  plan: string;
  salesRepresentative?: string;
}
