import React, { Fragment, useEffect } from 'react';
import text from '../../shared/translations/en';
import { PushSettings } from '../../containers/PushSettings/PushSettings';

export function Tools(): JSX.Element {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      <h1>
        <span className="icon-header">
          <i className="icon-16-231-wrench-tool" />
        </span>
        {text.en.tools}
      </h1>
      <PushSettings />
    </Fragment>
  );
}
