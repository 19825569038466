import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectError } from '../../store/selectors/application';
import iconPlaceholder from '../../assets/images/app_icon_placeholder_100x100.png';
import { uploadIcon } from '../../store/actions/applications';

import './ImageUploader.scss';

interface IProps {
  iconUrl?: string;
}

export function ImageUploader({ iconUrl }: IProps): JSX.Element {
  const dispatch = useDispatch();

  const [imagePreviewUrl, setImagePreviewUrl] = useState<
    string | ArrayBuffer
  >();

  const error = useSelector(selectError);

  const handleImageChange = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      event.preventDefault();
      const reader = new FileReader();
      const uploadedFile = event.currentTarget.files[0];

      reader.onloadend = () => {
        setImagePreviewUrl(reader.result);
      };

      reader.readAsDataURL(uploadedFile);
      dispatch(uploadIcon(uploadedFile));
    },
    []
  );

  return (
    <div className="image-uploader">
      <img
        src={
          imagePreviewUrl && !error
            ? imagePreviewUrl
            : iconUrl ?? iconPlaceholder
        }
        height="100"
        width="100"
        alt="Preview"
      />
      <form>
        <input
          type="file"
          onChange={handleImageChange}
          id="file"
          className="inputfile"
          hidden
        />
        <button
          className="sr-btn btn-default upload-file"
          onClick={(e) => {
            e.preventDefault();
            document.getElementById('file')?.click();
          }}
        >
          {iconUrl ? 'Change image' : 'Upload image'}
        </button>
      </form>
    </div>
  );
}
