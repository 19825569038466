import React from 'react';

export function Violations(): JSX.Element {
  return (
    <>
      <h2>14. Violation of These Terms & Conditions</h2>
      <h3>14.1 Disclosure to Authority</h3>
      <p>
        Sportradar may disclose any information we have about you (including
        your identity) if we determine that such disclosure is necessary in
        connection with any investigation or complaint regarding your use of the
        Portal, or to identify, contact or bring legal action against someone
        who may be causing injury to or interference with (either intentionally
        or unintentionally) Sportradar’s rights or property, or the rights or
        property of visitors to or users of the Portal, including Sportradar’s
        customers. Sportradar reserves the right at all times to disclose any
        information that Sportradar deems necessary to comply with any
        applicable law, regulation, legal process or governmental request.
        Sportradar also may disclose your information when Sportradar determines
        that applicable law requires or permits such disclosure, including
        exchanging information with other companies and organizations for fraud
        protection purposes.
      </p>
      <h3>14.2 Communication Preservation</h3>
      <p>
        You acknowledge and agree that Sportradar may preserve any transmittal
        or communication by you with Sportradar through the Portal or any
        service offered on or through the Portal, and may also disclose such
        data if required to do so by law or Sportradar determines that such
        preservation or disclosure is reasonably necessary to (1) comply with
        legal process, (2) enforce these Terms, (3) respond to claims that any
        such data violates the rights of others, or (4) protect the rights,
        property or personal safety of Sportradar, its employees, users of or
        visitors to the Portal, and the public.
      </p>
      <h3>14.3 Access Termination</h3>
      <p>
        You agree that Sportradar may, in its sole discretion and without prior
        notice, terminate your access to the Portal and Mobile SDK, and/or block
        your future access to the Portal or Mobile SDK, if we determine that you
        have violated these Terms or other agreements or guidelines which may be
        associated with your use of the Portal. You also agree that any
        violation by you of these Terms will constitute an unlawful and unfair
        business practice, and will cause irreparable harm to Sportradar, for
        which monetary damages would be inadequate, and you consent to
        Sportradar obtaining any injunctive or equitable relief that Sportradar
        deems necessary or appropriate in such circumstances. These remedies are
        in addition to any other remedies Sportradar may have at law or in
        equity.
      </p>
      <h3>14.4 Entitlement to Recovery</h3>
      <p>
        If Sportradar does take any legal action against you as a result of your
        violation of these Terms, Sportradar will be entitled to recover from
        you, and you agree to pay, all reasonable attorneys’ fees and costs of
        such action, in addition to any other relief granted to Sportradar. You
        agree that Sportradar will not be liable to you or to any third party
        for termination of your access to the Portal as a result of any
        violation of these Terms.
      </p>
    </>
  );
}
