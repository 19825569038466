import { combineReducers } from 'redux';
import authReducer from './auth';
import appsReducer from './applications';
import docsReducer from './documentations';
import userReducer from './user';
import usersReducer from './users';
import customPNReducer from './customPN';
import localeReducer from './locale';
import adminReducer from './admin';
import pushModifiersReducer from './pushModifiers';
import apnTokensReducer from './apnTokens';
import modalsReducer from './modals';
import asyncReducer from './async';
import notificationsReducer from './notifications';
import devicesReducer from './devices';
import paginationReducer from './pagination';
import searchReducer from './search';
import configReducer from './config';
import costReportReducer from './costReport';
import { reducer as ToastrReducer } from 'react-redux-toastr';

const rootReducer = combineReducers({
  apps: appsReducer,
  auth: authReducer,
  docs: docsReducer,
  profile: userReducer,
  mdpUsers: usersReducer,
  customPushNotifications: customPNReducer,
  locale: localeReducer,
  admin: adminReducer,
  pushSettings: pushModifiersReducer,
  apnTokens: apnTokensReducer,
  modals: modalsReducer,
  async: asyncReducer,
  toastr: ToastrReducer,
  notifications: notificationsReducer,
  devices: devicesReducer,
  pagination: paginationReducer,
  search: searchReducer,
  config: configReducer,
  costReport: costReportReducer,
});

export default rootReducer;
