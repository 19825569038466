export const text = {
  accesKeyCreated: 'Api access key created.',
  appCreated: 'Application created.',
  certificateDeleted: 'Certificate deleted.',
  error: 'Error!',
  genericError: "The operation couldn't be completed.",
  genericSuccess: 'Application updated.',
  iconUploaded: 'Icon has been uploaded.',
  inviteSent: 'Invitation sent.',
  modifierCreated: 'Modifier created.',
  modifierDeleted: 'Modifier has been deleted.',
  notificationSent: 'Notification sent.',
  platformCreated: 'Platform created.',
  platformDeleted: 'Platform deleted.',
  platformUpdated: 'Platform updated.',
  roleChanged: 'Role changed.',
  lockChanged: 'User lock changed.',
  recordingUpdated: 'Recording updated.',
  recordingScheduled: 'Recording scheduled.',
  success: 'Success!',
  userDeleted: 'User deleted.',
  userAdded:
    'User is not able to register. User will be shown in the users table once the registration will be completed.',
  acceptSuccess: 'Invitation was accepted.',
  rejectSuccess: 'Invitation was rejected.',
};
