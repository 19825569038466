import React, { Fragment, useCallback } from 'react';
import { ModalButton } from '../../components/UI/ModalButton/ModalButton';
import text from '../../shared/translations/en';
import { useDispatch } from 'react-redux';
import * as actions from '../../store/actions/index';

interface IProps {
  modifierName: string;
  setOpenDeleteDialog: (close: boolean) => void;
}

export function DeletePushSettingModalActions({
  modifierName,
  setOpenDeleteDialog,
}: IProps) {
  const dispatch = useDispatch();

  const handleDelete = useCallback(() => {
    dispatch(actions.deleteAppPushModifier(modifierName));
    setOpenDeleteDialog(false);
  }, [modifierName]);

  return (
    <Fragment>
      <ModalButton
        btnText={text.en.cancel}
        actionHandler={() => setOpenDeleteDialog(false)}
        type="transparent"
      />
      <ModalButton
        btnText={text.en.delete}
        actionHandler={handleDelete}
        type="colored"
      />
    </Fragment>
  );
}
