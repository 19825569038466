import { CHANGE_USERS_SEARCH } from '../actions/actionTypes';

const initialState = {
  users_search_term: '',
};

const search = (state, { type, payload }) => {
  if (typeof state === 'undefined') {
    return initialState;
  }

  if (type === CHANGE_USERS_SEARCH) {
    return {
      ...state,
      users_search_term: payload,
    };
  } else {
    return state;
  }
};

export default search;
