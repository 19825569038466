import React from 'react';
import text from '../../shared/translations/en';
import { UserRoles } from '../../shared/types/user';

interface IProps {
  role: string;
  handleChangeRole: (role: string) => void;
}

export function UserDetails({ role, handleChangeRole }: IProps): JSX.Element {
  return (
    <div className="srm-highlight">
      <div className="app-detail__row">
        <h3>{text.en.roles}</h3>
      </div>
      <label className={'u-margin-top-18'}>{text.en['user-role']}</label>
      <select
        id="roles"
        className="select"
        onChange={(e) => handleChangeRole(e.target.value)}
        value={role}
      >
        {Object.keys(UserRoles).map((userRole, ix) => (
          <option value={userRole} key={`roles-${ix}`}>
            {userRole}
          </option>
        ))}
      </select>
    </div>
  );
}
