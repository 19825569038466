import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';
import { AdminState } from '../../shared/types/store';

const initialState: AdminState = {
  user: [],
  plans: [],
  loading: false,
  error: false,
  usageImage: false,
  userPlan: undefined,
};

const handleLoading = (state) => {
  return updateObject(state, {
    loading: true,
    error: false,
  });
};

const handleError = (state) => {
  return updateObject(state, {
    loading: false,
    error: true,
  });
};

const fetchPlansStart = (state) => {
  return handleLoading(state);
};

const fetchPlansSuccess = (state, action) => {
  return updateObject(state, {
    plans: action.plans,
    loading: false,
    error: false,
  });
};

const fetchPlansFail = (state) => {
  return handleError(state);
};

const setPlanStart = (state) => {
  return handleLoading(state);
};

const setPlanSuccess = (state) => {
  return updateObject(state, {
    loading: false,
    error: false,
  });
};

const setPlanFail = (state) => {
  return handleError(state);
};

const fetchUserAppsSuccess = (state, action) => {
  return updateObject(state, { user: action.payload });
};

const fetchUsageImageSuccess = (state, action) => {
  return updateObject(state, {
    usageImage: {
      ...state.usageImage,
      [action.payload.tag]: action.payload.objectUrl,
    },
  });
};

const fetchUsageImageFail = (state, action) => {
  return updateObject(state, {
    usageImage: { ...state.usageImage, [action.tag]: undefined },
  });
};

const fetchUserPlanSuccess = (state, action) => {
  return updateObject(state, {
    userPlan: action.plan,
  });
};

const fetchUserPlanFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
  });
};

const admin = (state, action) => {
  if (typeof state === 'undefined') {
    return initialState;
  }

  switch (action.type) {
    case actionTypes.FETCH_PLANS_START:
      return fetchPlansStart(state);
    case actionTypes.FETCH_PLANS_SUCCESS:
      return fetchPlansSuccess(state, action);
    case actionTypes.FETCH_PLANS_FAIL:
      return fetchPlansFail(state);
    case actionTypes.SET_PLAN_START:
      return setPlanStart(state);
    case actionTypes.SET_PLAN_SUCCESS:
      return setPlanSuccess(state);
    case actionTypes.SET_PLAN_FAIL:
      return setPlanFail(state);
    case actionTypes.FETCH_USER_APPLICATIONS:
      return fetchUserAppsSuccess(state, action);
    case actionTypes.FETCH_USAGE_IMAGE_SUCCESS:
      return fetchUsageImageSuccess(state, action);
    case actionTypes.FETCH_USAGE_IMAGE_FAIL:
      return fetchUsageImageFail(state, action);
    case actionTypes.FETCH_USER_PLAN_SUCCESS:
      return fetchUserPlanSuccess(state, action);
    case actionTypes.FETCH_USER_PLAN_FAIL:
      return fetchUserPlanFail(state, action);
    default:
      return state;
  }
};

export default admin;
