import React from 'react';
import { CreateAppIcon } from '../../../../components/UI/Icons/CreateAppIcon/CreateAppIcon';
import text from '../../../../shared/translations/en';

interface IProps {
  createApplication: () => void;
}

export function CreateApp({ createApplication }: IProps) {
  return (
    <div className="applist__box" onClick={createApplication}>
      <div className="create-app">
        <CreateAppIcon />
        <div className="create-app create-app__text">
          <p>{text.en['create-new']}</p>
        </div>
      </div>
    </div>
  );
}
