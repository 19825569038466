import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import { FormDialog } from '../../UI/FormDialog/FormDialog';
import { PlatformEnum } from '../../../shared/types/application';
import text from '../../../shared/translations/en';
import { ModalButton } from '../../../components/UI/ModalButton/ModalButton';
import { NewToken } from './NewToken';
import { useDispatch, useSelector } from 'react-redux';
import { selectApplication } from '../../../store/selectors/application';
import { setApnToken, uploadApnToken } from '../../../store/actions/apnTokens';
import { ApnToken } from '../../../shared/types/store';
import { ExistingToken } from './ExistingToken';
import { selectUser } from '../../../store/selectors/user';
import { CognitoUser } from '../../../shared/types/user';
import { selectUploadSuccess } from '../../../store/selectors/modalError';
import { hideModal } from '../../../store/actions/modals';
import {
  DialogTitle,
  DialogContent,
  FormControlLabel,
  DialogActions,
  Radio,
} from '@mui/material';

interface IProps {
  setEditField: (field: string) => void;
  platform: PlatformEnum;
  mode: string;
  apnTokens: ApnToken[];
}

export function TokenDialog({
  setEditField,
  platform,
  mode,
  apnTokens,
}: IProps): JSX.Element {
  const dispatch = useDispatch();

  const existingToken = text.en['existing-token'];
  const newToken = text.en['new-token'];
  const [checked, setChecked] = useState<string>(
    apnTokens.length === 0 ? newToken : existingToken
  );
  const [file, setFile] = useState<File>();
  const [keyId, setKeyId] = useState<string>('');
  const [teamId, setTeamId] = useState<string>('');

  const application = useSelector(selectApplication);
  const user = useSelector(selectUser);
  const uploadSuccess = useSelector(selectUploadSuccess);
  const appId = application.appId ?? application.id;

  const handleUploadToken = useCallback(() => {
    if (checked === newToken) {
      const obj = {
        keyId,
        platform,
        modeType: mode,
        teamId,
      };
      dispatch(uploadApnToken(appId, obj, file));
    } else {
      const obj = {
        keyId,
        platform,
        modeType: mode,
        email: (user as CognitoUser).attributes.email,
      };
      dispatch(setApnToken(appId, obj));
    }
  }, [platform, keyId, mode, teamId, file]);

  const closeModal = useCallback(() => {
    dispatch(hideModal());
    setEditField(undefined);
  }, []);

  useEffect(() => {
    if (uploadSuccess) {
      setEditField(undefined);
      dispatch(hideModal());
    }
  }, [uploadSuccess]);

  const isDisabled = checked === newToken ? !file || !keyId || !teamId : !keyId;

  return (
    <FormDialog open={true} handleClose={closeModal}>
      <DialogTitle id="form-dialog-title">Token</DialogTitle>
      <DialogContent style={{ width: 300 }}>
        <FormControlLabel
          disabled={apnTokens.length === 0}
          control={
            <Radio
              checked={checked === existingToken}
              onChange={(e: FormEvent<HTMLInputElement>) =>
                setChecked(e.currentTarget.value)
              }
              value={existingToken}
              name="radio-button-demo"
              aria-label="Existing"
              color="default"
            />
          }
          label="Existing token"
        />
        <FormControlLabel
          control={
            <Radio
              checked={checked === newToken}
              onChange={(e: FormEvent<HTMLInputElement>) =>
                setChecked(e.currentTarget.value)
              }
              value={newToken}
              name="radio-button-demo"
              aria-label="New"
              color="default"
            />
          }
          label="New token"
        />
        {checked === newToken && (
          <NewToken
            keyId={keyId}
            setKeyId={setKeyId}
            teamId={teamId}
            setTeamId={setTeamId}
            setFile={setFile}
          />
        )}
        {checked === existingToken && (
          <ExistingToken
            apnTokens={apnTokens}
            keyId={keyId}
            setKeyId={setKeyId}
          />
        )}
      </DialogContent>
      <DialogActions>
        <ModalButton
          btnText={text.en.cancel}
          actionHandler={closeModal}
          type="transparent"
        />
        <ModalButton
          btnText="use"
          actionHandler={handleUploadToken}
          type="colored"
          isDisabled={isDisabled}
        />
      </DialogActions>
    </FormDialog>
  );
}
