import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { resetUser } from '../../../store/actions/user.js';
import useAmplifyAuth from 'utils/useAmplifyAuth';

export function Logout(): JSX.Element {
  const { handleSignout } = useAmplifyAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetUser());
    handleSignout();
  }, []);

  return <Navigate to="/login" />;
}
