import React from 'react';
import text from '../../shared/translations/en';
import { SocialIcon } from './SocialIcon';
import { ContactIcon } from './ContactIcon';

export function Footer(): JSX.Element {
  return (
    <footer className="sr-footer">
      <div className="sr-footer__wrapper">
        <div className="row">
          <div className="sr-footer__first-row">
            {/* address */}
            <div className="col-1-of-3 sr-footer__column-address">
              <p>SPORTRADAR</p>
              <p className="sr-footer__address">
                Feldlistrasse 2
                <br />
                CH-9000 St. Gallen
                <br />
                Switzerland
              </p>
              <p className="sr-footer__vat">
                {text.en['vat-text']}
                <br />
                CHE-113.910.142 MWST
              </p>
            </div>
            {/* contact */}
            <div className="col-2-of-3 sr-footer__column-contact">
              <div>
                <ContactIcon
                  title="phone"
                  icon={
                    <path
                      d="M14.146,16.935c.741.421,1.592-1.5,1.818-1.8.431-.574.676-1.089,2.847.524,
      2.333,1.733,2.277,2.158,1.167,3.68a3.239,3.239,0,0,1-2.036,1.383A13.562,13.562,0,
      0,1,9.5,16.5,13.562,13.562,0,0,1,5.28,8.058,3.239,3.239,0,0,1,6.663,6.022c1.522-1.11,
      1.947-1.166,3.68,1.167,1.613,2.171,1.1,2.416.524,2.847-.3.226-2.223,1.077-1.8,1.818
      a13.614,13.614,0,0,0,2.188,2.893A13.614,13.614,0,0,0,14.146,16.935Z"
                    />
                  }
                />
                Office: +41 71 517 72 00
              </div>
              <div>
                <ContactIcon
                  title="fax"
                  icon={
                    <path
                      d="M21.557,10.59v4.843A2.175,2.175,0,0,1,19.389,17.6H18.337v-3H7.663v3
      H6.611a2.175,2.175,0,0,1-2.168-2.168V10.59A2.175,2.175,0,0,1,6.611,8.421
      H7.663v3.1H18.337v-3.1h1.052A2.175,2.175,0,0,1,21.557,10.59ZM17.27,5.081
      H8.7v5.19H17.27Zm0,10.648v5.19H8.7v-5.19Zm-1.115,3.217H9.809v1.122
      h6.346Zm0-2.182H9.809v1.122h6.346Z"
                    />
                  }
                />
                Fax: +41 71 517 72 99
              </div>

              <div>
                <ContactIcon
                  title="mail"
                  icon={
                    <path
                      d="M6.075,7.941a1.43,1.43,0,0,1,.854-.288H19.071a1.429,1.429,0,0,1,.908.332L13,14.119
      C8.882,10.439,6.969,8.735,6.075,7.941Zm14.364,9.405a1.429,1.429,0,0,0,.077-.445
      V9.1a1.408,1.408,0,0,0-.091-.487l-5.049,4.439ZM5.6,8.539a1.436,1.436,0,0,0-.113.56
      v7.8a1.429,1.429,0,0,0,.077.445l5.08-4.309C8.78,11.375,6.7,9.516,5.6,8.539Zm9.2,5.023
      L13,15.141l-.253-.225-1.527-1.365-5.23,4.437a1.435,1.435,0,0,0,.941.359H19.071
      a1.435,1.435,0,0,0,.941-.359Z"
                    />
                  }
                />
                E-Mail: &nbsp;
                <a
                  href="mailto:mdp@sportradar.com"
                  className="u-color-red"
                  rel="noopener noreferrer"
                >
                  mdp@sportradar.com
                </a>
              </div>
              <p></p>
              <p className="sr-footer__social-media">SPORTRADAR IS SOCIAL</p>
              <div className="sr-footer__social-media-icons">
                <SocialIcon
                  provider="Facebook"
                  url="https://www.facebook.com/pages/Sportradar/391162040945181"
                  icon={
                    <>
                      <path
                        d="M13.961,7.612a2.335,2.335,0,0,0-2.249,2.356v1.84H9.925v2.08l1.742.009v4.491l2.143,0V13.906h1.751l.267-2.089
        H13.81V10.35c0-.9.96-.827.96-.827h1.111V7.709A14.824,14.824,0,0,0,13.961,7.612Z"
                      />
                      <path
                        d="M12.9.556A12.444,12.444,0,1,0,25.347,13,12.458,12.458,0,0,0,12.9.556Z
        m0,23.868A11.424,11.424,0,1,1,24.327,13,11.437,11.437,0,0,1,12.9,24.424Z"
                      />
                    </>
                  }
                />
                <SocialIcon
                  provider="Twitter"
                  url="https://twitter.com/Sportradar"
                  icon={
                    <path
                      d="M13,.556A12.444,12.444,0,1,0,25.444,13,12.458,12.458,0,0,0,13,.556Zm0,23.868
      A11.424,11.424,0,1,1,24.424,13,11.437,11.437,0,0,1,13,24.424Zm7.488-13.769-1.474,1.373
      a6.834,6.834,0,0,1-2.406,5.254,7.48,7.48,0,0,1-9.056.782,5.761,5.761,0,0,0,3.855-1.127,
      5.394,5.394,0,0,1-3.08-1.443,12.6,12.6,0,0,0,1.844-.394,4.021,4.021,0,0,1-2.619-2.377,
      10.355,10.355,0,0,0,1.934.211s-1.934-1.556-1.28-3.662c0,0,2.471,2.758,5.656,2.791h.013
      a2.6,2.6,0,0,1,2.517-3.254,2.106,2.106,0,0,1,1.76.687L19.7,9l-.577,1.418Z"
                    />
                  }
                />
                <SocialIcon
                  provider="Linkedin"
                  url="https://www.linkedin.com/company/sportradar"
                  icon={
                    <path
                      d="M13,.556A12.444,12.444,0,1,0,25.444,13,12.458,12.458,0,0,0,13,.556Z
      m0,23.868A11.424,11.424,0,1,1,24.424,13,11.437,11.437,0,0,1,13,24.424ZM8.323,10.98
      h1.853v6.534H8.323Zm9.571,2.3v4.231H16.041V13.868c0-.877-.415-1.328-1.121-1.328
      s-1.159.451-1.159,1.328v3.646H11.908V10.98h1.853v.926a2.313,2.313,0,0,1,1.913-1.048
      A2.142,2.142,0,0,1,17.894,13.283Zm-7.5-4.673A1.144,1.144,0,1,1,9.25,7.466,1.144,1.144,0,0,1,10.393,8.61Z"
                    />
                  }
                />
                <SocialIcon
                  provider="Youtube"
                  url="https://www.youtube.com/Sportradar"
                  icon={
                    <path
                      d="M13,.556A12.444,12.444,0,1,0,25.444,13,12.458,12.458,0,0,0,13,.556Zm0,23.868
      A11.424,11.424,0,1,1,24.424,13,11.437,11.437,0,0,1,13,24.424Z
      M17.231,8.61s-2.2-.239-4.214-.239S8.8,8.61,8.8,8.61c-1.422.188-1.895.835-2.168,2.169
      A8.9,8.9,0,0,0,6.411,13a8.392,8.392,0,0,0,.223,2.185c.294,1.186.557,1.879,2.168,2.168
      a38.743,38.743,0,0,0,4.03.276c1.859,0,4.4-.276,4.4-.276
      A2.335,2.335,0,0,0,19.4,15.185,14.986,14.986,0,0,0,19.589,13
      a15.808,15.808,0,0,0-.19-2.221A2.278,2.278,0,0,0,17.231,8.61Zm-5.567,6.545V11.04L15.033,13.1Z"
                    />
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="sr-footer__copyright-row">
            <p className="sr-footer__copyright">
              <span className="sr-footer__copyright-span">
                © Sportradar AG, St. Gallen, Switzerland
              </span>
              <span className="sr-footer__copyright-links">
                <a
                  href="https://sportradar.com/about-us/imprint/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {text.en.imprint}
                </a>
                <a
                  href="https://sportradar.com/about-us/privacy/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {text.en['privacy-policy']}
                </a>
              </span>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}
