import React, { useCallback } from 'react';
import text from '../../shared/translations/en';

interface IProps {
  setOpenDialog: (open: boolean) => void;
  saveData: () => void;
}

export function ConfirmModal({ setOpenDialog, saveData }: IProps): JSX.Element {
  const confirmDelete = useCallback(() => {
    saveData();
    setOpenDialog(false);
  }, []);

  return (
    <div className="settings-dialog">
      <div className="settings-dialog-header">
        <div
          className={'settings-dialog-close'}
          onClick={() => setOpenDialog(false)}
        >
          <i className={'icon-16-377-close2'} />
        </div>
      </div>
      <div className="settings-dialog-content">
        {text.en['are-you-sure-delete-key']}
      </div>
      <button
        className="settings-dialog-delete-btn sr-btn sr-btn__md btn-default"
        title={text.en.delete}
        onClick={confirmDelete}
      >
        {text.en.delete}
      </button>
    </div>
  );
}
