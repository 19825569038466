import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
  allModifiers: [],
  appModifiers: [],
  success: undefined,
};

const fetchAllPushModifiersSuccess = (state, action) => {
  return updateObject(state, {
    allModifiers: action.allModifiers,
  });
};

const fetchAllPushModifiersFail = (state) => {
  return updateObject(state, {});
};

const fetchAppPushModifiersSuccess = (state, action) => {
  return updateObject(state, {
    appModifiers: action.appModifiers,
  });
};

const fetchPushModifiersFail = (state) => {
  return updateObject(state, {});
};

const addPushModifierSuccess = (state) => {
  return updateObject(state, { success: true });
};

const addPushModifierFail = (state) => {
  return updateObject(state, { success: false });
};

const pushModifiers = (state, action) => {
  if (typeof state === 'undefined') {
    return initialState;
  }

  switch (action.type) {
    case actionTypes.FETCH_ALL_PUSH_MODIFIERS_SUCCESS:
      return fetchAllPushModifiersSuccess(state, action);
    case actionTypes.FETCH_ALL_PUSH_MODIFIERS_FAIL:
      return fetchAllPushModifiersFail(state);
    case actionTypes.FETCH_APP_PUSH_MODIFIERS_SUCCESS:
      return fetchAppPushModifiersSuccess(state, action);
    case actionTypes.FETCH_APP_PUSH_MODIFIERS_FAIL:
      return fetchPushModifiersFail(state);
    case actionTypes.ADD_PUSH_MODIFIER_SUCCESS:
      return addPushModifierSuccess(state);
    case actionTypes.ADD_PUSH_MODIFIER_FAIL:
      return addPushModifierFail(state);
    default:
      return state;
  }
};

export default pushModifiers;
