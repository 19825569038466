import React from 'react';
import text from '../../../shared/translations/en';
import { CONFIG_ONE, CONFIG_TWO, CONFIG_THREE } from '../config';
import { FormOne } from './FormOne';
import {
  ConfigOne,
  ConfigThree,
  ConfigTwo,
} from '../../../shared/types/pushSettings';
import { FormThree } from './FormThree';

interface IProps {
  formType: string;
  onFormSubmit: (config: ConfigOne | ConfigTwo | ConfigThree) => void;
}

export function ConfigDetailForm({ formType, onFormSubmit }: IProps) {
  switch (formType) {
    case text.en[CONFIG_ONE]:
    case text.en[CONFIG_TWO]:
      return <FormOne configType={formType} onFormSubmit={onFormSubmit} />;
    case text.en[CONFIG_THREE]:
      return <FormThree onFormSubmit={onFormSubmit} />;
    default:
      return null;
  }
}
