import React from 'react';
import srLogo from '../../assets/images/SR_logo_black.svg';

export function Logo(): JSX.Element {
  return (
    <div className="logo">
      <img alt="logo" src={srLogo} />
    </div>
  );
}
